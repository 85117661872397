import { Autocomplete, Avatar, Button, Dialog, FormControlLabel, Radio, RadioGroup, styled, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';
import { LazyImage } from '../../../../../assets/image/lazyImage';
import { useNavigate, useLocation } from 'react-router-dom';

// import { AddTractorModel as ProfileAddTractorModel } from "../../../../../profile/components/my-tractor";
import { AddTractorModel as ProfileAddTractorModel } from '../../../../profile/components/my-tractor';

import './our-offering.css';
import { getLastYears } from '../../../../../config/common-function/data-generate';
import { useAuthContext } from '../../../../../context/authContext';
import { useLanguage } from '../../../../../language/useLanguage';
import { AutocompleteImageOption } from '../../../../../assets/autocomplete/autocomplete-option';
import InputNumber from '../../../../../assets/custom-fields/input-number';
import { useAddCustomerTractor, useGetAllServiceTypeV2, useGetBrand, useGetCustomerTractor, useGetCustomerTractorList, useGetModel } from '../../../homepage-api';
import IconStar from '../../../../../assets/icons/icon-common/icon-star';
import IconTractorDualColor from '../../../../../assets/icons/icon-common/icon-tractor-dual-color';
import IconArrowRedirect from '../../../../../assets/icons/icon-common/icon-redirection';
import { SectionTagGrey } from '../../../../common/tags';
import { setTractorBrandId, setTractorBrandName, setTractorModelId, setTractorModelName } from '../../../../../redux/features/customer-order/customerOrderSlice';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import IconArrowDown from '../../../../../assets/icons/icon-common/icon-arrow-down';
import IconSearchWhite from '../../../../../assets/icons/icon-common/search-icon';
import { CardContent, OfferingCard, OurOfferingContainer, OurOfferingWrapper, ResponsiveDiv } from './styled';

const getYears = getLastYears(30);

const defaultValues = {
  tractor_brand_id: null,
  tractor_model_id: null,
};

export default function OurOffering({ data, handelScroll, brandList, modelList, modelId, tractorBrandId }) {
  return (
    <>
      <ServiceSelection />
      <OurOfferingContainer style={{ scrollMarginTop: 190 }}>
        <OurOfferingWrapper>
          <div className="offering-content">
            <ResponsiveDiv>
              <SectionTagGrey text={data.tag} />
            </ResponsiveDiv>
            <div>
              <h2>{data?.heading}</h2>
              <p style={{ fontSize: 18, fontWeight: 400, color: '#6d7d93' }}>{data.description}</p>
            </div>
          </div>
          <div className="offering-cards">
            {data.content?.map((a, index) => (
              <OfferingCard key={index} style={{ flexGrow: 1 }}>
                <div>
                  <LazyImage name={a.icon} height={'4rem'} width={'4rem'} />
                </div>
                <CardContent>
                  <h3>{a.heading}</h3>
                  <p>{a.description}</p>
                </CardContent>

                <div className="card-container">
                  <Link
                    to={
                      modelId
                        ? `${brandList?.data
                            ?.find((br) => br.id === tractorBrandId)
                            ?.en_label.trim()
                            .toLowerCase()
                            .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
                            .replace(/^-+|-+$/g, '') // Remove leading/trailing hyphens
                            .split(' ')
                            .join('-')}/${modelList?.data
                            ?.find((mo) => mo.id === modelId)
                            ?.en_label.trim()
                            .toLowerCase()
                            .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
                            .replace(/^-+|-+$/g, '') // Remove leading/trailing hyphens
                            .split(' ')
                            .join('-')}`
                        : '/'
                    }
                    onClick={handelScroll}
                  >
                    <p>{a.link_title} </p>
                    <IconArrowRedirect height="0.6rem" width="0.6rem" />
                  </Link>
                </div>
              </OfferingCard>
            ))}
          </div>
        </OurOfferingWrapper>
      </OurOfferingContainer>
    </>
  );
}

export function ServiceSelection() {
  const navigate = useNavigate();
  const { getData } = useAuthContext();
  const { userData } = getData();
  const location = useLocation();
  const langData = useLanguage();
  const dispatch = useDispatch();
  const customer_id = userData?.user_id;
  const tractor_brand = localStorage.getItem('tractor_brand') ? JSON.parse(localStorage.getItem('tractor_brand')) : null;
  const tractor_model = localStorage.getItem('tractor_model') ? JSON.parse(localStorage.getItem('tractor_model')) : null;

  defaultValues.tractor_brand_id = location.state?.tractor_brand_id ?? tractor_brand?.brand_id ?? null;
  defaultValues.tractor_model_id = location.state?.model_id ?? tractor_model?.model_id ?? null;

  useEffect(() => {
    window.history.replaceState({}, location.state);
  }, [location.state]);

  const {
    control,
    formState: { errors },
    watch,
    setValue,
    reset,
    handleSubmit,
    setFocus,
  } = useForm({ defaultValues });

  useEffect(() => {
    //This ussefct work for focus brandId and modelId if not select by user when user try to redirect on another page from view all button
    //1) used in shop-by-category.jsx in view all button in handelScroll() function
    window.brandModelFormFocus = setFocus;
  }, [setFocus]);

  const [tractor_brand_id, tractor_model_id] = watch(['tractor_brand_id', 'tractor_model_id']);
  const submitBtnRef = useRef();
  const [addTrPopup, setAddTrPopup] = useState(false);
  const [openPopup, setOpenPopup] = useState({
    open: false,
    customerMultiTractor: [],
  });
  const brandList = useGetBrand();
  const modelList = useGetModel(tractor_brand_id);
  const serviceTypeListV2 = useGetAllServiceTypeV2(tractor_model_id);
  const customerTractorList = useGetCustomerTractorList(customer_id);
  const addTractor = useAddCustomerTractor(customer_id);
  const [serviceId, setServiceId] = useState(null);
  // const customerAllTractor = customerTractorList?.data;

  const customerTractor = useGetCustomerTractor(tractor_brand_id);
  const all_model = customerTractor.data?.all_tractors ?? [];
  const your_model = customerTractor.data?.your_tractors ?? [];

  const customGroupTractorList = [
    ...your_model.map((a) => {
      a.group = 'My Tractors';
      return a;
    }),
    ...all_model.map((a) => {
      a.group = 'All Tractors';
      return a;
    }),
  ];
  const [cart, setCart] = useState(null);

  const onProceed = (selectedCustTractor) => {
    // console.debug("SELECTED TRACTOR: ", selectedCustTractor);
    let custTractor;
    if (!selectedCustTractor) {
      custTractor = customerTractorList.data.filter((tr) => tr.tractor_model_id === tractor_model_id && tr.status === 1);
      if (custTractor.length === 0) setAddTrPopup(true);
      else if (custTractor.length >= 1) setOpenPopup({ open: true, customerMultiTractor: custTractor });
      return;
    } else {
      setOpenPopup({ open: false, customerMultiTractor: [] });
    }

    const state = {
      service_id: serviceId,
      service: serviceTypeListV2.data.find((ST) => ST.Items[0].Service.id === serviceId),
      tractor_model: modelList.data.find((model) => model.id === selectedCustTractor.tractor_model_id),
      tractor_model_id: selectedCustTractor.tractor_model_id,
      customer_tractor_id: selectedCustTractor.id,
      tractor_brand_id: selectedCustTractor.TractorModel.TractorBrand.id,
    };
    // console.debug('state: ', state)
    navigate('/booking/add', { state });
  };

  const handleClosePopup = () => setOpenPopup({ open: false });

  const onReset = () => {
    setCart(null);
    setServiceId(null);
    reset();
  };

  const closeTrPopup = () => setAddTrPopup(false);
  const handleTractorAdd = (inputs) => {
    inputs.customer_id = customer_id;
    inputs.tractor_model_id = tractor_model_id;
    console.debug('SUBMITTING: ', inputs);
    const form = new FormData();
    for (const key in inputs) {
      form.append(key, inputs[key]);
    }
    addTractor.mutate(form, {
      onSuccess: (response) => {
        closeTrPopup();
        const state = {
          service: serviceTypeListV2.data.find((ST) => ST.Items[0].Services[0].id === serviceId),
          tractor_model: modelList.data.find((model) => model.id === tractor_model_id),
          customer_tractor_id: response.data.id,
        };
        navigate('/booking/add', { state });
      },
    });
  };

  const submitHandler = () => {
    const brand = brandList?.data
      .find((a) => a.id === tractor_brand.brand_id)
      .en_label?.trim()
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
      .replace(/^-+|-+$/g, '') // Remove leading/trailing hyphens
      .split(' ')
      .join('-');
    const model = modelList?.data
      .find((a) => a.id === tractor_model.model_id)
      .en_label?.trim()
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
      .replace(/^-+|-+$/g, '') // Remove leading/trailing hyphens
      .split(' ')
      .join('-');
    // debugger;
    dispatch(setTractorBrandName(brandList?.data.find((a) => a.id === tractor_brand.brand_id).label ?? ''));
    dispatch(setTractorModelName(modelList?.data.find((a) => a.id === tractor_model.model_id).label ?? ''));
    navigate(`/${brand}/${model}`);
    // navigate("/customer-order");
  };
  const ButtonContainer = styled('div')({
    '@media (max-width: 600px)': {
      width: '100%',
    },
  });

  return (
    <div
      className="service-selection-container"
      id="select-service"
      style={{
        scrollMarginTop: 190,
        marginBottom: 0,
      }}
    >
      <form onSubmit={handleSubmit(submitHandler)} style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <div className="tractor-model-list-container tractor-selection">
          <div className="field-container" style={{ flexGrow: 1 }}>
            <Controller
              name="tractor_brand_id"
              control={control}
              rules={{ required: 'Select Tractor Brand' }}
              render={({ field: { ref, ...field } }) => {
                return (
                  <Autocomplete
                    {...field}
                    options={brandList.data ?? []}
                    isOptionEqualToValue={(option, value) => option.id === value}
                    popupIcon={<IconArrowDown height="1.2rem" width="1.2rem" />}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputRef={ref}
                        placeholder={langData('Select Brand')}
                        error={!!errors.tractor_brand_id}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: <IconStar style={{ marginLeft: 4 }} />,
                          style: { touchAction: 'manipulation' },
                        }}
                      />
                    )}
                    getOptionLabel={(option) => option.label ?? brandList.data?.find((opt) => opt.id === option)?.label ?? ''}
                    onChange={(__event, data) => {
                      field.onChange(data?.id ?? null);
                      dispatch(setTractorBrandName(data?.label ?? ''));
                      dispatch(setTractorBrandId(data?.id ?? null));
                      localStorage.setItem(
                        'tractor_brand',
                        JSON.stringify({
                          brand_name: data?.label,
                          brand_id: data?.id,
                        }),
                      );
                      localStorage.removeItem('tractor_model');
                      if (data) setFocus('tractor_model_id');
                      setCart(null);
                      setValue('tractor_model_id', null);
                    }}
                    renderOption={(params, option) => (
                      <AutocompleteImageOption {...params} option={option} imgSrc={option.brand_img} fallBackSrc={'placeholder-tractor-brand.svg'} />
                    )}
                    getOptionDisabled={(option) => option.status === 0}
                  />
                );
              }}
            />
            <p className="form-error-text">{errors.tractor_brand_id?.message}</p>
          </div>
          <div className="field-container" style={{ flexGrow: 1 }}>
            <Controller
              name="tractor_model_id"
              control={control}
              rules={{ required: 'Select Tractor Model' }}
              render={({ field: { ref, ...field } }) => {
                return (
                  <Autocomplete
                    {...field}
                    options={customGroupTractorList}
                    onChange={(__event, data) => {
                      field.onChange(data?.id ?? null);
                      dispatch(setTractorModelName(data?.label ?? ''));
                      dispatch(setTractorModelId(data?.id ?? ''));

                      if (data) {
                        setValue('tractor_brand_id', data.tractor_brand_id);
                        setValue('tractor_brand', data.tractor_brand_id);
                        dispatch(setTractorBrandId(data.tractor_brand_id ?? ''));
                        dispatch(setTractorBrandName(brandList?.data.find((a) => a.id === data.tractor_brand_id).label));
                        localStorage.setItem(
                          'tractor_model',
                          JSON.stringify({
                            model_name: data?.label,
                            model_id: data?.id,
                          }),
                        );
                        localStorage.setItem(
                          'tractor_brand',
                          JSON.stringify({
                            brand_name: brandList?.data.find((a) => a.id === data?.tractor_brand_id).label,
                            brand_id: brandList?.data.find((a) => a.id === data?.tractor_brand_id).id,
                          }),
                        );
                        submitBtnRef.current.focus();
                      }
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value}
                    popupIcon={<IconArrowDown height="1.2rem" width="1.2rem" />}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputRef={ref}
                        placeholder={langData('Select  Model')}
                        error={!!errors.tractor_model_id}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: <IconTractorDualColor style={{ marginLeft: 4 }} />,
                          style: { touchAction: 'manipulation' },
                        }}
                      />
                    )}
                    groupBy={(option) => option.group}
                    getOptionLabel={(option) => {
                      if (option.label) {
                        return option.label;
                      } else {
                        return customGroupTractorList?.find((opt) => opt.id === option)?.label ?? 'Loading...';
                      }
                    }}
                    getOptionDisabled={(option) => option.status === 0}
                    renderOption={(params, option) => (
                      <AutocompleteImageOption {...params} option={option} imgSrc={option.model_img} fallBackSrc={'placeholder-tractor-model.svg'} />
                    )}
                  />
                );
              }}
            />
            <p className="form-error-text">{errors.tractor_model_id?.message}</p>
            {openPopup.open && <SelectTractor open={openPopup.open} tractor_list={openPopup.customerMultiTractor} onProceed={onProceed} onClose={handleClosePopup} />}
          </div>
          <ButtonContainer>
            <Button
              sx={{
                alignSelf: 'flex-end',
                // minWidth: window.innerWidth <= 600 ? "100%" : "119px",
                '@media only screen and (max-width: 600px)': {
                  '&.MuiButtonBase-root': {
                    minWidth: '100%',
                  },
                },
              }}
              variant="contained"
              ref={submitBtnRef}
              type="submit"
              startIcon={<IconSearchWhite height="1.2rem" width="1.2rem" />}
            >
              {langData('Search')}
            </Button>
            <p className="form-error-text"></p>
          </ButtonContainer>
        </div>
      </form>

      {cart && (
        <div className="cart">
          <div className="cart-details">
            <Avatar className="icon-container">
              <LazyImage name={cart.model_img} width={50} height={50} borderRadius={50} fallbackImage="tractor-model-image.svg" />
            </Avatar>
            {/* <div className="tractor-details">
              <p className="title">{langData("Selected Tractor")}</p>
              <p className="details">{cart.brand_name + ' ' + cart.model_name}</p>
            </div>
            <div style={{ width: 1, backgroundColor: "#FFFFFF4B" }}></div> */}
            <div className="service-details">
              <p className="title">{langData('Selected Service')}</p>
              <p className="details">
                {langData(cart.service_type_name)} - {langData(cart.brand_name)} {langData(cart.model_name)}
              </p>
              <p className="details">₹ {cart.offer_total}</p>
            </div>
          </div>
          <div className="btn-container">
            <Button variant="outlined" color="white" onClick={onReset}>
              {langData('Reset')}
            </Button>
            <Button variant="contained" color="white" onClick={() => onProceed()}>
              {langData('Proceed')}
            </Button>
          </div>
        </div>
      )}
      {addTrPopup && (
        <AddTractorModel
          open={addTrPopup}
          onClose={closeTrPopup}
          submit={handleTractorAdd}
          brandName={brandList.data.find((br) => br.id === tractor_brand_id).label}
          modelName={modelList.data.find((mo) => mo.id === tractor_model_id).label}
        />
      )}
    </div>
  );
}

// export function ServiceSelection({ data }) {
//   const navigate = useNavigate();
//   const { getData } = useAuthContext();
//   const { userData } = getData();
//   const location = useLocation();
//   const langData = useLanguage();
//   const dispatch = useDispatch();
//   const customer_id = userData?.user_id;
//   defaultValues.tractor_brand_id = location.state?.tractor_brand_id ?? null;
//   defaultValues.tractor_model_id = location.state?.model_id ?? null;
//   useEffect(() => {
//     window.history.replaceState({}, location.state);
//   }, []);
//   const {
//     control,
//     formState: { errors },
//     watch,
//     setValue,
//     reset,
//     handleSubmit,
//     setFocus,
//   } = useForm({ defaultValues });
//   const [tractor_brand_id, tractor_model_id] = watch([
//     "tractor_brand_id",
//     "tractor_model_id",
//   ]);
//   const submitBtnRef = useRef();
//   const [addTrPopup, setAddTrPopup] = useState(false);
//   const [openPopup, setOpenPopup] = useState({
//     open: false,
//     customerMultiTractor: [],
//   });
//   const brandList = useGetBrand();
//   const modelList = useGetModel(tractor_brand_id);
//   const serviceTypeListV2 = useGetAllServiceTypeV2(tractor_model_id);
//   const customerTractorList = useGetCustomerTractorList(customer_id);
//   const addTractor = useAddCustomerTractor(customer_id);
//   const [serviceId, setServiceId] = useState(null);
//   // const customerAllTractor = customerTractorList?.data;

//   const customerTractor = useGetCustomerTractor(tractor_brand_id);
//   const all_model = customerTractor.data?.all_tractors ?? [];
//   const your_model = customerTractor.data?.your_tractors ?? [];

//   const customGroupTractorList = [
//     ...your_model.map((a) => {
//       a.group = "My Tractors";
//       return a;
//     }),
//     ...all_model.map((a) => {
//       a.group = "All Tractors";
//       return a;
//     }),
//   ];

//   const [cart, setCart] = useState(null);
//   const AddService = (service_type) => {
//     const serviceID = service_type.Items[0].Service.id;
//     setServiceId(serviceID);
//     const cartData = {
//       service_id: serviceID,
//       brand_name:
//         service_type.Items[0].ItemModels[0].TractorModel.TractorBrand
//           .brand_name,
//       model_name: service_type.Items[0].ItemModels[0].TractorModel.model_name,
//       model_image: service_type.Items[0].ItemModels[0].TractorModel.model_img,
//       service_type_name: service_type.service_type_name,
//       offer_total: service_type.Items[0].Service.offer_total,
//     };
//     setCart(cartData);
//     // console.debug("SERVICE_ID: ", cartData);
//   };

//   const onProceed = (selectedCustTractor) => {
//     // console.debug("SELECTED TRACTOR: ", selectedCustTractor);
//     let custTractor;
//     if (!selectedCustTractor) {
//       custTractor = customerTractorList.data.filter(
//         (tr) => tr.tractor_model_id === tractor_model_id && tr.status === 1
//       );
//       if (custTractor.length === 0) setAddTrPopup(true);
//       else if (custTractor.length >= 1)
//         setOpenPopup({ open: true, customerMultiTractor: custTractor });
//       return;
//     } else {
//       setOpenPopup({ open: false, customerMultiTractor: [] });
//     }

//     const state = {
//       service_id: serviceId,
//       service: serviceTypeListV2.data.find(
//         (ST) => ST.Items[0].Service.id === serviceId
//       ),
//       tractor_model: modelList.data.find(
//         (model) => model.id === selectedCustTractor.tractor_model_id
//       ),
//       tractor_model_id: selectedCustTractor.tractor_model_id,
//       customer_tractor_id: selectedCustTractor.id,
//       tractor_brand_id: selectedCustTractor.TractorModel.TractorBrand.id,
//     };
//     // console.debug('state: ', state)
//     navigate("/booking/add", { state });
//   };

//   const handleClosePopup = () => setOpenPopup({ open: false });

//   const onReset = () => {
//     setCart(null);
//     setServiceId(null);
//     reset();
//   };

//   function handleSelectTractor() {
//     if (!tractor_brand_id) {
//       setFocus("tractor_brand_id");
//       return;
//     } else if (!tractor_model_id) {
//       setFocus("tractor_model_id");
//     }
//   }
//   const closeTrPopup = () => setAddTrPopup(false);
//   const handleTractorAdd = (inputs) => {
//     inputs.customer_id = customer_id;
//     inputs.tractor_model_id = tractor_model_id;
//     console.debug("SUBMITTING: ", inputs);
//     const form = new FormData();
//     for (const key in inputs) {
//       form.append(key, inputs[key]);
//     }
//     addTractor.mutate(form, {
//       onSuccess: (response) => {
//         closeTrPopup();
//         const state = {
//           service: serviceTypeListV2.data.find(
//             (ST) => ST.Items[0].Services[0].id === serviceId
//           ),
//           tractor_model: modelList.data.find(
//             (model) => model.id === tractor_model_id
//           ),
//           customer_tractor_id: response.data.id,
//         };
//         navigate("/booking/add", { state });
//       },
//     });
//   };

//   const handelScroll = () => {
//     if (tractor_brand_id && tractor_model_id) {
//       const cardelements = document.getElementsByClassName("service-card")[0];
//       cardelements.scrollIntoView({
//         inline: "start",
//         block: "start",
//         behavior: "smooth",
//       });
//     }
//   };

//   const submitHandler = () => {
//     navigate("/customer-order");
//   };

//   return (
//     <div style={{ display: "flex", alignItems: "center" }}>
//       <div style={{ backgroundColor: "#fff", padding: 24, display: "flex" }}>
//         <h1>select trac</h1>
//       </div>
//     </div>
//   );
// }

const TractorInputs = {
  reg_no: '',
  purchase_year: null,
  manufacture_year: null,
  hours_used: '',
};

function AddTractorModel({ open = false, onClose, submit, brandName = 'Brand', modelName = 'Model' }) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues: TractorInputs });
  const langData = useLanguage();

  return (
    <Dialog open={open} onClose={onClose} className="popup add-tractor" style={{ maxWidth: 'unset' }}>
      <div className="content-container">
        <div className="info">Seems like you have not added selected tractor</div>
        <div className="tractor-details">
          <img src={`icons/tractor-model-demo.svg`} style={{ height: 60 }} />
          <div>
            <p>{brandName}</p>
            <p>{modelName}</p>
          </div>
        </div>
        <form className="form-container" noValidate onSubmit={handleSubmit(submit)}>
          <div className="field-row">
            <div className="field-container">
              <label className="required">{langData('Chassis No.')}</label>
              <TextField
                error={!!errors?.reg_no}
                {...register('reg_no', {
                  required: langData('Field is Required'),
                })}
              />
              <p className="form-error-text">{errors.reg_no?.message}</p>
            </div>
            <div className="field-container">
              <label className="required">{langData('Year of Purchase')}</label>
              <Controller
                name="purchase_year"
                control={control}
                rules={{
                  required: langData('Please Select purchase year'),
                  validate: {
                    notLessThanManufacture: (value, formValues) => {
                      return Number(value) < Number(formValues.manufacture_year) ? 'Please select valid year' : true;
                    },
                  },
                }}
                render={({ field }) => {
                  return (
                    <Autocomplete
                      {...field}
                      options={getYears}
                      value={field.value?.toString() ?? null}
                      renderInput={(params) => <TextField {...params} placeholder={langData('Purchase year')} error={!!errors.purchase_year} />}
                      onChange={(___event, newValue) => {
                        field.onChange(newValue ? Number(newValue) : newValue);
                      }}
                    />
                  );
                }}
              />
              <p className="error-text">{errors.purchase_year?.message}</p>
            </div>
          </div>
          <div className="field-row">
            <div className="field-container">
              <label className="required">{langData('Year of Manufacture')}</label>
              <Controller
                name="manufacture_year"
                control={control}
                rules={{
                  required: langData('Please Select Year of Manufacture'),
                  deps: ['purchase_year'],
                }}
                render={({ field }) => {
                  return (
                    <Autocomplete
                      {...field}
                      options={getYears}
                      value={field.value?.toString() ?? null}
                      renderInput={(params) => <TextField {...params} placeholder={langData('Year of Manufacture')} error={!!errors.manufacture_year} />}
                      onChange={(___event, newValue) => {
                        field.onChange(newValue ? Number(newValue) : newValue);
                      }}
                    />
                  );
                }}
              />
              <p className="form-error-text">{errors.manufacture_year?.message}</p>
            </div>
            <div className="field-container">
              <label className="required">{langData('Hours Used')}</label>
              <Controller
                name="hours_used"
                control={control}
                rules={{
                  required: langData('Field is Required'),
                }}
                render={({ field }) => <InputNumber {...field} error={!!errors.hours_used} />}
              />
              <p className="form-error-text">{errors.hours_used?.message}</p>
            </div>
          </div>
          <div className="btn-container">
            <Button sx={{ minWidth: 120 }} type="submit" variant="contained">
              {langData('Save & Next')}
            </Button>
          </div>
        </form>
      </div>
    </Dialog>
  );
}

function SelectTractor({ open = false, tractor_list, onProceed, onClose }) {
  const [addVehiclePopup, setAddVehiclePopup] = useState(false);
  const handlePopUpClose = () => setAddVehiclePopup(false);
  const langData = useLanguage();
  // console.debug('tractor_list', tractor_list);
  return (
    <Dialog open={open} onClose={onClose} className="popup" sx={{ '& .MuiDialog-paper': { width: '75vw', maxWidth: 700 } }}>
      <div className="choose-tractor">
        <h1 style={{ paddingBottom: 28 }}>{langData('Select Your Vehicle')}</h1>
      </div>
      <div className="select-card-container">
        {tractor_list?.map((a) => (
          <div className="select-service-tractor-card" key={a.id}>
            <RadioGroup onClick={() => onProceed(a)}>
              <div style={{ display: 'flex', gap: 5 }}>
                <FormControlLabel value={a.id} control={<Radio />} style={{ alignSelf: 'self-start', margin: 0 }} />
                <div style={{ padding: 9 }}>
                  <h3>
                    {a.TractorModel.TractorBrand.brand_name} {a.TractorModel.model_name}
                  </h3>
                  <div className="tractor-des-details">
                    <div className="tractor-chassis-details">
                      <p className="detail-title">{langData('Chassis No.')}</p>
                      <p className="detail-des">{a.reg_no}</p>
                    </div>
                    <div className="tractor-yop-details">
                      <p className="detail-title">{langData('Year of Purchase')}</p>
                      <p className="detail-des">{a.purchase_year}</p>
                    </div>
                    <div className="tractor-uh-details">
                      <p className="detail-title">{langData('Used Hours')}</p>
                      <p className="detail-des">{a.hours_used}</p>
                    </div>
                  </div>
                </div>
              </div>
            </RadioGroup>
          </div>
        ))}
      </div>
      <div
        style={{
          display: 'flex',
          gap: 16,
          marginTop: 16,
          justifyContent: 'flex-end',
          flexWrap: 'wrap',
        }}
      >
        <Button variant="contained" onClick={() => setAddVehiclePopup(true)}>
          {langData('Add New Vehicle')}
        </Button>
        <Button variant="outlined" onClick={onClose}>
          {langData('Cancel')}
        </Button>
      </div>
      {addVehiclePopup && (
        <ProfileAddTractorModel
          open={addVehiclePopup}
          disableBrand
          disableModel
          inputs={{
            tractor_brand_id: tractor_list[0].TractorModel.TractorBrand.id,
            tractor_model_id: tractor_list[0].tractor_model_id,
            reg_no: '',
            purchase_year: null,
            manufacture_year: null,
            hours_used: '',
          }}
          onClose={handlePopUpClose}
          onSuccess={onClose}
        />
      )}
    </Dialog>
  );
}
