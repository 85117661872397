import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Autocomplete, Avatar, Box, Button, FormControl, MenuItem, Skeleton, styled, TextField, Typography, useMediaQuery } from '@mui/material';
import { useGetCustomerTractor, useGetCustomerTractorAll, useGetCustomerTractorNew, useGetTractorBrandDDList } from './tractor-workshop-api';
import { useGetBrand, useGetModel, useGetWorkshopLocation } from '../../homepage/homepage-api';
import { useLanguage } from '../../../language/useLanguage';
import { AutocompleteImageOption } from '../../../assets/autocomplete/autocomplete-option';
import {
  setChange,
  setIsAppointmentDrawerOpen,
  setServiceEdit,
  setTractorBrandId,
  setTractorBrandName,
  setTractorModelId,
  setTractorModelName,
} from '../../../redux/features/customer-order/customerOrderSlice';

import {
  setIsLocationWorkShopModel,
  setIsWorkshopLocationChangeAgree,
  setWorkshopAddress1,
  setWorkshopAddress2,
  setWorkshopDirection,
  setWorkshopName,
  setWorkshopSelectId,
} from '../../../redux/features/location/locationSlice';
import { LazyImage } from '../../../assets/image/lazyImage';
import IconLocationAvatar from '../../../assets/icons/icon-common/icon-location-avatar';
import IconService from '../../../assets/icons/icon-common/icon-service';
import IconKits from '../../../assets/icons/icon-common/icon-kits';
import IconParts from '../../../assets/icons/icon-common/icon-parts';
import { CustomSelect, TractorAndWorkshopSelect, TractorModelChange } from './styled';
import BookAppointmentModel from '../book-appointment-model/book-appointment-model';
import { useAuthContext } from '../../../context/authContext';
import LocationChangeModel from '../location-change-model/location-change-model';
import { useDeleteCart } from '../../layout/cart/cart-api';
import { setCartItems } from '../../../redux/features/cart/cartSlice';
import { useGetCart } from '../../layout/header/header-api';
import BookAppointmentDrawerMobile from '../book-appointment-model/book-appointment-drawer-mobile';

const scrollSections = [
  {
    icon: <IconService style={{ width: 36, height: 36 }} />,
    text: 'Book Service',
    link: '',
  },
  {
    icon: <IconKits style={{ width: 36, height: 36 }} />,
    text: 'Buy Kits',
    link: '',
  },
  {
    icon: <IconParts style={{ width: 36, height: 36 }} />,
    text: 'Buy Parts & Accessories',
    link: '',
  },
];

export default function WorkShoplocationSelection({ activeSection, sectionHeadingRef, scrollToSection, sectionsRef, serviceTypeListTrial }) {
  const locationId = useSelector((state) => state.location.locationId);
  const getLocationWorkshop = useGetWorkshopLocation(locationId);

  const TractorSection = styled('div')({
    flex: 1,
  });

  const FlexBox = styled('div')({
    display: 'flex',
    gap: '1rem',
  });

  const SelectionTabsContainer = styled('div')({
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
  });

  const WorkshopContainer = styled('div')({
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  });

  const SkeletonItem = ({ variant, width, height }) => <Skeleton variant={variant} width={width} height={height} />;

  // Extracted sections
  const TractorSectionComponent = () => (
    <TractorSection>
      <span>
        <SkeletonItem variant="text" width={100} />
      </span>
      <FlexBox>
        <SkeletonItem variant="circular" width={50} height={50} />
        <div>
          <h3>
            <SkeletonItem variant="text" width={100} />
          </h3>
          <p>
            <SkeletonItem variant="text" width={150} />
            <span>
              <SkeletonItem variant="text" width={50} />
            </span>
          </p>
        </div>
      </FlexBox>
    </TractorSection>
  );

  const SelectionTabs = ({ scrollSections }) => (
    <SelectionTabsContainer>
      {scrollSections.length ? (
        scrollSections.map((section, i) => (
          <div key={i}>
            <SkeletonItem variant="rectangular" width={30} height={30} />
            <p>
              <SkeletonItem variant="text" width={80} />
            </p>
          </div>
        ))
      ) : (
        <SkeletonItem variant="rectangular" width={300} height={40} />
      )}
    </SelectionTabsContainer>
  );

  const WorkshopSection = () => (
    <WorkshopContainer>
      <div>
        <SkeletonItem variant="rectangular" width={50} height={50} />
        <div>
          <p>
            <SkeletonItem variant="text" width={100} />
          </p>
          <p>
            <SkeletonItem variant="text" width={150} />
          </p>
        </div>
      </div>
    </WorkshopContainer>
  );

  const TractorAndWorkshopComponent = ({ scrollSections }) => {
    return (
      <TractorAndWorkshopSelect>
        <TractorSectionComponent />
        <SelectionTabs scrollSections={scrollSections} />
        <WorkshopSection />
      </TractorAndWorkshopSelect>
    );
  };

  if (getLocationWorkshop.isLoading) return <TractorAndWorkshopComponent scrollSections={scrollSections} />;

  return (
    <TractorAndWorkshopSelection
      serviceTypeListTrial={serviceTypeListTrial}
      getLocationWorkshop={getLocationWorkshop}
      scrollToSection={scrollToSection}
      sectionsRef={sectionsRef}
      activeSection={activeSection}
      sectionHeadingRef={sectionHeadingRef}
    />
  );
}

export function TractorAndWorkshopSelection({ activeSection, sectionHeadingRef, serviceTypeListTrial, getLocationWorkshop, scrollToSection }) {
  const { getData } = useAuthContext();
  const { isAuth, lang, userData } = getData();
  const customerTractors = useGetCustomerTractor();
  const tractorWorkshopService = useRef(null);
  const langData = useLanguage();
  const dispatch = useDispatch();
  const cartId = useSelector((state) => state.cart.cartId);
  const deleteCart = useDeleteCart(cartId);
  const brandList = useGetBrand();
  const tractorBrandId = useSelector((state) => state.customer_order.tractorBrandID);
  const modelList = useGetModel(tractorBrandId);
  const cart = useGetCart(userData?.user_id);

  if (localStorage.getItem('tractor_brand')) {
    const tractor_brand = localStorage.getItem('tractor_brand') ? JSON.parse(localStorage.getItem('tractor_brand')) : null;

    if (tractor_brand) {
      dispatch(setTractorBrandName(brandList?.data?.find((a) => a.id === tractor_brand.brand_id).label ?? ''));
      dispatch(setTractorBrandId(tractor_brand.brand_id));
    }
  }

  if (localStorage.getItem('tractor_model')) {
    const tractor_model = localStorage.getItem('tractor_model') ? JSON.parse(localStorage.getItem('tractor_model')) : null;

    if (tractor_model) {
      dispatch(setTractorModelName(modelList?.data?.find((a) => a.id === tractor_model.model_id)?.label ?? ''));
      dispatch(setTractorModelId(tractor_model.model_id));
    }
  }

  const tractorModelName = useSelector((state) => state.customer_order.tractorModelName);
  const tractorBrandName = useSelector((state) => state.customer_order.tractorBrandName);
  const tractorModelId = useSelector((state) => state.customer_order.tractorModelId);
  const selectedService = useSelector((state) => state.customer_order.selectedService);
  const serviceID = useSelector((state) => state.customer_order.serviceId);
  const selectServicePriceOffer = useSelector((state) => state.customer_order.selectServicePriceOffer);
  const selectServicePriceTotal = useSelector((state) => state.customer_order.selectServicePriceTotal);
  const workshopName = useSelector((state) => state.location.workshopName);
  const WorkshopDirection = useSelector((state) => state.location.WorkshopDirection);
  const workshopAddress1 = useSelector((state) => state.location.workshopAddress1);
  const workshopAddress2 = useSelector((state) => state.location.workshopAddress2);
  const isAppointmentModelOpen = useSelector((state) => state.customer_order.isAppointmentModelOpen);
  const isAppointmentDrawerOpen = useSelector((state) => state.customer_order.isAppointmentDrawerOpen);
  const change = useSelector((state) => state.customer_order.change);
  const serviceEdit = useSelector((state) => state.customer_order.serviceEdit);
  const customerTractorId = useSelector((state) => state.customer_order.customerTractorId);

  const cartItems = useSelector((state) => state.cart.cartItems);
  const isLocationWorkShopModel = useSelector((state) => state.location.isLocationWorkShopModel);
  const chassisNO = useSelector((state) => state.customer_order.chassisNO);
  const defaultInput = {
    tractor_brand_id: tractorBrandId ?? null,
    tractor_model_id: tractorModelId ?? null,
  };

  const {
    control,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
  } = useForm({ defaultValues: defaultInput });

  const [tractor_brand_id, tractor_model_id] = watch(['tractor_brand_id', 'tractor_model_id']);
  // const serviceTypeListTrial = useGetAllServiceTypeV3Trial();
  const workshopSelectId = useSelector((state) => state.location.workshopSelectId);
  const isWorkshopLocationChangeAgree = useSelector((state) => state.location.isWorkshopLocationChangeAgree);

  const [selectedWorkshopId, setSelectedWorkshopId] = useState(null);

  // Workshop location change function for model confirmation
  const workshopLocationChange = () => {
    deleteCart.mutate('', {
      onSuccess: () => {
        dispatch(setIsWorkshopLocationChangeAgree(false));
        dispatch(setWorkshopSelectId(selectedWorkshopId));
        dispatch(setCartItems(null));
      },
    });
  };

  const handleDisAgree = () => {
    dispatch(setIsLocationWorkShopModel(false));
  };
  const handleAgree = () => {
    dispatch(setIsWorkshopLocationChangeAgree(true));
    handleDisAgree();
  };

  // useEffect for handling the location change agreement
  useEffect(() => {
    if (isWorkshopLocationChangeAgree) {
      workshopLocationChange();
    }
  }, [isWorkshopLocationChangeAgree]);
  const [changeLocation, setChangeLocation] = useState(false);

  // useEffect(() => {
  //   if (cartItems !== null && Array.isArray(cartItems) && cartItems.length !== 0 && cart.data !== '') {
  //     dispatch(setWorkshopSelectId(cart?.data?.Workshop.id ?? null));
  //   } else {
  //     dispatch(setWorkshopSelectId(workshopSelectId ?? getLocationWorkshop?.data[0]?.id ?? null));
  //   }
  // }, [getLocationWorkshop?.data, dispatch, cart, cartItems, workshopSelectId]);

  useEffect(() => {
    if (cartItems?.length > 0 && cart?.data?.Workshop?.id) {
      // If cartItems exist and there's a workshop in the cart, set it.
      dispatch(setWorkshopSelectId(cart?.data?.Workshop.id));
    } else if (!changeLocation && getLocationWorkshop?.data?.length > 0) {
      // If location hasn't been changed and workshops exist, set the first one.
      dispatch(setWorkshopSelectId(getLocationWorkshop?.data[0]?.id));
    } else if (getLocationWorkshop.data && getLocationWorkshop.data?.length === 0) {
      dispatch(setWorkshopSelectId(null));
    }
  }, [cartItems, cart?.data?.Workshop?.id, changeLocation, getLocationWorkshop?.data, dispatch]);

  useEffect(() => {
    if (tractorModelId !== null) {
      serviceTypeListTrial.mutate({ tractor_model_id: tractorModelId, workshop_id: workshopSelectId });
    }
  }, [tractorModelId, lang, workshopSelectId]);

  useEffect(() => {
    if (getLocationWorkshop.data) {
      getLocationWorkshop?.data.filter((workshop) => {
        if (workshop.id === workshopSelectId) {
          dispatch(setWorkshopDirection(workshop.map_link));
          dispatch(setWorkshopName(workshop.workshop_name));
          dispatch(setWorkshopAddress1(workshop.SubDistrict.subdistrict_name));
          dispatch(setWorkshopAddress2(workshop.SubDistrict.District.district_name));
        }
      });
    }
  }, [workshopSelectId]);

  useEffect(() => {
    if (serviceEdit && tractorWorkshopService.current) {
      // Get the position of the element and adjust the scroll position
      const offsetTop = tractorWorkshopService.current.offsetTop;
      // You can adjust the scroll height here, add custom height offset if needed
      window.scrollTo({
        top: offsetTop + 60, // Adjust the value (-100, +100, etc.) to control the scroll position
        behavior: 'smooth',
      });
      dispatch(setServiceEdit(false));
    }
  }, [serviceEdit]);

  // Use `useMediaQuery` to detect mobile screen (600px or less)
  const isMobile = useMediaQuery('(max-width:600px)');

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    dispatch(setIsAppointmentDrawerOpen(open));
  };

  return (
    <Parent ref={sectionHeadingRef}>
      <TractorAndWorkshopSelect className="selection-bar" ref={tractorWorkshopService}>
        {!change ? (
          <>
            <div className="selected-tractor selected-tractor-mobile">
              <span className="tractor-heading">{langData('Selected Tractor')}</span>
              <div className="selected-tractor-model">
                <div className="tractor-img">
                  <Avatar className="icon-container">
                    <LazyImage name fallbackImage="tractor-model-image.svg" width={'100%'} height={50} />
                  </Avatar>
                </div>
                <div className="tractor-details">
                  <h3 style={{ fontSize: '15px' }}>{tractorBrandName !== '' ? tractorBrandName : 'Mahindra'}</h3>
                  <p>
                    {tractorModelName !== '' ? tractorModelName : '275 DI SP Plus'}{' '}
                    <span onClick={() => dispatch(setChange(true))} className="mobile-change-none">
                      {langData('Change')}
                    </span>
                  </p>
                </div>
              </div>
              <span onClick={() => dispatch(setChange(true))} className="model-change-mobile">
                {langData('Change')}
              </span>
            </div>
            <div className="selection-tabs">
              {scrollSections.map((section, i) => (
                <div
                  // id={`${section.id}`}
                  key={i}
                  className={`tab ${i !== 0 ? 'tab-bordered' : ''} services-tabs${i} `}
                  onClick={() => scrollToSection(i)}
                  style={{ cursor: i < 3 ? 'pointer' : 'default' }}
                >
                  <div style={{ width: '100%' }}>{section.icon}</div>
                  <p style={{ fontWeight: activeSection === i ? 'bold' : 500 }}>{langData(section.text)}</p>
                </div>
              ))}
            </div>
            <div className="selected-workshop">
              <FormControl fullWidth variant="outlined">
                <p
                  id="workshop-label"
                  style={{
                    fontWeight: 800,
                    color: '#6D7D93',
                    fontSize: '14',
                  }}
                  // shrink
                >
                  {langData('Workshop')}
                </p>
                {getLocationWorkshop.data && getLocationWorkshop.data?.length > 0 ? (
                  <CustomSelect
                    labelId="workshop-label"
                    id="workshop-select"
                    onChange={(e) => {
                      setChangeLocation(true);
                      if (cartItems?.length > 0) {
                        dispatch(setIsLocationWorkShopModel(true));
                        setSelectedWorkshopId(e.target.value); // Handle case where cartItems exist
                      } else {
                        setSelectedWorkshopId(e.target.value);
                        dispatch(setWorkshopSelectId(e.target.value)); // Set selected workshop
                      }
                    }}
                    value={workshopSelectId || ''}
                    // value={workshopSelectId}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          a: { display: 'none' },
                        },
                        style: {
                          maxHeight: 200,
                          maxWidth: '300px',
                        },
                      },
                    }}
                    onOpen={(res) => {
                      if (res.target.href !== undefined) {
                        window.open(res.target.href, '_blank');
                      }
                    }}
                  >
                    {getLocationWorkshop.data &&
                      getLocationWorkshop.data?.length > 0 &&
                      getLocationWorkshop.data.map((workshop, index) => (
                        <MenuItem key={index} value={workshop.id}>
                          <Box display="flex" alignItems="center" gap={'1rem'}>
                            {workshop?.workshop_img ? (
                              <Avatar
                                src={`${workshop.workshop_img}`}
                                alt={workshop.workshop_name}
                                sx={{
                                  width: 40,
                                  height: 40,
                                }}
                              />
                            ) : (
                              <IconLocationAvatar />
                            )}
                            <Box>
                              <Typography variant="subtitle1" fontWeight={600} fontSize={15} sx={{ textWrap: 'wrap' }}>
                                {workshop.workshop_name}{' '}
                                {workshopSelectId ? (
                                  <Link to={workshop.map_link} style={{ color: 'red' }}>
                                    {langData('Get Direction')}
                                  </Link>
                                ) : (
                                  ''
                                )}
                              </Typography>
                              <Typography variant="body2" color="textSecondary" style={{ textWrap: 'wrap' }} fontWeight={500}>
                                {workshop.SubDistrict.subdistrict_name}, {workshop.SubDistrict.District.district_name}
                              </Typography>
                            </Box>
                          </Box>
                        </MenuItem>
                      ))}
                  </CustomSelect>
                ) : (
                  <Box display="flex" alignItems="center" paddingTop={'0.5px'} gap={'1rem'}>
                    <IconLocationAvatar />
                    <Box>
                      <Typography variant="subtitle1" fontWeight={600} sx={{ textWrap: 'wrap' }}>
                        No workshop found at your location
                      </Typography>
                    </Box>
                  </Box>
                )}
              </FormControl>
            </div>
          </>
        ) : (
          <ChangeTractor
            setChange={setChange}
            control={control}
            errors={errors}
            tractor_brand_id={tractor_brand_id}
            tractor_model_id={tractor_model_id}
            handleSubmit={handleSubmit}
            serviceTypeListTrial={serviceTypeListTrial}
            setValue={setValue}
            tractorBrandName={tractorBrandName}
            tractorModelName={tractorModelName}
            change={change}
            workshopSelectId={workshopSelectId}
          />
        )}
      </TractorAndWorkshopSelect>
      {!isMobile
        ? isAuth &&
          isAppointmentModelOpen && (
            <BookAppointmentModel
              customerTractors={customerTractors}
              isForNavigation={{
                brandName: brandList?.data?.find((a) => a.id === tractorBrandId)?.en_label,
                modelName: modelList?.data?.find((a) => a.id === tractorModelId)?.en_label,
              }}
              tractorModelName={tractorModelName}
              tractorBrandName={tractorBrandName}
              selectedService={selectedService}
              selectServicePriceOffer={selectServicePriceOffer}
              selectServicePriceTotal={selectServicePriceTotal}
              workshopName={workshopName}
              WorkshopDirection={WorkshopDirection}
              workshopAddress1={workshopAddress1}
              workshopAddress2={workshopAddress2}
              tractorModelId={tractorModelId}
              tractorBrandId={tractorBrandId}
              service_id={serviceID}
              setChange={setChange}
              modelOpen={isAppointmentModelOpen}
              chassisNO={chassisNO}
              customerTractorId={customerTractorId}
              isPastService={false}
            />
          )
        : isAppointmentDrawerOpen &&
          isAuth && (
            <BookAppointmentDrawerMobile
              modelOpen={isAppointmentDrawerOpen}
              toggleDrawer={toggleDrawer}
              customerTractors={customerTractors}
              isForNavigation={{
                brandName: brandList?.data?.find((a) => a.id === tractorBrandId)?.en_label,
                modelName: modelList?.data?.find((a) => a.id === tractorModelId)?.en_label,
              }}
              tractorModelName={tractorModelName}
              tractorBrandName={tractorBrandName}
              selectedService={selectedService}
              selectServicePriceOffer={selectServicePriceOffer}
              selectServicePriceTotal={selectServicePriceTotal}
              workshopName={workshopName}
              WorkshopDirection={WorkshopDirection}
              workshopAddress1={workshopAddress1}
              workshopAddress2={workshopAddress2}
              tractorModelId={tractorModelId}
              tractorBrandId={tractorBrandId}
              service_id={serviceID}
              setChange={setChange}
              chassisNO={chassisNO}
              customerTractorId={customerTractorId}
              isPastService={false}
            />
          )}

      {isLocationWorkShopModel && <LocationChangeModel isModelOpen={isLocationWorkShopModel} handleAgree={handleAgree} handleDisAgree={handleDisAgree} />}
    </Parent>
  );
}
const Parent = styled('div')({
  height: '100%',
  position: 'sticky',
  top: '0px',
  zIndex: 1000,
  ' @media(max-width: 600px)': {
    zIndex: 5,
  },
});
export const ChangeTractor = ({
  workshopSelectId,
  change,
  setChange,
  tractor_brand_id,
  errors,
  control,
  handleSubmit,
  serviceTypeListTrial,
  setValue,
  tractorBrandName,
  tractorModelName,
}) => {
  const dropdownBrandList = useGetTractorBrandDDList();
  let customerbrand = JSON.parse(localStorage.getItem('tractor_brand'));
  const tractorModelId = useSelector((state) => state.customer_order.tractorModelId);
  const tractorBrandId = useSelector((state) => state.customer_order.tractorBrandID);
  const modelList = useGetModel(tractor_brand_id);

  const customerTractor = useGetCustomerTractorAll(tractor_brand_id);
  const all_models = customerTractor.data?.all_tractors ?? [];
  const your_models = customerTractor.data?.your_tractors ?? [];

  const customGroupTractorLists = [
    ...your_models.map((a) => {
      a.group = 'My Tractors';
      return a;
    }),
    ...all_models.map((a) => {
      a.group = 'All Tractors';
      return a;
    }),
  ];

  useEffect(() => {
    setValue('tractor_model_id', tractorModelId);
    setValue('tractor_brand_id', tractorBrandId);
    // setBrandName(tractorBrandName ?? '');
    // setModelName(tractorModelName ?? '');
  }, [tractorModelId, tractorBrandId, tractorBrandName, tractorModelName]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputHandler = async (inputs) => {
    if (inputs) {
      try {
        await serviceTypeListTrial.mutateAsync({ tractor_model_id: inputs.tractor_model_id, workshop_id: workshopSelectId });

        dispatch(setTractorModelId(inputs.tractor_model_id));
        dispatch(setTractorBrandId(inputs.tractor_brand_id));
        dispatch(setTractorModelName(modelList?.data.find((a) => a.id === inputs.tractor_model_id).label));
        dispatch(setTractorBrandName(dropdownBrandList?.data.find((a) => a.id === inputs.tractor_brand_id).label));
        const brand = dropdownBrandList?.data
          .find((a) => a.id === inputs.tractor_brand_id)
          .en_label?.trim()
          .split(' ')
          .join('-')
          .toLowerCase();
        const model = modelList?.data
          .find((a) => a.id === inputs.tractor_model_id)
          .en_label?.trim()
          .split(' ')
          .join('-')
          .toLowerCase();

        localStorage.setItem(
          'tractor_brand',
          JSON.stringify({
            brand_name: dropdownBrandList?.data.find((a) => a.id === inputs.tractor_brand_id).label,
            brand_id: inputs.tractor_brand_id,
          }),
        );

        localStorage.setItem(
          'tractor_model',
          JSON.stringify({
            model_name: modelList?.data.find((a) => a.id === inputs.tractor_model_id).label,
            model_id: inputs.tractor_model_id,
          }),
        );

        navigate(`/${brand}/${model}`);
        dispatch(setChange(false));
      } catch (error) {
        console.error('Error updating service type list:', error);
      }
    }
  };

  const tractorWorkshopRef = useRef(null);

  useEffect(() => {
    if (change && tractorWorkshopRef.current) {
      // Get the position of the element and adjust the scroll position
      const offsetTop = tractorWorkshopRef.current.offsetTop;

      // You can adjust the scroll height here, add custom height offset if needed
      window.scrollTo({
        top: offsetTop - 180, // Adjust the value (-100, +100, etc.) to control the scroll position
        behavior: 'smooth',
      });
    }
  }, [change]);

  return (
    <TractorModelChange>
      <h4 style={{ minWidth: 121 }}> Change Tractor: </h4>
      <form className="change-tractor-brand" onSubmit={handleSubmit(inputHandler)} ref={tractorWorkshopRef}>
        <Controller
          name="tractor_brand_id"
          control={control}
          rules={{ required: 'Please Select Brand' }}
          render={({ field }) => {
            return (
              <Autocomplete
                {...field}
                fullWidth
                options={dropdownBrandList.data ?? []}
                isOptionEqualToValue={(option, value) => option.id === value}
                renderInput={(params) => <TextField {...params} placeholder="Brand" error={!!errors.tractor_brand_id} />}
                getOptionLabel={(option) => option.label ?? dropdownBrandList.data?.find((opt) => opt.id === option)?.label ?? ''}
                onChange={(__event, data) => {
                  field.onChange(data?.id ?? null);
                  setValue('tractor_model_id', null);
                }}
                renderOption={(params, option) => <AutocompleteImageOption {...params} option={option} imgSrc={option.brand_img} fallBackSrc={'placeholder-tractor-brand.svg'} />}
                getOptionDisabled={(option) => option.status === 0}
              />
            );
          }}
        />

        <Controller
          name={`tractor_model_id`}
          control={control}
          rules={{
            required: 'Please Select Model',
          }}
          render={({ field }) => (
            <Autocomplete
              {...field}
              fullWidth
              options={customGroupTractorLists}
              renderInput={(params) => <TextField {...params} placeholder="Model" error={!!errors.tractor_model_id} />}
              groupBy={(option) => option.group}
              isOptionEqualToValue={(option, value) => option.id === value}
              getOptionDisabled={(option) => option.status === 0}
              getOptionLabel={(option) => {
                if (option.label) {
                  return option.label;
                } else {
                  return customGroupTractorLists?.find((opt) => opt.id === option)?.label ?? '';
                }
              }}
              onChange={(__event, data) => {
                field.onChange(data?.id ?? null);
                if (data) {
                  setValue('tractor_brand_id', data.tractor_brand_id);
                  // dispatch(setTractorBrandId(data.tractor_brand_id ?? ''));
                  // dispatch(setTractorBrandName(dropdownBrandList?.data.find((a) => a.id === data.tractor_brand_id).label));
                }
              }}
              disabled={!customerbrand}
              renderOption={(params, option) => (
                <AutocompleteImageOption {...params} option={option} imgSrc={option.model_img ?? 'placeholder-tractor-model.svg'} fallBackSrc={'placeholder-tractor-model.svg'} />
              )}
            />
          )}
        />

        <div className="button_container" style={{ display: 'flex', gap: '1rem' }}>
          <Button variant="contained" type="submit">
            Apply
          </Button>
          <Button variant="outlined" onClick={() => dispatch(setChange(false))}>
            Cancel
          </Button>
        </div>
      </form>
    </TractorModelChange>
  );
};
