import { Button, styled } from '@mui/material';
import IconArrowRedirect from '../../../../../assets/icons/icon-common/icon-redirection';
import { LazyImage } from '../../../../../assets/image/lazyImage';
import { SectionTagGrey } from '../../../../common/tags';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../../../../language/useLanguage';

export function ShopByCategory({ data, handelScroll, brandList, modelList, modelId, tractorBrandId }) {
  const langData = useLanguage();
  return (
    <CategoryContainer>
      <div className="category_content">
        <div>
          <SectionTagGrey text={data.tag} />
        </div>
        <h2>{data.heading}</h2>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: 8,
          }}
        >
          <p
            style={{
              lineHeight: '1.3rem',
              fontSize: 18,
              fontWeight: 400,
              color: '#6D7D93',
            }}
          >
            {data.sub_heading}
          </p>
          <LinkContainer
            to={
              modelId
                ? `${brandList?.data
                    ?.find((br) => br.id === tractorBrandId)
                    ?.en_label.trim()
                    .toLowerCase()
                    .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
                    .replace(/^-+|-+$/g, '') // Remove leading/trailing hyphens
                    .split(' ')
                    .join('-')}/${modelList?.data
                    ?.find((mo) => mo.id === modelId)
                    ?.en_label.trim()
                    .toLowerCase()
                    .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
                    .replace(/^-+|-+$/g, '') // Remove leading/trailing hyphens
                    .split(' ')
                    .join('-')}`
                : '/'
            }
            onClick={handelScroll}
          >
            {langData('View All')}
            <IconArrowRedirect height="0.8rem" width="0.8rem" />
          </LinkContainer>
        </div>
      </div>
      <CardWrapper>
        {data.content?.map((a, index) => (
          <CategoryCard key={index}>
            <CardTitle>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 4, overflowWrap: 'anywhere' }}>
                <p>{a.main_category}</p>
                <h3>{a.category}</h3>
              </div>
              <LinkContainer
                to={
                  modelId
                    ? `${brandList?.data
                        ?.find((br) => br.id === tractorBrandId)
                        ?.en_label.trim()
                        .toLowerCase()
                        .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
                        .replace(/^-+|-+$/g, '') // Remove leading/trailing hyphens
                        .split(' ')
                        .join('-')}/${modelList?.data
                        ?.find((mo) => mo.id === modelId)
                        ?.en_label.trim()
                        .toLowerCase()
                        .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
                        .replace(/^-+|-+$/g, '') // Remove leading/trailing hyphens
                        .split(' ')
                        .join('-')}`
                    : '/'
                }
                onClick={handelScroll}
              >
                <Button
                  variant="contained"
                  endIcon={<IconArrowRedirect color="#fff" height="0.8rem" width="0.8rem" />}
                  style={{
                    fontWeight: '400',
                    fontSize: 14,
                    height: 28,
                    padding: '4px 18px',
                  }}
                >
                  {langData('Shop Now')}
                </Button>
              </LinkContainer>
            </CardTitle>
            <div style={{ width: '-webkit-fill-available', height: '220px', display: 'flex', justifyContent: 'end' }}>
              <LazyImage name={a.img} fallbackImage="item-fallback.png" width={220} height={220} />
            </div>
          </CategoryCard>
        ))}
      </CardWrapper>
    </CategoryContainer>
  );
}

const CategoryContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 46,
  padding: '4rem var(--padding-inline)',
  marginInline: 'calc(0px - var(--padding-inline))',
  backgroundColor: '#FBFBFB',
  p: { color: '#6D7D93', fontSize: '14px' },
  ' div.category_content': {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    h2: {
      color: 'var(--clr-primary)',
      fontSize: '3rem',
      fontWeight: 500,
    },
    ' @media only screen and (max-width: 600px)': {
      h2: {
        fontSize: '2rem',
      },
    },
  },
});

const CardWrapper = styled('div')({
  display: 'flex',
  gap: '2.4rem',
  justifyContent: 'space-between',
  '  @media (max-width: 768px)': {
    overflow: 'scroll',
    scrollBehavior: 'smooth',
  },
});

const CategoryCard = styled('div')({
  padding: '1.5rem',
  flexGrow: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  backgroundColor: '#F2F7FF',
  borderRadius: 8,
  flexBasis: 400,
  minHeight: 450,
  justifyContent: 'space-between',
  ' @media only screen and (max-width: 600px)': {
    minWidth: 250,
    flexBasis: 400,
    minHeight: 350,
  },
  ' @media only screen and (min-width: 750px) and (max-width: 1300px)': {
    minHeight: 350,
    justifyContent: 'space-between',
  },
});

const CardTitle = styled('div')({
  display: 'grid',
  height: '-webkit-fill-available',
  p: {
    fontSize: 18,
  },
  h3: {
    color: '#140B0C',
    fontSize: '1.8rem',
    fontWeight: 500,
  },
  gap: 12,
  ' div': {
    marginTop: '1rem',
  },
  ' @media only screen and (max-width: 600px)': {
    h3: {
      fontSize: '1.3rem',
    },
  },
});

const LinkContainer = styled(Link)({
  width: 'fit-content',
  display: 'flex',
  gap: 5,
  alignItems: 'center',
  color: '#ff4c4c',
  textDecoration: 'none',
  fontWeight: 500,
  fontSize: 18,
});
