import { Dialog, Drawer, styled } from '@mui/material';

export const AppointmentDialog = styled(Dialog)({
  // ".MuiDialog-paper": {
  //   minWidth: "60%",
  // },
  '.service-appointment': {
    display: 'flex',
    flexDirectionL: 'row',
    padding: '1rem',
  },
  '.service-container': {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: '1rem',
    padding: '1rem',
    background: '#F2F7FF',
    '.selected-tractor, .selected-service, .selected-workshop': {
      flex: '1 1 200px',
    },
    ' .selected-tractor, .selected-service, .selected-workshop': {
      h5: {
        color: '#6D7D93',
      },
    },
    '.selected-tractor-model': {
      display: 'flex',
      gap: '0.5rem',
      paddingTop: '1.5rem',
    },
    '.selected-service': {
      '.tractor-details': {
        '.offer-price': {
          paddingRight: '0.5rem',
          fontSize: '15px',
          fontWeight: 600,
        },
        '.total-price': {
          textDecoration: 'line-through',
          fontSize: '15px',
          color: '#6D7D93',
          fontWeight: 400,
        },
      },
    },
  },

  '.custom-scrollbar-popup::-webkit-scrollbar': {
    width: '6px' /* Width of the scrollbar */,
  },

  '.custom-scrollbar-popup::-webkit-scrollbar-thumb': {
    backgroundColor: '#002852' /* Color of the scrollbar thumb */,
    borderRadius: '5px' /* Rounded corners of the thumb */,
  },

  '.custom-scrollbar-popup::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1' /* Color of the track */,
    borderRadius: '5px' /* Rounded corners of the track */,
  },

  /* For Firefox */
  '.custom-scrollbar-popup': {
    scrollbarWidth: 'thin' /* Make scrollbar thinner */,
    scrollbarColor: '#002852 #f1f1f1' /* thumb color track color */,
  },

  // for tractor added card
  '.pop-card-container': {
    width: '100%',
    '.pop-card-media': {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      '.tractor-names > p': {
        fontWeight: '600',
        fontSize: '18px',
      },
    },
    '.pop-card-details': {
      '.pop-card-content': {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: '0.5rem',
        '.content-head': {
          color: '#6D7D93',
          fontWeight: '500',
        },
        '.content-head-value': {
          fontWeight: '500',
        },
      },
    },
  },
});
export const AppointmentDialogDrawer = styled(Drawer)({
  // for heading part
  '.appointment-container': {
    padding: '1rem 1rem 0.5rem',
  },
  '.appointment-header': {
    display: 'flex',
    justifyContent: 'space-between',
  },
  h2: {
    fontWeight: 600,
  },
  '.close-icon': {
    fontSize: '30px',
    cursor: 'pointer',
  },
  '.appointment-divider': {
    borderBottom: '1px solid #ccc',
    padding: '-10px 0px 10px',
  },
  // ....
  '.MuiAutocomplete-popper': {
    zIndex: 1000,
  },
  '.service-appointment': {
    display: 'flex',
    flexDirectionL: 'row',
    padding: '1rem',
  },
  '.service-container': {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto', // Enable horizontal scrolling
    maxWidth: '100%', // Adjust width as needed
    padding: '0.4rem 1rem 0.4rem',
    background: '#F2F7FF',
    minHeight: '130px',
    gap: '0.8rem',
    '.selected-tractor, .selected-service, .selected-workshop': {
      flex: '0 0 auto',
      width: '95%',
      padding: '1rem', // Add padding inside each service item
      backgroundColor: '#fff', // Background for each item
      border: '1px solid #e0e0e0',
      borderRadius: '4px',
    },
    '.selected-tractor, .selected-workshop, .selected-service': {
      '.tractor-details': {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    },
    // '.selected-tractor, .selected-service, .selected-workshop': {
    //   h5: {
    //     color: '#6D7D93',
    //   },
    // },
    '.selected-tractor-model': {
      display: 'flex',
      gap: '0.5rem',
      paddingTop: '0.6rem',
    },
    '.selected-service': {
      '.tractor-details': {
        '.offer-price': {
          paddingRight: '0.5rem',
          fontSize: '15px',
          fontWeight: 600,
        },
        '.total-price': {
          textDecoration: 'line-through',
          fontSize: '15px',
          color: '#6D7D93',
          fontWeight: 400,
        },
      },
    },
  },
  '.custom-scrollbar-popup::-webkit-scrollbar': {
    width: '6px' /* Width of the scrollbar */,
  },

  '.custom-scrollbar-popup::-webkit-scrollbar-thumb': {
    backgroundColor: '#002852' /* Color of the scrollbar thumb */,
    borderRadius: '5px' /* Rounded corners of the thumb */,
  },

  '.custom-scrollbar-popup::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1' /* Color of the track */,
    borderRadius: '5px' /* Rounded corners of the track */,
  },

  /* For Firefox */
  '.custom-scrollbar-popup': {
    scrollbarWidth: 'thin' /* Make scrollbar thinner */,
    scrollbarColor: '#002852 #f1f1f1' /* thumb color track color */,
  },

  // for tractor added card
  '.pop-card-container': {
    width: '100%',
    '.pop-card-media': {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      '.tractor-names > p': {
        fontWeight: '600',
        fontSize: '18px',
      },
    },
    '.pop-card-details': {
      '.pop-card-content': {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: '0.5rem',
        '.content-head': {
          color: '#6D7D93',
          fontWeight: '500',
        },
        '.content-head-value': {
          fontWeight: '500',
        },
      },
    },
  },
});

// for the tractor model add

export const TractorModelAdd = styled('div')({
  padding: '1rem 1rem',
  '.book-modal-pop > .form-column-2>*': {
    flexBasis: '200px',
    maxWidth: '100%',
    flexGrow: 1,
  },
});
