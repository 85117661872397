import { Popover, Button, FormControl, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useState } from 'react';
import { useGetDistricts, useGetStates, useGetTehsil } from './header-api';
import IconArrowDown from '../../../assets/icons/icon-common/icon-arrow-down';
import { useDispatch, useSelector } from 'react-redux';
import {
  setLocationID,
  setTehsilName,
  setStateName,
  setStateID,
  setLocationChange,
  setIsLocationModelOpen,
  setIsLocationChangeAgree,
  setWorkshopSelectId,
  setDistrictId,
} from '../../../redux/features/location/locationSlice';
import { getOptionLabel } from '../../../assets/autocomplete/autocomplete-functions';
import { useEffect } from 'react';
import { useGetWorkshopLocation, useUpdateWorkshopLocation } from '../../homepage/homepage-api';
import { useDeleteCart } from '../cart/cart-api';
import { setCartItems } from '../../../redux/features/cart/cartSlice';
import { useCallback } from 'react';

const PopoverWithDropdowns = ({ existCartLocations }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [errors, setErrors] = useState({
    state: false,
    district: false,
    tehsil: false,
  });
  const locationId = useSelector((state) => state.location.locationId);
  const getLocationWorkshop = useGetWorkshopLocation(locationId);
  const WorkShoplocationSelection = useUpdateWorkshopLocation();
  const stateId = useSelector((state) => state.location.stateId);
  const workshopSelectId = useSelector((state) => state.location.workshopSelectId);
  const districtId = useSelector((state) => state.location.districtId);
  const cartItems = useSelector((state) => state.cart.cartItems);
  const cartId = useSelector((state) => state.cart.cartId);
  const isLocationModelOpen = useSelector((state) => state.location.isLocationModelOpen);
  const deleteCart = useDeleteCart(cartId);
  const isLocationChangeAgree = useSelector((state) => state.location.isLocationChangeAgree);
  const handleValidation = () => {
    const validationErrors = {
      state: !stateId,
      district: !districtId,
      tehsil: !locationId,
    };

    setErrors(validationErrors);

    // Return true if any field has an error
    return Object.values(validationErrors).some((error) => error);
  };

  const handleClose = () => {
    // Validate and close the popover only if no errors
    if (!handleValidation()) {
      setAnchorEl(null);
    }
  };

  const dropdownStateList = useGetStates();
  const dispatch = useDispatch();
  const dropdownDistrictList = useGetDistricts(stateId);
  const dropdownTehsilList = useGetTehsil(districtId);
  // const [buttonText, setButtonText] = useState("Location");

  let buttonText = 'Location';

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  if (dropdownTehsilList.data?.find((opt) => opt.id == locationId)) {
    buttonText = dropdownTehsilList.data?.find((opt) => opt.id == locationId)?.main_label;
  }

  const handleFieldChange = (field, option) => {
    setErrors((prevErrors) => {
      if (field === 'state' && !option) {
        return { state: true, district: true, tehsil: true };
      }
      if (field === 'district' && !option) {
        return { ...prevErrors, district: true, tehsil: true };
      }
      return { ...prevErrors, [field]: !option };
    });
  };

  const handleStateChange = useCallback(
    (option, reason) => {
      const isClear = reason === 'clear';
      const newStateId = option?.id ?? null;
      // Update local storage and dispatch actions
      const updateState = () => {
        localStorage.setItem('state_id', newStateId);
        dispatch(setStateID(newStateId));
        dispatch(setLocationID(null));
        dispatch(setDistrictId(null));
        localStorage.setItem('location_change', true);
      };

      if (!isClear) {
        const selectedOption = dropdownStateList.data?.find((opt) => opt.id === newStateId);
        if (selectedOption) {
          dispatch(setStateName(selectedOption.label));
        }

        if (newStateId !== stateId) {
          updateState();
        }
      } else {
        updateState();
      }
    },
    [stateId, dispatch, dropdownStateList],
  );

  const handleDistrictChange = (option, reason) => {
    if (reason === 'clear') {
      dispatch(setDistrictId(null));
      localStorage.setItem('district_id', null);
      dispatch(setLocationID(null));
      localStorage.setItem('location_change', false);
      return;
    }

    const selectedDistrict = dropdownDistrictList.data?.find((opt) => opt.id === option.id);
    if (selectedDistrict) {
      localStorage.setItem('location_change', true);
      dispatch(setDistrictId(option.id));
      dispatch(setLocationID(null));
      localStorage.setItem('location_id', null);
    }
  };

  const [selectedOption, setSelectedOption] = useState({
    state: { option: null, reason: null },
    tehsil: { option: null, reason: null },
    district: { option: null, reason: null },
  });

  useEffect(() => {
    if (!isLocationModelOpen) {
      setSelectedOption({
        state: { option: null, reason: null },
        tehsil: { option: null, reason: null },
        district: { option: null, reason: null },
      });
    }
  }, [isLocationModelOpen]);

  const handleTehsilChange = (option, reason) => {
    console.debug(cartItems);
    if (reason === 'clear') {
      dispatch(setWorkshopSelectId(getLocationWorkshop?.data[0]?.id ?? null));
      localStorage.setItem('location_id', null);
      dispatch(setLocationID(null));
      dispatch(setLocationChange(false));
      localStorage.setItem('location_change', false);
      dispatch(setIsLocationChangeAgree(false));
      return;
    }

    const selectedTehsil = dropdownTehsilList.data?.find((opt) => opt.id === option?.id);

    if (selectedTehsil) {
      const tehsilLabel = selectedTehsil.label || 'Select Tehsil';
      dispatch(setTehsilName(tehsilLabel));
      buttonText = tehsilLabel;
      localStorage.setItem('location_id', option.id);

      if (option.id !== locationId) {
        dispatch(setLocationID(option.id));
        dispatch(setLocationChange(true));
        localStorage.setItem('location_change', true);
      }

      dispatch(setIsLocationChangeAgree(false));
    }
  };
  // Memoize deleteCart.mutate

  useEffect(() => {
    if (isLocationChangeAgree) {
      const { option: stateOption, reason: stateReason } = selectedOption.state;
      const { option: tehsilOption, reason: tehsilReason } = selectedOption.tehsil;
      const { option: districtOption, reason: districtReason } = selectedOption.district;

      if (stateOption) {
        handleStateChange(stateOption, stateReason);
        deleteCart.mutate('', {
          onSuccess: () => {
            dispatch(setCartItems(null));
          },
        });
      } else if (tehsilOption) {
        handleTehsilChange(tehsilOption, tehsilReason);
        deleteCart.mutate('', {
          onSuccess: () => {
            dispatch(setCartItems(null));
          },
        });
      } else if (districtOption) {
        handleDistrictChange(districtOption, districtReason);
        deleteCart.mutate('', {
          onSuccess: () => {
            dispatch(setCartItems(null));
          },
        });
      }
    }
  }, [isLocationChangeAgree, selectedOption, dispatch]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    if ((locationId === null && districtId === null && stateId === null) || [locationId, districtId, stateId].includes(null)) {
      localStorage.removeItem('location_change');
    }
  }, [locationId, districtId, stateId]);

  return (
    <div>
      <Button
        aria-describedby={id}
        variant="text"
        style={{ color: '#fff' }}
        onClick={handleClick}
        endIcon={<IconArrowDown />}
        startIcon={<img src="/images/location-logo-white-new.svg" color="#fff" />}
        sx={{
          '&&.MuiButton-root.MuiButtonBase-root': {
            minWidth: '200px',
          },
        }}
      >
        {buttonText}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div style={{ padding: '16px', width: 240 }}>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              options={dropdownStateList.data ?? []}
              renderInput={(params) => <TextField {...params} placeholder="State" error={errors.state} helperText={errors.state ? 'State is required' : ''} />}
              isOptionEqualToValue={(option, value) => option.id == value}
              getOptionLabel={(option) => getOptionLabel(option, dropdownStateList)}
              value={dropdownStateList?.data?.find((option) => option.id == stateId)?.id ?? null}
              onChange={(__event, option, reason) => {
                if (cartItems?.length > 0 && option?.id !== existCartLocations.state && reason !== 'clear') {
                  setSelectedOption((prev) => ({
                    ...prev,
                    state: { option, reason },
                  }));

                  dispatch(setIsLocationModelOpen(true));
                } else {
                  handleStateChange(option, reason);
                }
                handleFieldChange('state', option);
              }}
              name="state"
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              options={dropdownDistrictList.data ?? []}
              renderInput={(params) => <TextField {...params} placeholder="District" error={errors.district} helperText={errors.district ? 'District is required' : ''} />}
              isOptionEqualToValue={(option, value) => option.id === value}
              getOptionLabel={(option) => option.label ?? dropdownDistrictList.data?.find((opt) => opt.id === option)?.label ?? ''}
              value={dropdownDistrictList?.data?.find((option) => option.id == districtId)?.id ?? null}
              // onChange={(__event, option, reason) => {
              //   handleDistrictChange(option, reason);
              //   handleFieldChange('district', option);
              // }}
              onChange={(__event, option, reason) => {
                if (cartItems?.length >= 1 && reason !== 'clear' && option?.id !== existCartLocations.districtId) {
                  // if (reason !== 'clear') {
                  dispatch(setIsLocationModelOpen(true));
                  setSelectedOption((prev) => ({
                    ...prev,
                    district: { option, reason },
                  }));
                  // }
                } else {
                  handleDistrictChange(option, reason);
                }
                handleFieldChange('district', option);
              }}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              name="tehsil"
              options={dropdownTehsilList.data ?? []}
              renderInput={(params) => <TextField {...params} placeholder="Tehsil" error={errors.tehsil} helperText={errors.tehsil ? 'Tehsil is required' : ''} />}
              isOptionEqualToValue={(option, value) => option.id == value}
              getOptionLabel={(option) => getOptionLabel(option, dropdownTehsilList)}
              value={dropdownTehsilList?.data?.find((option) => option.id == locationId)?.id ?? null}
              onChange={(_, option, reason) => {
                const isLocationChanged = cartItems?.length >= 1 && reason !== 'clear' && option?.id !== existCartLocations.locationId;

                const handleSuccess = (res) => {
                  const isWorkshopSelectIdInResponse = res?.[0]?.id === workshopSelectId;
                  if (!isWorkshopSelectIdInResponse) {
                    dispatch(setIsLocationModelOpen(true));
                    setSelectedOption((prev) => ({
                      ...prev,
                      tehsil: { option, reason },
                    }));
                  } else {
                    handleTehsilChange(option, reason);
                  }
                };

                // Proceed with location change logic only if the selection is not cleared
                if (isLocationChanged) {
                  WorkShoplocationSelection.mutate(option?.id, {
                    onSuccess: handleSuccess,
                  });
                } else if (!isLocationChanged) {
                  handleTehsilChange(option, reason);
                }

                handleFieldChange('tehsil', option);
              }}
            />
          </FormControl>
        </div>
      </Popover>
    </div>
  );
};

export default PopoverWithDropdowns;
