import { Skeleton, Tab, Tabs } from '@mui/material';
import { useLanguage } from '../../language/useLanguage';
import { dateFormat } from '../../config/common-function/date-time-format';
import { LazyImage } from '../../assets/image/lazyImage';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetOrders } from '../booking/booking-api';
import { useAuthContext } from '../../context/authContext';
import { useState } from 'react';
import { formatAmount } from '../../config/common-function/calculation';
import IconArrowRight from '../../assets/icons/icon-common/icon-arrow-right';
import { capitalizeFirstLetter } from '../common/first-letter-capital/first-letter-capital';

export default function MyOrder() {
  const { getData } = useAuthContext();
  const { userData } = getData();
  const langData = useLanguage();
  //   const bookings = useGetBookings(userData.user_id);
  const orders = useGetOrders(userData.user_id);
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(searchParams.get('list') ?? 'pending');
  const handleTabChange = (event) => {
    if (event.target?.id) {
      setActiveTab(event.target.id);
      setSearchParams([['list', event.target.id]], { replace: true });
    }
  };

  if (orders.isError) return <p>Error</p>;
  if (orders.isLoading) return <BookingLoading activeTab={activeTab} />;
  const [pendingOrders, partialOrders, completedOrders, cancelledOrders] = getBookingData(orders.data);

  return (
    <div className="booking-container">
      <h1>{langData('My Orders')}</h1>
      <div className="booking-tabs">
        <Tabs value={activeTab} onClick={handleTabChange} variant="scrollable">
          <Tab label={langData('Pending')} id="pending" value="pending" />
          <Tab label={langData('Partial')} id="partial" value="partial" />
          <Tab label={langData('Completed')} id="completed" value="completed" />
          <Tab label={langData('Cancelled')} id="cancelled" value="cancelled" />
        </Tabs>
        <CustomTabPanel value={activeTab} index="pending" className="booking-panel">
          <BookingList orderData={pendingOrders} activeTab={activeTab} />
        </CustomTabPanel>
        <CustomTabPanel value={activeTab} index="partial" className="booking-panel">
          <BookingList orderData={partialOrders} activeTab={activeTab} />
        </CustomTabPanel>
        <CustomTabPanel value={activeTab} index="completed" className="booking-panel">
          <BookingList orderData={completedOrders} activeTab={activeTab} />
        </CustomTabPanel>
        <CustomTabPanel value={activeTab} index="cancelled" className="booking-panel">
          <BookingList orderData={cancelledOrders} activeTab={activeTab} />
        </CustomTabPanel>
      </div>
    </div>
  );
}

function getBookingData(data = []) {
  const pendingOrders = [];
  const completedOrders = [];
  const cancelledOrders = [];
  const partialOrders = [];

  data.forEach((order) => {
    switch (order.status) {
      case 'completed':
        completedOrders.push(order);
        break;
      case 'cancelled':
        cancelledOrders.push(order);
        break;
      case 'partial':
        partialOrders.push(order);
        break;
      case 'pending':
        //   case "payment-failed":
        //   case "payment-pending":
        pendingOrders.push(order);
        break;
      default:
        pendingOrders.push(order);
        break;
    }
  });

  return [pendingOrders, partialOrders, completedOrders, cancelledOrders];
}

function BookingList({ orderData = [], activeTab }) {
  if (orderData.length === 0) return <NoBooking />;
  return (
    <div className="booking-card-container">
      {orderData.map((order) => (
        <BookingCard key={order.id} order={order} activeTab={activeTab} />
      ))}
    </div>
  );
}

function BookingCard({ order, activeTab }) {
  const navigate = useNavigate();
  const langData = useLanguage();
  console.debug(activeTab, 'activeTab');
  return (
    <div className="booking-card" onClick={() => navigate(`/orders/view/${order.id}`, { state: activeTab })}>
      <div className="heading-container">
        <h3>Order TSCO{order.id}</h3>
        <IconArrowRight />
      </div>
      <hr style={{ border: '0.2px solid #EEF1F6' }} />

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 20,
          marginBottom: '2rem',
          marginTop: '1rem',
        }}
      >
        {order.CustomerOrderItems.map((orderItem, index) =>
          orderItem.Booking ? (
            <LazyImage key={index} name={orderItem.Booking?.Service?.Item?.ServiceType.service_type_img} width={38.96} height={38.96} />
          ) : (
            <LazyImage key={index} name={orderItem.Item?.item_img} width={38.96} height={38.96} />
          ),
        )}
      </div>
      <hr style={{ border: '0.2px solid #EEF1F6' }} />

      <div
        style={{
          display: 'flex',
          gap: 48,
          paddingBottom: '1rem',
          paddingTop: '1rem',
        }}
      >
        <div>
          <div className="icon-container" style={{ display: 'flex', fontSize: '3rem' }}></div>
          <div>
            <p
              style={{
                fontWeight: 500,
                color: 'var(--clr-gray)',
                marginBottom: 4,
              }}
            >
              {langData('Order Payment')}
            </p>
            <p
              style={{
                fontWeight: 600,
                color: 'var(--clr-primary)',
                fontSize: 18,
              }}
            >
              ₹ {`${formatAmount(order.total_amount)}`} ({capitalizeFirstLetter(order.payment_status)})
            </p>
          </div>
        </div>
        <div>
          <div className="icon-container"></div>
          <div>
            <p
              style={{
                fontWeight: 500,
                color: 'var(--clr-gray)',
                marginBottom: 4,
              }}
            >
              {langData('Order Date')}
            </p>
            <p
              style={{
                fontWeight: 600,
                color: 'var(--clr-primary)',
                fontSize: 18,
              }}
            >{`${dateFormat(order.created_at)}`}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other} style={{ minHeight: '38vh' }}>
      {value === index && <>{children}</>}
    </div>
  );
}

function NoBooking() {
  const langData = useLanguage();
  return (
    <div style={{ textAlign: 'center', width: '100%', padding: '4rem 0rem 2rem' }}>
      <img style={{ width: '100%', maxWidth: '400px' }} src="/icons/no-booking.svg" alt="No-Workshop" />
      <p style={{ fontSize: 20, margin: '16px 0px' }}>{langData('No Bookings Available')}</p>
    </div>
  );
}

function BookingLoading({ activeTab }) {
  const langData = useLanguage();
  return (
    <div className="booking-container">
      <h1>{langData('My Bookings')}</h1>
      <div className="booking-tabs">
        <Tabs value={activeTab}>
          <Tab label={langData('Pending')} id="pending" value="pending" />
          <Tab label={langData('Partial')} id="partial" value="partial" />
          <Tab label={langData('Completed')} id="completed" value="completed" />
          <Tab label={langData('Cancelled')} id="cancelled" value="cancelled" />
        </Tabs>
        <CustomTabPanel value={activeTab} index="pending" className="booking-panel">
          <BookingListLoading count={3} />
        </CustomTabPanel>
        <CustomTabPanel value={activeTab} index="partial" className="booking-panel">
          <BookingListLoading count={3} />
        </CustomTabPanel>
        <CustomTabPanel value={activeTab} index="completed" className="booking-panel">
          <BookingListLoading count={3} />
        </CustomTabPanel>
        <CustomTabPanel value={activeTab} index="cancelled" className="booking-panel">
          <BookingListLoading count={3} />
        </CustomTabPanel>
      </div>
    </div>
  );
}

function BookingListLoading({ count = 3 }) {
  const countArr = [];
  let i = 1;
  while (i <= count) {
    countArr.push(i++);
  }
  return (
    <div className="booking-card-container">
      {countArr.map((x) => (
        <div key={x} className="booking-card">
          <div className="heading-container">
            <h3>
              <Skeleton variant="text" width={80} />
            </h3>
            <p>
              <Skeleton variant="text" width={100} />
            </p>
          </div>
          <div className="details-container">
            <div style={{ flexBasis: 250, flexGrow: 1 }}>
              <div className="icon-container">
                <Skeleton variant="circular" width={40} height={40} />
              </div>
              <div className="text-details">
                <p className="title">{<Skeleton variant="text" width={80} />}</p>
                <p className="details">{<Skeleton variant="text" width={100} />}</p>
              </div>
            </div>
            <div style={{ flexBasis: 400, flexGrow: 1 }}>
              <div className="icon-container">
                <Skeleton variant="circular" width={40} height={40} />
              </div>
              <div className="text-details">
                <p className="title">{<Skeleton variant="text" width={80} />}</p>
                <p className="details">{<Skeleton variant="text" width={100} />}</p>
              </div>
            </div>
            <div style={{ flexBasis: 250, flexGrow: 1 }}>
              <div className="icon-container">
                <Skeleton variant="circular" width={40} height={40} />
              </div>
              <div className="text-details">
                <p className="title">{<Skeleton variant="text" width={80} />}</p>
                <p className="details" style={{ textTransform: 'capitalize' }}>
                  {<Skeleton variant="text" width={100} />}
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
