import { useLocation } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import CustomerOrderBanner from './customer-order-banner/customer-order-banner';
import ItemList from './items-selection/items-list';
import WorkShoplocationSelection from './tractor-workshop-selection/tractor-workshop-selection';
import { useGetBrandFromLink, useGetModelFromLink } from '../layout/header/header-api';
import { setTractorBrandId, setTractorBrandName, setTractorModelId, setTractorModelName } from '../../redux/features/customer-order/customerOrderSlice';
import { useDispatch, useSelector } from 'react-redux';
import { TractorServices } from './tractor-workshop-selection/tractor-service-card';
import { useGetAllServiceTypeV3Trial, useGetCustomerTractor } from './tractor-workshop-selection/tractor-workshop-api';
import { setHideHeader, setPrevScrollPos } from '../../redux/features/tractor-selection-sticky/tractorSelectionSticky';

const order = ['customer-order'];
export default function CustomerOrderAdd() {
  const sectionsRef = useRef([]);
  const location = useLocation();
  const dispatch = useDispatch();
  const [activeSection, setActiveSection] = useState(0); // Track active section
  const brand_name = order.includes(location.pathname.split('/')[1]) ? null : location.pathname.split('/')[1];
  const model_name = order.includes(location.pathname.split('/')[2]) ? null : location.pathname.split('/')[2];
  const brand = useGetBrandFromLink(
    brand_name
      ? {
          brand_name,
        }
      : null,
  );
  const model = useGetModelFromLink(
    model_name
      ? {
          brand_name,
          model_name,
        }
      : null,
  );
  const customerTractors = useGetCustomerTractor();

  const scrollToSection = (index) => {
    if (sectionsRef.current[index]) {
      const element = sectionsRef.current[index];
      const offset = 120; // Adjust this value to control the scroll height
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
      setActiveSection(index);
    }
  };

  if (!order.includes(location.pathname.split('/')[1])) {
    if (brand.data) {
      localStorage.removeItem('tractor_brand');
      dispatch(setTractorBrandName(brand.data?.brand_name ?? ''));
      dispatch(setTractorBrandId(brand.data?.id ?? null));
      localStorage.setItem(
        'tractor_brand',
        JSON.stringify({
          brand_name: brand?.data?.brand_name,
          brand_id: brand?.data?.id,
        }),
      );
    }
    if (model.data) {
      localStorage.removeItem('tractor_model');
      dispatch(setTractorModelName(model.data?.model_name ?? ''));
      dispatch(setTractorModelId(model.data?.id ?? null));
      localStorage.setItem(
        'tractor_model',
        JSON.stringify({
          model_name: `${brand?.data?.brand_name} ${model?.data?.model_name}`,
          model_id: model?.data?.id,
        }),
      );
    }
  }
  const serviceTypeListTrial = useGetAllServiceTypeV3Trial();
  const sectionHeadingRef = useRef(null);
  const prevScrollPos = useSelector((state) => state.tractor_selection.prevScrollPos);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY; // Get current scroll position
      const headingTop = sectionHeadingRef?.current?.getBoundingClientRect().top;
      sectionsRef.current.forEach((section, index) => {
        const rect = section?.getBoundingClientRect();
        const offset = 120; // Same offset used in scrollToSection
        if (rect?.top <= offset && rect?.bottom >= offset) {
          setActiveSection(index); // Set the section as active when it's in view
        }
      });
      if (headingTop <= 22 && currentScrollPos > prevScrollPos) {
        dispatch(setHideHeader(true)); // Hide header when scrolling down
      } else if (currentScrollPos < prevScrollPos) {
        dispatch(setHideHeader(false)); // Show header when scrolling up
      }

      // Update previous scroll position
      dispatch(setPrevScrollPos(currentScrollPos));
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos, dispatch]);

  // for customer tractor selection  sticky bar
  const handleTractorSelection = () => {
    const currentSections = sectionsRef.current;
    const scrollY = window.scrollY;
    const windowHeight = window.innerHeight;
    let closestSectionIndex = null;

    // Determine the active section based on scroll direction and visibility
    currentSections.forEach((section, index) => {
      const rect = section?.getBoundingClientRect();

      // Handle scrolling down: Check if the section's top is near the top of the viewport
      if (scrollY > window.prevScrollY && rect?.top >= 0 && rect?.top <= windowHeight / 2) {
        closestSectionIndex = index;
      }

      // Handle scrolling up: Check if the section's bottom is near the bottom of the viewport
      if (scrollY < window.prevScrollY && rect?.bottom <= windowHeight && rect?.bottom >= windowHeight / 2) {
        closestSectionIndex = index;
      }
    });

    if (closestSectionIndex !== null) {
      setActiveSection(closestSectionIndex);
    }

    window.prevScrollY = scrollY;
  };

  useEffect(() => {
    // Set the initial scroll position
    window.prevScrollY = window.scrollY;

    // Add the scroll event listener
    window.addEventListener('scroll', handleTractorSelection);

    // Remove the event listener on cleanup
    return () => {
      window.removeEventListener('scroll', handleTractorSelection);
    };
  }, []);

  return (
    <>
      <CustomerOrderBanner />
      <WorkShoplocationSelection
        activeSection={activeSection}
        sectionHeadingRef={sectionHeadingRef}
        sectionsRef={sectionsRef}
        scrollToSection={scrollToSection}
        serviceTypeListTrial={serviceTypeListTrial}
      />
      <TractorServices serviceTypeListTrial={serviceTypeListTrial} sectionsRef={sectionsRef} path={location.pathname} customerTractors={customerTractors} />
      <ItemList sectionsRef={sectionsRef} path={location.pathname} />
    </>
  );
}
