import { Autocomplete, Avatar, Box, Button, Checkbox, Drawer, FormControlLabel, InputAdornment, Radio, Tab, Tabs, TextField } from '@mui/material';
import IconClose from '../../../assets/icons/icon-common/icon-close';
import { LazyImage } from '../../../assets/image/lazyImage';
import IconLocationAvatar from '../../../assets/icons/icon-common/icon-location-avatar';
import { Link, useNavigate } from 'react-router-dom';
import { setCartState } from '../../../redux/features/cart/cartSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { AppointmentDialogDrawer, TractorModelAdd } from './styled';
import { forwardRef, Fragment, useEffect, useState } from 'react';
import IconTractorTab from '../../../assets/icons/icon-common/icon-tractor-tab';
import IconTractorTime from '../../../assets/icons/icon-common/icon-tractor-time';
import {
  setChassisNo,
  setCustomerTractorId,
  setIsAppointmentDrawerOpen,
  setIsAppointmentModelOpen,
  setIsEditAppointmentDrawerOpen,
  setIsEditAppointmentModelOpen,
} from '../../../redux/features/customer-order/customerOrderSlice';
import IconNoCustomerTractors from '../../../assets/icons/icon-common/icon-no-customer-tractor';
import IconCheckboxUnchecked from '../../../assets/icons/icon-common/icon-checkbox-unchecked';
import IconCheckboxChecked from '../../../assets/icons/icon-common/icon-checkbox-checked';
import { getFormData } from '../../../form/form-data';
import { useGetBookingWorkshopTimings } from '../../booking/booking-api';
import { useLanguage } from '../../../language/useLanguage';
import { useUpdateCartService } from '../../layout/cart/cart-api';
import { useAddToCart, useAddTractor } from './book-appointment-model-api';
import InputNumber from '../../../assets/custom-fields/input-number';
import { getLastYears } from '../../../config/data-generate/data-generate';
import { useAuthContext } from '../../../context/authContext';

function BookAppointmentDrawerMobile({
  toggleDrawer,
  customerTractors,
  tractorModelName,
  tractorBrandName,
  selectedService,
  selectServicePriceOffer,
  selectServicePriceTotal,
  workshopName,
  workshopAddress1,
  workshopAddress2,
  tractorModelId,
  tractorBrandId,
  WorkshopDirection,
  service_id,
  setChange,
  modelOpen,
  chassisNO,
  otherDetails,
  customerTractorId,
  cartItemId,
  workshopIdUser,
  isForNavigation,
  editCustomerTractorId,
  isPastService,
}) {
  const customerSelectTractorModel =
    customerTractors.data?.length > 0 ? customerTractors.data.filter((model) => model.tractor_model_id === tractorModelId && model.status !== 2) : [];
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setIsAppointmentModelOpen(false));
    dispatch(setIsAppointmentDrawerOpen(false));
    dispatch(setCustomerTractorId(null));
    dispatch(setIsEditAppointmentModelOpen(false));
    dispatch(setIsEditAppointmentDrawerOpen(false));
  };

  const [activeTab, setActiveTab] = useState('1');
  const handleTabChange = (event) => event.target?.id && setActiveTab(event.target.id);

  const {
    control,
    watch,
    register,
    setValue,
    formState: { errors },
    reset,
    getValues,
    handleSubmit,
  } = useForm({
    defaultValues: {
      customer_tractor_id: customerTractorId ?? null, // Set initial value to null or a specific tractor ID
      tractor_brand_id: null,
      tractor_model_id: null,
      reg_no: '',
      purchase_year: null,
      manufacture_year: null,
      hours_used: '',
    },
  });

  const [customer_tractor_id] = watch(['customer_tractor_id']);
  const [isTractorModelAdd, setIstractorModelAdd] = useState(false);
  useEffect(() => {
    setValue('tractor_brand_id', tractorBrandName);
    setValue('tractor_model_id', tractorModelName);
    setValue('customer_tractor_id', customerTractorId);
  }, [tractorBrandName, tractorModelName, customerTractorId, setValue]);

  const { getData } = useAuthContext();
  const { userData } = getData();
  const addTractor = useAddTractor(userData.user_id);

  const submit = (inputs) => {
    const { tractor_brand_id, tractor_model_id } = getValues();
    inputs.tractor_brand_id = tractorBrandId;
    inputs.tractor_model_id = tractorModelId;
    const form = new FormData();
    for (const key in inputs) {
      form.append(key, inputs[key]);
    }
    addTractor.mutate(form, {
      onSuccess: (res) => {
        dispatch(setCustomerTractorId(res.data.id ?? null));
        setValue('customer_tractor_id', res.data.id ?? null);
        reset({
          tractor_brand_id,
          tractor_model_id,
        });
        setIstractorModelAdd(false);
      },
    });
  };
  const cartState = useSelector((state) => state.cart.cartState);
  const navigate = useNavigate();
  useEffect(() => {
    if (customerTractors?.data) {
      let isChasisAvail = customerTractors.data.find((tractor) => tractor.id === customer_tractor_id);
      if (isChasisAvail !== undefined) {
        dispatch(setChassisNo(isChasisAvail?.reg_no));
      }
    }
  }, [customerTractors, customer_tractor_id, dispatch]);

  const cart_items = useSelector((state) => state.cart.cartItems);
  const customer_tractors_in_cart = cart_items?.filter((cart) => cart.service_id !== null).map((x) => x.customer_tractor_id);

  return (
    <div>
      <AppointmentDialogDrawer
        anchor="bottom"
        open={modelOpen}
        onClose={toggleDrawer(false)}
        sx={{
          '& .MuiDrawer-paper': {
            height: '95%', // Set drawer height to 80%
            // padding: '1rem',
          },
        }}
      >
        <div className="appointment-container">
          <div className="appointment-header">
            <h2>Book a service appointment</h2>
            <span onClick={toggleDrawer(false)}>
              <IconClose className="close-icon" />
            </span>
          </div>
          <div className="appointment-divider"></div>
        </div>

        <div className="service-container">
          <div className="selected-tractor">
            <h5>Selected Tractor</h5>
            <div className="selected-tractor-model">
              <div className="tractor-img">
                <Avatar className="icon-container">
                  <LazyImage name fallbackImage="tractor-model-image.svg" width={'100%'} height={50} />
                </Avatar>
              </div>
              <div className="tractor-details">
                <div>
                  <p
                    style={{
                      fontWeight: '600',
                      fontSize: '16px',
                      lineHeight: '20px',
                    }}
                  >
                    {tractorBrandName !== '' ? tractorBrandName : 'Mahindra'}
                  </p>
                  <p
                    style={{
                      fontWeight: '600',
                      fontSize: '14px',
                      lineHeight: '20px',
                    }}
                  >
                    {tractorModelName !== '' ? tractorModelName : '275 DI SP Plus'}{' '}
                  </p>
                  {customer_tractor_id && (
                    <p
                      style={{
                        wordBreak: 'break-word',
                        color: 'var(--clr-gray)',
                        fontSize: '13px',
                        lineHeight: '16px',
                      }}
                    >
                      (Chassis No : {chassisNO})
                    </p>
                  )}
                </div>
                <span
                  style={{
                    color: 'var(--clr-secondary)',
                    textDecoration: 'underline',
                    fontSize: 14,
                    paddingLeft: 4,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    const brand = isForNavigation.brandName.trim().split(' ').join('-').toLowerCase();
                    const model = isForNavigation.modelName.trim().split(' ').join('-').toLowerCase();
                    navigate(`/${brand}/${model}`);
                    dispatch(setChange(true));
                    const anchor = window.innerWidth > 600 ? 'right' : 'bottom';
                    dispatch(setCartState({ ...cartState, [anchor]: false }));
                    handleClose();
                  }}
                >
                  Edit
                </span>
              </div>
            </div>
          </div>
          <div className="selected-service">
            <h5>Selected Service</h5>
            <div className="selected-tractor-model">
              <div className="tractor-img">
                <Avatar className="icon-container">
                  <LazyImage name fallbackImage="tractor-model-image.svg" width={'100%'} height={50} />
                </Avatar>
              </div>
              <div className="tractor-details">
                <div>
                  <h4 style={{ lineHeight: '20px' }}>{selectedService} </h4>
                  <p>
                    <span className="offer-price">{`₹${selectServicePriceOffer}`}</span>
                    <span className="total-price">{`₹${selectServicePriceTotal}`}</span>
                  </p>
                </div>
                <span
                  style={{
                    color: 'var(--clr-secondary)',
                    textDecoration: 'underline',
                    fontSize: 14,
                    paddingLeft: 4,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    const brand = isForNavigation.brandName.trim().split(' ').join('-').toLowerCase();
                    const model = isForNavigation.modelName.trim().split(' ').join('-').toLowerCase();
                    navigate(`/${brand}/${model}`);
                    // dispatch(setServiceEdit(true));
                    const anchor = window.innerWidth > 600 ? 'right' : 'bottom';
                    dispatch(setCartState({ ...cartState, [anchor]: false }));
                    handleClose();
                  }}
                >
                  View
                </span>{' '}
              </div>
            </div>
          </div>
          <div className="selected-workshop">
            <h5>Workshop</h5>
            <div className="selected-tractor-model">
              <div className="tractor-img">
                <Avatar className="icon-container" style={{ height: 45, width: 45 }}>
                  <IconLocationAvatar />
                </Avatar>
              </div>
              <div className="tractor-details">
                <div>
                  <p
                    style={{
                      fontWeight: '600',
                      fontSize: '16px',
                      lineHeight: '20px',
                    }}
                  >
                    {workshopName}
                  </p>
                  <p
                    style={{
                      fontWeight: 500,
                      color: '#6D7D93',
                      fontSize: 13,
                      lineHeight: '16px',
                    }}
                  >
                    {workshopAddress1}, &nbsp;
                    {workshopAddress2}
                  </p>
                </div>
                <span>
                  {' '}
                  <Link to={WorkshopDirection} style={{ color: 'var(--clr-secondary)', fontSize: 14 }}>
                    {' '}
                    Get Direction
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: '100%', overflowY: 'scroll', marginTop: '3px' }} className="custom-scrollbar-popup">
          <Tabs
            value={activeTab}
            onClick={handleTabChange}
            scrollButtons={false}
            sx={{
              '&&.MuiTabs-root .MuiTabs-flexContainer': {
                gap: `${activeTab === '2' ? '0px' : '4px'}`,
                width: '100%',
              },
              '&&.MuiTabs-root .MuiTab-root ': {
                boxShadow: `${activeTab === '2' ? 'inset 0px -3px 0px 0px  var(--clr-secondary)' : 'inset 0px -3px 0px 0px #d6dce3'}`,
              },
              '& .MuiTab-root .MuiBox-root .tab-steps': {
                color: '#6D7D93', // default color
              },
              '& .Mui-selected .step-des': {
                color: '#EC2726', // active tab color
              },
            }}
            style={{
              padding: '1rem 1rem',
              paddingTop: '0px',
            }}
          >
            <Tab
              disableRipple
              sx={{
                width: '100%',
                maxWidth: '50%',
                padding: '0px !important',
              }}
              label={
                <Box
                  display={'flex'}
                  paddingBottom={'6px'}
                  gap={'0.4rem'}
                  justifyContent={'start'}
                  flexDirection={'column'}
                  width={'100%'}
                  component={'div'}
                  onClick={() => setActiveTab('1')}
                >
                  <IconTractorTab height="40px" width="40px" activeTab={activeTab} />

                  <div>
                    <p
                      className="tab-steps"
                      style={{
                        textAlign: 'start',
                        fontSize: '14px',
                        // color: "#6D7D93",
                      }}
                    >
                      step 1
                    </p>
                    <p className="step-des" style={{ textAlign: 'start', fontSize: '16px' }}>
                      Add / Select Tractor
                    </p>
                  </div>
                </Box>
              }
              id="1"
              value="1"
            />
            <Tab
              disabled={!customer_tractor_id}
              disableRipple
              sx={{ width: '100%', maxWidth: '50%', padding: '0px !important' }}
              label={
                <Box
                  display={'flex'}
                  justifyContent={'start'}
                  flexDirection={'column'}
                  paddingBottom={'6px'}
                  gap={'0.4rem'}
                  width={'100%'}
                  component={'div'}
                  onClick={() => setActiveTab('2')}
                >
                  <IconTractorTime
                    height="40px"
                    width="40px"
                    color={activeTab == 2 ? '#EC2726' : '#0028520D'}
                    color2={activeTab == 2 ? 'white' : '#6D7D93'}
                    opacity={activeTab == 2 ? '1' : '0.5'}
                  />
                  <div>
                    <p
                      className="tab-steps"
                      style={{
                        textAlign: 'start',
                        fontSize: '14px',
                      }}
                    >
                      step 2
                    </p>
                    <p className="step-des" style={{ textAlign: 'start', fontSize: '16px' }}>
                      Select Slot
                    </p>
                  </div>
                </Box>
              }
              id="2"
              value="2"
            />
          </Tabs>
          {activeTab === '1' && (
            <form
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleSubmit(submit)(e);
              }}
            >
              <div
                style={{
                  display: 'flex',
                  gap: 6,
                  alignItems: 'center',
                  padding: '0px 1rem',
                }}
              >
                {!isTractorModelAdd ? (
                  <>
                    <p
                      style={{
                        color: '#002852',
                        fontSize: '21px',
                        fontWeight: 600,
                      }}
                    >
                      Select Your Tractor
                    </p>
                    <span
                      style={{
                        color: '#EC2726',
                        borderBottom: '1px solid #EC2726 ',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setIstractorModelAdd(true);
                      }}
                    >
                      + Add New Tractor
                    </span>
                  </>
                ) : (
                  <p
                    style={{
                      color: '#002852',
                      fontSize: '21px',
                      fontWeight: 600,
                    }}
                  >
                    Add New Tractor
                  </p>
                )}
              </div>
              {!isTractorModelAdd ? (
                <div style={{ height: '100%', minHeight: '600px' }}>
                  {customerSelectTractorModel?.length > 0 ? (
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: ' repeat(auto-fill, minmax(350px, 1fr))',
                        gap: '1rem',
                        width: 'calc(100% - 1rem)',
                        margin: '13px auto',
                      }}
                    >
                      <Controller
                        name="customer_tractor_id"
                        control={control}
                        render={({ field }) => (
                          <>
                            {customerSelectTractorModel?.map((model) => (
                              <div
                                key={model.id}
                                className="modal-pop-card"
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                  background: '#F2F7FF',
                                  padding: '15px',
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  if (
                                    customer_tractors_in_cart?.includes(model.id) &&
                                    model.id !== customerTractorId &&
                                    (editCustomerTractorId ? model.id !== editCustomerTractorId : true)
                                  ) {
                                    return false;
                                  } else {
                                    field.onChange(model.id);
                                    dispatch(setChassisNo(model?.reg_no));
                                  }
                                }} // Set the index as the value
                              >
                                <div>
                                  <FormControlLabel
                                    control={
                                      <Radio
                                        checked={field.value === model.id} // Check the radio button based on the value
                                        disabled={
                                          customer_tractors_in_cart?.includes(model.id) &&
                                          model.id !== customerTractorId &&
                                          (editCustomerTractorId ? model.id !== editCustomerTractorId : true)
                                        }
                                        // disabled={true}
                                        onChange={() => {
                                          field.onChange(model.id);
                                          dispatch(setCustomerTractorId(model.id));
                                        }} // Ensure the radio button state is updated
                                      />
                                    }
                                    label=""
                                  />
                                </div>
                                <div className="pop-card-container">
                                  <div className="pop-card-media">
                                    <Avatar className="icon-container">
                                      <LazyImage name={model.TractorModel.model_img} fallbackImage="tractor-model-image.svg" width={'100%'} height={50} />
                                    </Avatar>
                                    <div className="tractor-names">
                                      <p>{model.TractorModel.TractorBrand.brand_name}</p>
                                      <p>{model.TractorModel.model_name}</p>
                                    </div>
                                  </div>
                                  <div className="pop-card-details">
                                    <div className="pop-card-content">
                                      <p className="content-head">Chassis No</p>
                                      <p className="content-head-value">{model.reg_no}</p>
                                    </div>
                                    <div className="pop-card-content">
                                      <p className="content-head">Year of Purchase</p>
                                      <p className="content-head-value">{model.purchase_year}</p>
                                    </div>
                                    <div className="pop-card-content">
                                      <p className="content-head">Year of Manufacture</p>
                                      <p className="content-head-value">{model.manufacture_year}</p>
                                    </div>
                                    <div className="pop-card-content">
                                      <p className="content-head">Used Hours</p>
                                      <p className="content-head-value">{model.hours_used}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </>
                        )}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        height: '400px',
                      }}
                    >
                      <IconNoCustomerTractors
                      // style={{ display: "block", margin: "auto" }}
                      />
                      <h4>No Tractors Saved</h4>
                      <p style={{ color: 'var(--clr-gray)' }}>Add new tractor or change the brand and model to se your saved tractor</p>
                    </div>
                  )}
                </div>
              ) : (
                <TractorModalAdd register={register} control={control} setValue={setValue} errors={errors} tractorBrandName={tractorBrandName} />
              )}
              {/* <Divider /> */}
              <div
                style={{
                  padding: ' 0rem 1rem 1rem',
                  marginTop: '-12px',
                }}
              >
                {!isTractorModelAdd ? (
                  <Button
                    variant="contained"
                    disabled={!customer_tractor_id}
                    onClick={() => setActiveTab('2')}
                    sx={{
                      padding: '1rem',
                      '&&.MuiButton-root.MuiButtonBase-root.MuiButton-contained.Mui-disabled': { background: '#6D7D93', color: '#fff' },
                      '&&.MuiButton-root.MuiButtonBase-root.MuiButton-contained': { width: '100%' },
                    }}
                  >
                    Continue
                  </Button>
                ) : (
                  <div style={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}>
                    <div style={{ flex: 1 }}>
                      <Button sx={{ '&&.MuiButton-root.MuiButtonBase-root': { width: '100%' } }} variant="outlined" onClick={() => setIstractorModelAdd(false)}>
                        Cancel
                      </Button>
                    </div>
                    <div style={{ flex: 2 }}>
                      <Button sx={{ '&&.MuiButton-root.MuiButtonBase-root': { width: '100%' } }} variant="contained" type="submit">
                        Save & Continue
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </form>
          )}
          {activeTab === '2' && (
            <FormDateTime
              customer_tractor_id={customer_tractor_id}
              customer_id={userData.user_id}
              customer_address_id={userData.customer_address_id}
              setActiveTab={setActiveTab}
              handleClose={handleClose}
              service_id={service_id}
              otherDetails={otherDetails}
              cartItemId={cartItemId}
              workshopIdUser={workshopIdUser}
              isPastService={isPastService}
            />
          )}
        </div>
      </AppointmentDialogDrawer>
    </div>
  );
}

export default BookAppointmentDrawerMobile;

export const AutocompleteImageOption = forwardRef(({ option, imgSrc, fallBackSrc, ...params }, ref) => {
  return (
    <li {...params} style={{ display: 'flex', gap: 8 }} ref={ref}>
      <LazyImage name={imgSrc} height={25} width={25} fallbackImage={fallBackSrc} />
      {option.label ?? option}
    </li>
  );
});
AutocompleteImageOption.displayName = 'AutocompleteImageOption';

function TractorModalAdd({ register, control, errors }) {
  // const brandList = useGetBrand();
  // const modelList = useGetModel(tractor_brand_id);
  const getYears = getLastYears(30);

  return (
    <TractorModelAdd>
      <div className="book-modal-pop">
        <div className="form-column-2" style={{ gap: '0px' }}>
          <div className="field-container">
            <label className="required">Choose Brand</label>
            <Controller
              name="tractor_brand_id"
              control={control}
              rules={{ required: 'Please Select Brand' }}
              render={({ field }) => {
                return (
                  <Autocomplete
                    {...field}
                    options={[]}
                    renderInput={(params) => <TextField {...params} placeholder="Brand" error={!!errors.tractor_brand_id} />}
                    // getOptionLabel={(option) =>
                    //   option.label ??
                    //   brandList.data?.find((opt) => opt.id === option)?.label ??
                    //   ""
                    // }
                    renderOption={(params, option) => (
                      <AutocompleteImageOption {...params} option={option} imgSrc={option.brand_img} fallBackSrc={'placeholder-tractor-brand.svg'} />
                    )}
                    disabled={true}
                  />
                );
              }}
            />
            <p className="form-error-text">{errors.tractor_brand_id?.message}</p>
          </div>
          <div className="field-container">
            <label className="required">Choose Tractor Model</label>
            <Controller
              name="tractor_model_id"
              control={control}
              rules={{ required: 'Please Select Tractor Model' }}
              render={({ field }) => {
                return (
                  <Autocomplete
                    {...field}
                    options={[]}
                    disabled
                    renderInput={(params) => <TextField {...params} placeholder="Model" error={!!errors.tractor_model_id} />}
                    isOptionEqualToValue={(option, value) => option.id === value}
                    // getOptionLabel={(option) =>
                    //   option.label ??
                    //   modelList.data?.find((opt) => opt.id === option)?.label ??
                    //   ""
                    // }
                    onChange={(__event, data) => {
                      field.onChange(data?.id ?? null);
                    }}
                    renderOption={(params, option) => (
                      <AutocompleteImageOption {...params} option={option} imgSrc={option.model_img} fallBackSrc={'placeholder-tractor-model.svg'} />
                    )}
                  />
                );
              }}
            />
            <p className="form-error-text">{errors.tractor_model_id?.message}</p>
          </div>
        </div>
        <div className="form-column-2" style={{ gap: '0px' }}>
          <div className="field-container">
            <label className="required">Chassis No</label>
            <TextField
              {...register('reg_no', {
                required: 'Field is required',
                minLength: {
                  value: 2,
                  message: 'Minimum 2 character required',
                },
                maxLength: { value: 30, message: 'Maximum limit exceed' },
              })}
              error={!!errors.reg_no}
            />
            <p className="form-error-text">{errors.reg_no?.message}</p>
          </div>
          <div className="field-container">
            <label className="required">Year of Manufacture</label>
            <Controller
              name="manufacture_year"
              control={control}
              rules={{
                required: 'Please Select Year of Manufacture',
                deps: ['purchase_year'],
              }}
              render={({ field }) => {
                return (
                  <Autocomplete
                    {...field}
                    options={getYears}
                    value={field.value?.toString() ?? null}
                    renderInput={(params) => <TextField {...params} placeholder="Year of Manufacture" error={!!errors.manufacture_year} />}
                    onChange={(___event, newValue) => {
                      field.onChange(newValue ? Number(newValue) : newValue);
                    }}
                  />
                );
              }}
            />
            <p className="form-error-text">{errors.manufacture_year?.message}</p>
          </div>
        </div>
        <div className="form-column-2" style={{ gap: '0px' }}>
          <div className="field-container">
            <label className="required">Year of Purchase</label>
            <Controller
              name="purchase_year"
              control={control}
              rules={{
                required: 'Please Select purchase year',
                validate: {
                  notLessThanManufacture: (value, formValues) => {
                    return Number(value) < Number(formValues.manufacture_year) ? 'Please select valid year' : true;
                  },
                },
              }}
              render={({ field }) => {
                return (
                  <Autocomplete
                    {...field}
                    options={getYears}
                    value={field.value?.toString() ?? null}
                    renderInput={(params) => <TextField {...params} placeholder="Purchase year" error={!!errors.purchase_year} />}
                    onChange={(___event, newValue) => {
                      field.onChange(newValue ? Number(newValue) : newValue);
                    }}
                    disableClearable // Prevent clear button
                  />
                );
              }}
            />

            {/* <p className="error-text">{errors.purchase_year?.message}</p> */}
            <p className="form-error-text">{errors.purchase_year?.message}</p>
          </div>
          <div className="field-container">
            <label className="required">Hours Used</label>
            <Controller name="hours_used" control={control} render={({ field }) => <InputNumber {...field} />} />
            <p className="error-text">{errors.hours_used?.message}</p>
          </div>
        </div>
      </div>
    </TractorModelAdd>
  );
}

export const ISSUES_DATA = [
  { id: 'engine', label: 'Engine' },
  { id: 'gearbox', label: 'Gearbox' },
  { id: 'clutch', label: 'Clutch' },
  { id: 'other', label: 'Other' },
];

export function FormDateTime({
  isPastService,
  customer_tractor_id,
  customer_id,
  setActiveTab,
  customer_address_id,
  service_id,
  handleClose,
  otherDetails,
  cartItemId,
  workshopIdUser,
}) {
  const workshopID = useSelector((state) => state.location.workshopSelectId);
  const addToCart = useAddToCart();
  // const updateCart = useUpdateCart(cartItemId, customer_id);
  const updateCartSevrvice = useUpdateCartService(cartItemId, customer_id);
  const { watch, control, formState, register, setValue, setFocus, reset, handleSubmit } = useForm({
    defaultValues: {
      date: otherDetails ? otherDetails?.date : null,
      time: otherDetails ? otherDetails?.time : null,
      notes: otherDetails ? otherDetails?.notes : '',
      issues: otherDetails ? otherDetails?.issues : {},
      customer_tractor_id: customer_tractor_id ?? null,
      workshop_id: workshopID ?? workshopIdUser ?? null,
      customer_address_id: customer_address_id ?? null,
      customer_id: customer_id ?? null,
      service_id: service_id ?? null,
    },
  });
  const { errors } = formState;
  const langData = useLanguage();
  const cartState = useSelector((state) => state.cart.cartState);
  const [date, time, notes] = watch(['date', 'time', 'notes']);
  const dispatch = useDispatch();

  const { data: wsDateTime, isLoading } = useGetBookingWorkshopTimings(workshopID ?? workshopIdUser);

  useEffect(() => {
    if (isPastService) {
      setValue('time', null);
    }
  }, [isPastService, setValue]);

  if (isLoading) return <p>Loading...</p>;
  const timeSlots = date ? wsDateTime.find((day) => day.date === date)?.time_slots : wsDateTime[0].time_slots;
  const descLength = notes?.length;

  const submitAddToCart = (res) => {
    const formData = getFormData({ ...res });
    if (cartItemId) {
      updateCartSevrvice.mutate(formData, {
        onSuccess: () => {
          handleClose();
        },
      });
    } else {
      addToCart.mutate(formData, {
        onSuccess: () => {
          setActiveTab('1');
          handleClose();
          const anchor = window.innerWidth > 600 ? 'right' : 'bottom';
          dispatch(setCartState({ ...cartState, [anchor]: true }));
        },
      });
    }
  };

  const findData = wsDateTime.find((day) => day.date === date);

  return (
    <form
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit(submitAddToCart)(e);
      }}
      onReset={reset}
    >
      <div
        className="form-date-time custom-scrollbar-popup"
        style={{
          padding: '0rem 1rem 1rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          flexWrap: 'wrap',
        }}
      >
        <div className="form-date-time-container-1">
          <div className="field-container" style={{ marginBottom: '16px' }}>
            <label>{langData('Select Date')}</label>
            <Controller
              name="date"
              rules={{ required: 'Select Date' }}
              control={control}
              render={(props) => {
                return (
                  <div className="reschedule_dates" style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                    {wsDateTime.map((day) => (
                      <Button
                        sx={{
                          '&&.MuiButton-outlined': {
                            border: '1px solid #002852',
                            color: '#002852',
                            minWidth: '30px',
                          },
                          '&&.MuiButton-contained': {
                            backgroundColor: '#002852',
                            minWidth: '30px',
                          },
                        }}
                        key={day.date}
                        className={day.date === date ? 'Active' : ''}
                        variant={`${day.date === date ? 'contained' : 'outlined'}`}
                        color="primary"
                        style={{ height: 50 }}
                        disabled={!day.status}
                        onClick={() => {
                          props.field.onChange(day.date);
                          setValue('time', null);
                        }}
                      >
                        {day.day}
                        <br />
                        {day.date.split('-')[2]}
                      </Button>
                    ))}
                  </div>
                );
              }}
            />
            <p className="form-error-text" style={{ fontSize: '14px' }}>
              {errors.date?.message}
            </p>
          </div>
          <div className="field-container" style={{ marginBottom: '16px' }}>
            <label className="required">{langData('Select Time')}</label>
            <p className="form-error-text" style={{ marginBottom: '8px', fontSize: '14px' }}>
              {errors.time?.message}
            </p>
            <Controller
              name="time"
              rules={{ required: 'Select Time' }}
              control={control}
              render={(props) => {
                return (
                  <div
                    className="reschedule_dates"
                    style={{
                      display: 'flex',
                      gap: '1em',
                      flexWrap: 'wrap',
                      justifyContent: 'flex-start',
                    }}
                  >
                    {timeSlots?.length > 0 ? (
                      timeSlots?.map((time_booking) => (
                        <Button
                          sx={{
                            '&&.MuiButton-outlined': {
                              border: '1px solid #002852',
                              color: '#002852',
                            },
                            '&&.MuiButton-outlined.Mui-disabled': {
                              border: '1px solid #a4a4a4',
                              color: '#a4a4a4',
                            },
                            '&&.MuiButton-contained': {
                              backgroundColor: '#002852',
                            },
                          }}
                          disabled={!date}
                          key={time_booking}
                          className={time_booking === time ? 'Active' : ''}
                          variant={`${time_booking === time ? 'contained' : 'outlined'}`}
                          color="primary"
                          onClick={() => {
                            props.field.onChange(time_booking ?? null);
                            setFocus('notes');
                          }}
                          style={{
                            flexBasis: 160,
                            paddingInline: 4,
                          }}
                        >
                          {time_booking}
                        </Button>
                      ))
                    ) : (
                      <p
                        style={{
                          textAlign: 'center',
                          fontWeight: 500,
                          color: 'var(--clr-secondary)',
                        }}
                      >
                        No slots available
                        <br />
                        Please choose other date
                      </p>
                    )}
                  </div>
                );
              }}
            />
          </div>
        </div>
        <div className="form-date-time-container-2" style={{ flex: 1 }}>
          <div className="field-container">
            <label>{langData('Are you facing any other problem with your tractor?')}</label>
            <Controller
              name="issues"
              control={control}
              rules={{
                validate: {
                  checkOtherField: (value) => {
                    if (value.other === undefined) return true;
                    else return value.other ? true : 'Please Mention Other Issue';
                  },
                },
              }}
              render={(props) => {
                return <MultiSelectCheckbox {...props} errors={errors} />;
              }}
            />
          </div>
          <div className="field-container">
            <label>{langData('Notes')}</label>
            <TextField
              multiline
              rows={4}
              placeholder={langData('Add Your Notes Here')}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{
                      position: 'absolute',
                      right: 16,
                      bottom: 16,
                      fontSize: 12,
                    }}
                  >
                    <p>{`${descLength} / 1000`}</p>
                  </InputAdornment>
                ),
              }}
              {...register('notes', {
                minLength: {
                  value: 2,
                  message: 'Atleast two character require',
                },
                maxLength: {
                  value: 1000,
                  message: 'Limit exceed, 1000 character allowed',
                },
              })}
              error={!!errors.notes}
            />
            <p className="form-error-text">{errors.notes?.message}</p>
          </div>
        </div>
      </div>
      {/* <Divider /> */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
          padding: '1rem',
          position: 'sticky',
          gap: '1rem',
          bottom: 0,
          backgroundColor: 'white',
          borderTop: '1px solid #c1c1c1',
          zIndex: 1000,
        }}
      >
        <div style={{ flex: 1 }}>
          <Button variant="outlined" onClick={() => setActiveTab('1')}>
            Back
          </Button>
        </div>

        <div style={{ flex: 2 }}>
          <Button
            variant="contained"
            type="submit"
            disabled={cartItemId ? findData == undefined : false}
            sx={{
              '&&.MuiButton-root.MuiButtonBase-root.MuiButton-contained.Mui-disabled': { background: '#6D7D93', color: '#fff', width: '100%' },
              '&&.MuiButton-root.MuiButtonBase-root.MuiButton-contained': {
                width: '100%',
              },
            }}
          >
            {cartItemId ? 'Update' : 'Add to cart'}
          </Button>
        </div>
      </div>
    </form>
  );
}

function MultiSelectCheckbox({ field, errors }) {
  const parsedValue = typeof field.value === 'string' ? JSON.parse(field.value || '{}') : field.value;

  function handleChange(event, isChecked) {
    const name = event.target.value;

    if (isChecked) {
      const newValue = name === 'other' ? '' : name;
      field.onChange({ ...parsedValue, [name]: newValue });
    } else {
      const updatedValue = { ...parsedValue };
      delete updatedValue[name];
      field.onChange(updatedValue);
    }
  }

  function handleChangeText(event) {
    const { name, value: valueText } = event.target;
    field.onChange({
      ...parsedValue,
      [name]: valueText,
    });
  }

  return (
    <div ref={field.ref} style={{ display: 'flex', flexWrap: 'wrap' }}>
      {ISSUES_DATA.map((obj) => (
        <Fragment key={obj.id}>
          <FormControlLabel
            control={
              <div>
                <Checkbox
                  icon={<IconCheckboxUnchecked />}
                  checkedIcon={<IconCheckboxChecked />}
                  onChange={handleChange}
                  value={obj.id}
                  checked={!!Object.keys(parsedValue).includes(obj.id)}
                />
                <span style={{ color: 'var(--clr-black)', fontSize: 14 }}>{obj.id === 'other' ? obj.label : `With ${obj.label}`}</span>
              </div>
            }
          />
          {obj.id === 'other' && parsedValue[obj.id] !== undefined ? (
            <>
              <TextField
                sx={{ marginBottom: '0.6rem', marginTop: '-0.7rem' }}
                fullWidth
                name={obj.id}
                value={parsedValue[obj.id] ?? ''}
                onChange={handleChangeText}
                onBlur={field.onBlur}
                placeholder="Please Mention"
              />
              <p className="form-error-text" style={{ marginBottom: '8px' }}>
                {errors.issues?.message}
              </p>
            </>
          ) : (
            <div></div>
          )}
        </Fragment>
      ))}
    </div>
  );
}
