import { useDispatch, useSelector } from 'react-redux';
import { useLanguage } from '../../../language/useLanguage';
import { useGetItems, useGetKits } from './item-api';
import ItemCard, { KitCard } from './item-cards';
import './item-list.css';
import { getFormData } from '../../../assets/lib/form/form-data';
import { useAddToCart } from '../../layout/cart/cart-api';
import { useAuthContext } from '../../../context/authContext';
import { useGetCustomerTractorList } from '../../homepage/homepage-api';
import { useState } from 'react';
import { AddTractorModel as ProfileAddTractorModel } from '../../profile/components/my-tractor';
import { setCartState } from '../../../redux/features/cart/cartSlice';
import IconNoKits from '../../../assets/icons/icon-common/icon-no-kits';
import IconNoParts from '../../../assets/icons/icon-common/icon-no-parts';

export default function ItemList({ sectionsRef, path }) {
  const { getData } = useAuthContext();
  const { userData } = getData();
  const dispatch = useDispatch();
  const langData = useLanguage();
  const addToCart = useAddToCart();
  const tractorModelId = useSelector((state) => state.customer_order.tractorModelId);

  const workshop_id = useSelector((state) => state.location.workshopSelectId);

  const cartState = useSelector((state) => state.cart.cartState);
  const tractor_model_id = useSelector((state) => state.customer_order.tractorModelId);
  // console.debug(customerTractorList.data);

  const itemsData = useGetItems(tractorModelId, {
    workshop_id: workshop_id,
  });
  const kitsData = useGetKits(tractorModelId, {
    workshop_id: workshop_id,
  });

  const handleAddToCart = (item_id, tractor_model_id) => {
    const formData = getFormData({
      item_id: item_id,
      customer_id: userData.user_id,
      customer_address_id: userData.customer_address_id,
      tractor_model_id: tractor_model_id,
      workshop_id: workshop_id,
    });

    addToCart.mutate(formData);
    const anchor = window.innerWidth > 600 ? 'right' : 'bottom';
    dispatch(setCartState({ ...cartState, [anchor]: true }));
  };

  return (
    <>
      <div className="all-items-list">
        {kitsData?.isFetched && kitsData?.data?.length > 0 ? (
          <div className="kits" ref={(el) => (sectionsRef.current[1] = el)}>
            <h4 id="buy-kits">{langData('Buy Kits')}</h4>
            <div className="kits-list" style={{ flexWrap: 'wrap' }}>
              {kitsData.data.map((item) => (
                <KitCard key={item.item_id} data={item} handleAddToCart={handleAddToCart} model_id={tractor_model_id} path={path} />
              ))}
            </div>
          </div>
        ) : (
          <div className="kits" ref={(el) => (sectionsRef.current[1] = el)}>
            <h4>{langData('Buy Kits')}</h4>
            <div
              style={{
                display: 'block',
                margin: 'auto',
                paddingTop: '1rem',
                paddingBottom: '1rem',
              }}
            >
              <IconNoKits />
            </div>
          </div>
        )}
        {itemsData?.isFetched && itemsData?.data?.length > 0 ? (
          <div id="buy-parts-&-accessories" className="items" ref={(el) => (sectionsRef.current[2] = el)}>
            <h4>{langData('Buy Parts & Accessories')}</h4>
            <div className="items-list">
              {itemsData.data.map((item) => (
                <ItemCard key={item.item_id} data={item} handleAddToCart={handleAddToCart} model_id={tractor_model_id} path={path} />
              ))}
            </div>
          </div>
        ) : (
          <div className="items" ref={(el) => (sectionsRef.current[2] = el)}>
            <h4>{langData('Buy Parts & Accessories')}</h4>
            <div
              style={{
                display: 'block',
                margin: 'auto',
                paddingTop: '1rem',
                paddingBottom: '1rem',
              }}
            >
              <IconNoParts />
            </div>
          </div>
        )}
      </div>
      {/* {addTrPopup && (
        <ProfileAddTractorModel
          open={Boolean(addTrPopup)}
          disableBrand
          disableModel
          inputs={{
            tractor_brand_id: tractorBrandID,
            tractor_model_id: tractorModelId,
            reg_no: '',
            purchase_year: null,
            manufacture_year: null,
            hours_used: '',
          }}
          onClose={handlePopUpClose}
          onSuccess={(res) => handleAddToCart(addTrPopup, res)}
        />
      )} */}
    </>
  );
}
