import { useMutation, useQuery } from '@tanstack/react-query';
import useAxios from '../../../config/axios/useAxios';
import { useNavigate } from 'react-router-dom';
import { promptActions, promptContext } from '../../../context/prompt';
import { useContext } from 'react';
import { useAuthContext } from '../../../context/authContext';

export function useUpdateLanguage() {
  const protectedAxoios = useAxios();
  const API = 'language/change';
  return useMutation({
    mutationFn: (form) => protectedAxoios.put(API, form),
  });
}

export function useGetStates() {
  const API = 'getdata/states';
  const protectedAxios = useAxios();
  const { getData } = useAuthContext();
  const { lang, isAuth } = getData();
  return useQuery({
    queryKey: [API, lang],
    queryFn: async () => {
      const { data } = await protectedAxios.get(API, !isAuth && { params: { language: lang } });
      return data;
    },
  });
}

export function useGetTehsil(districtId) {
  const API = 'getdata/v2/subdistricts';
  const protectedAxios = useAxios();
  const { getData } = useAuthContext();
  const { lang, isAuth } = getData();
  const parsedDistrictId = parseInt(districtId);
  return useQuery({
    queryKey: [API, lang, districtId],
    enabled: !isNaN(parsedDistrictId) && parsedDistrictId !== 0,
    queryFn: async () => {
      const { data } = await protectedAxios.get(`${API}/${parsedDistrictId}`, !isAuth && { params: { language: lang } });
      return data;
    },
  });
}

export function useGetDistricts(state_id) {
  const API = 'getdata/districts';
  const protectedAxios = useAxios();
  const { setToken } = useAuthContext();
  const prompt = useContext(promptContext);
  const { getData } = useAuthContext();
  const { lang } = getData();
  const parsedStateId = parseInt(state_id);
  return useQuery({
    queryKey: [API, parsedStateId, lang],
    enabled: !isNaN(parsedStateId) && parsedStateId !== 0,
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`${API}/${parsedStateId}`, { params: { language: lang } });
        return data;
      } catch (error) {
        if (error.response.status === 401) {
          setToken('clearToken');
          prompt({
            type: promptActions.SHOW_PROMPT,
            payload: {
              message: error.response.data?.message || 'Token Expired',
            },
          });
        }
        return;
      }
    },
  });
}

export function useGetCart(customer_id) {
  const API = 'cart';
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const { getData } = useAuthContext();
  const { lang } = getData();
  const navigate = useNavigate();

  return useQuery({
    enabled: !!customer_id,
    queryKey: [API, customer_id, lang],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(API);
        return data;
      } catch (error) {
        prompt({
          type: promptActions.SHOW_PROMPT,
          payload: {
            message: error.response.data?.message || 'Error while getting data',
          },
        });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}

export function useGetCartSummary(id) {
  const API = `cart/summary/${id}`;
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const navigate = useNavigate();

  return useQuery({
    queryKey: [API, id],
    enabled: !!id,
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(API);
        return data;
      } catch (error) {
        prompt({
          type: promptActions.SHOW_PROMPT,
          payload: {
            message: error.response.data?.message || 'Error while getting data',
          },
        });
        if (error.response.status === 401) navigate('/session-timeout');
        return;
      }
    },
  });
}

export function useGetBrandFromLink(params) {
  const API = '/getData/brand_data';
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);

  return useQuery({
    queryKey: [API, params],
    enabled: !!params,
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(API, { params });
        return data;
      } catch (error) {
        prompt({
          type: promptActions.SHOW_PROMPT,
          payload: {
            message: error.response.data?.message || 'Error while getting data',
          },
        });
      }
    },
  });
}

export function useGetModelFromLink(params) {
  const API = '/getData/model_data';
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);

  return useQuery({
    queryKey: [API, params],
    enabled: !!params,
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(API, { params });
        return data;
      } catch (error) {
        prompt({
          type: promptActions.SHOW_PROMPT,
          payload: {
            message: error.response.data?.message || 'Error while getting data',
          },
        });
      }
    },
  });
}
