import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { promptActions, promptContext } from '../../context/prompt';
import useAxios from '../../config/axios/useAxios';
import { useAuthContext } from '../../context/authContext';

export function useGetBrand() {
  const API = 'getdata/brands';
  const protectedAxios = useAxios();
  const { setToken } = useAuthContext();
  const prompt = useContext(promptContext);
  const { getData } = useAuthContext();
  const { lang } = getData();

  return useQuery({
    queryKey: [lang, API],
    cacheTime: 1000 * 60 * 10,
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`${API}`, { params: { language: lang } });
        return data.filter((obj) => obj.status === 1);
      } catch (error) {
        if (error.response.status === 401) {
          setToken('clearToken');
          prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Token Expired' } });
        }
        return;
      }
    },
  });
}

export function useGetModel(brand_id) {
  const API = 'getdata/models';
  const protectedAxios = useAxios();
  const { setToken } = useAuthContext();
  const prompt = useContext(promptContext);
  const { getData } = useAuthContext();
  const { lang } = getData();
  return useQuery({
    queryKey: [lang, API, brand_id],
    enabled: !!brand_id,
    cacheTime: 1000 * 60 * 5,
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`${API}/${brand_id}`, { params: { language: lang } });
        return data.filter((obj) => obj.status === 1);
      } catch (error) {
        if (error.response.status === 401) {
          setToken('clearToken');
          prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Token Expired' } });
        }
        return;
      }
    },
  });
}

export function useGetCustomerTractor(brand_id) {
  const baseAPI = 'getData/user_models';
  const API = brand_id ? `${baseAPI}/${brand_id}` : baseAPI;

  const protectedAxios = useAxios();
  const { setToken } = useAuthContext();
  const prompt = useContext(promptContext);
  const { getData } = useAuthContext();
  const { lang } = getData();

  return useQuery({
    queryKey: [lang, baseAPI, brand_id],
    cacheTime: 5 * 60 * 1000,
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`${API}`, { params: { language: lang } });
        return data;
      } catch (error) {
        if (error.response.status === 401) {
          setToken('clearToken');
          prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Token Expired' } });
        }
        return;
      }
    },
  });
}

export function useGetAllServiceType(tractor_model_id) {
  const API = 'service_type/user/getall';
  const protectedAxios = useAxios();
  const { setToken } = useAuthContext();
  const prompt = useContext(promptContext);
  const { getData } = useAuthContext();
  const { lang } = getData();
  return useQuery({
    queryKey: [lang, API, tractor_model_id],
    queryFn: async () => {
      try {
        if (tractor_model_id) {
          const params = {
            tractor_model_id: tractor_model_id,
          };
          const { data } = await protectedAxios.get(`${API}`, { params });
          return data;
        } else {
          const { data } = await protectedAxios.get(`${API}`);
          return data;
        }
      } catch (error) {
        if (error.response.status === 401) {
          setToken('clearToken');
          prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Token Expired' } });
        }
        return;
      }
    },
  });
}

export function useGetCustomerTractorList(customer_id) {
  const API = 'customer/tractor/getall';
  const protectedAxios = useAxios();
  const { setToken } = useAuthContext();
  const prompt = useContext(promptContext);
  const { getData } = useAuthContext();
  const { lang } = getData();

  return useQuery({
    queryKey: [API, customer_id, lang],
    enabled: !!customer_id,
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`${API}/${customer_id}`);
        return data;
      } catch (error) {
        if (error.response.status === 401) {
          setToken('clearToken');
          prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Token Expired' } });
        }
        return;
      }
    },
  });
}

export function useAddCustomerTractor(customer_id) {
  const protectedAxios = useAxios();
  const API = 'customer/tractor/add';
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (form) => protectedAxios.post(`${API}/${customer_id}`, form),
    onSuccess: () => {
      queryClient.invalidateQueries(['customer/tractor/getall', customer_id]);
    },
    onError: (error) => {
      console.debug('ERROR', error);
      prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while adding' } });
    },
  });
}

export function useGetHomepageContent(isAuth, lang) {
  const protectedAxios = useAxios();
  const API = isAuth ? `customer/v2/homepage?platform=customer-web` : `customer/v2/homepage?platform=customer-web&language=${lang}`;
  const prompt = useContext(promptContext);
  // console.debug({ lang });
  return useQuery({
    queryKey: [API, lang],
    enabled: !!lang,
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`${API}`);
        return data;
      } catch (error) {
        console.debug('ERROR', error);
        prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Error while adding' } });
      }
    },
  });
}

export function useGetAllServiceTypeV2(tractor_model_id) {
  const API = 'service_type/v2/user/getall';
  const protectedAxios = useAxios();
  const { setToken } = useAuthContext();
  const prompt = useContext(promptContext);
  const { getData } = useAuthContext();
  const { lang } = getData();
  return useQuery({
    queryKey: [lang, API, tractor_model_id],
    queryFn: async () => {
      try {
        if (tractor_model_id) {
          const params = {
            tractor_model_id: tractor_model_id,
          };
          const { data } = await protectedAxios.get(`${API}`, { params });
          return data;
        } else {
          const { data } = await protectedAxios.get(`${API}`);
          return data;
        }
      } catch (error) {
        if (error.response.status === 401) {
          setToken('clearToken');
          prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Token Expired' } });
        }
        return;
      }
    },
  });
}
export function useGetLocation() {
  const API = 'getData/location';
  const protectedAxios = useAxios();
  const { setToken } = useAuthContext();
  const prompt = useContext(promptContext);
  const { getData } = useAuthContext();
  const { lang, token } = getData();
  return useQuery({
    queryKey: [API, lang, token],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`${API}`);
        return data;
      } catch (error) {
        if (error.response.status === 401) {
          setToken('clearToken');
          prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Token Expired' } });
        }
        return;
      }
    },
  });
}
export function useGetWorkshopLocation(location_id) {
  const API = 'getData/workshops_by_location';
  const protectedAxios = useAxios();
  const { setToken } = useAuthContext();
  const { getData } = useAuthContext();
  const { lang } = getData();
  const prompt = useContext(promptContext);
  const parsedLocationId = parseInt(location_id);
  return useQuery({
    enabled: !isNaN(parsedLocationId) && parsedLocationId !== 0,
    queryKey: [API, parsedLocationId, lang],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`${API}/${parsedLocationId}`);
        return data;
      } catch (error) {
        if (error.response.status === 401) {
          setToken('clearToken');
          prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Token Expired' } });
        }
        return;
      }
    },
  });
}

export function useUpdateWorkshopLocation() {
  const protectedAxios = useAxios();
  const { setToken } = useAuthContext();
  const prompt = useContext(promptContext);

  return useMutation({
    mutationFn: async (location_id) => {
      try {
        const { data } = await protectedAxios.get(`getData/workshops_by_location/${location_id}`, location_id);
        return data;
      } catch (error) {
        if (error.response.status === 401) {
          setToken('clearToken');
          prompt({ type: promptActions.SHOW_PROMPT, payload: { message: error.response.data?.message || 'Token Expired' } });
        }
        throw error; // Re-throw the error to be handled by the mutation
      }
    },
    // onSuccess: (res) => {
    //   // Optionally, you can invalidate queries or update local state here
    //   console.debug('resharshit', res);
    // },
  });
}
