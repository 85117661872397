import { Button, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useMediaQuery } from '@mui/material';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { LazyImage } from '../../../assets/image/lazyImage';
import IconEmptyCart from '../../../assets/icons/icon-common/icon-empty-cart';
import { useLanguage } from '../../../language/useLanguage';
import { Counter, QuantityEditCounter } from './counter';
import dayjs from 'dayjs';
import IconCrossCart from '../../../assets/icons/icon-common/icon-cross-cart-new';
import { IconCalendar } from '../../../assets/icons/icon-common/icon-cart-calendar-new';
import { IconWatch } from '../../../assets/icons/icon-common/icon-watch-new';
import { formatAmount } from '../../../config/common-function/calculation';
import { getFormData } from '../../../assets/lib/form/form-data';
import { useDeleteCartItem, useUpdateCartItem } from './cart-api';
import { useNavigate } from 'react-router-dom';
import { setCartItems, setCartState } from '../../../redux/features/cart/cartSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setEditServicePopId } from '../../../redux/features/cart/cartSlice';
import BookAppointmentModel from '../../customer-order/book-appointment-model/book-appointment-model';
import {
  setChange,
  setChassisNo,
  setCustomerTractorId,
  setIsEditAppointmentDrawerOpen,
  setIsEditAppointmentModelOpen,
} from '../../../redux/features/customer-order/customerOrderSlice';
import { useAuthContext } from '../../../context/authContext';
import { useGetCustomerTractor } from '../../customer-order/tractor-workshop-selection/tractor-workshop-api';
import BookAppointmentDrawerMobile from '../../customer-order/book-appointment-model/book-appointment-drawer-mobile';

export default function Cart({ data, onClose }) {
  const langData = useLanguage();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editServicePopId = useSelector((state) => state.cart.editServicePopId);
  const cartItems = useMemo(() => data?.CartItems ?? [], [data]);
  const isPastService =
    data?.CartItems?.reduce((acc, cartItem) => {
      if (acc) return acc;
      // Check if cart item is service
      if (cartItem.service_id) {
        const currentDate = dayjs();
        // if yes then is it in past? then return true
        const isInvalid = isInvalidService(cartItem, currentDate);
        if (isInvalid) {
          return true;
        }
      }
      return acc;
    }, false) ?? false;

  const { getData } = useAuthContext();
  const { isAuth } = getData();
  const customerTractors = useGetCustomerTractor();
  const isEditAppointmentModelOpen = useSelector((state) => state.customer_order.isEditAppointmentModelOpen);
  const isEditAppointmentDrawerOpen = useSelector((state) => state.customer_order.isEditAppointmentDrawerOpen);
  const customerTractorId = useSelector((state) => state.customer_order.customerTractorId);
  const chassisNO = useSelector((state) => state.customer_order.chassisNO);
  const [isSlotNotAvailable, setIsSlotNotAvailable] = useState(false);

  const availabilityMessage = (currentItem, cartItems) => {
    // Check if other_details is null or undefined
    if (!currentItem?.other_details) {
      return false;
    }
    const { team_count, is_available, date, time } = currentItem?.other_details ?? {};
    // Calculate the total count of items with the same date and time
    const sameDateTimeCount =
      cartItems?.reduce((total, item) => {
        const { date: itemDate, time: itemTime, count: itemCount } = item?.other_details ?? {};
        if (itemDate === date && itemTime === time) {
          return total + itemCount;
        }
        return total;
      }, 0) ?? 0;

    // Calculate available slots
    const available_slots = team_count - sameDateTimeCount;

    if (!is_available || available_slots <= 0) {
      return 'No slots available. Please change time.';
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (cartItems.length > 0) {
      // Check if any item has no available slots or is not available
      const hasUnavailableSlots = cartItems.some((item) => {
        const message = availabilityMessage(item, cartItems);
        return message !== false; // If message is not false, slots are not available
      });

      // Set the button's disabled state based on availability
      setIsSlotNotAvailable(hasUnavailableSlots);
    }
  }, [cartItems]);

  const isMobile = useMediaQuery('(max-width:600px)');
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    dispatch(setIsEditAppointmentDrawerOpen(open));
  };
  useEffect(() => {
    dispatch(setChassisNo(editServicePopId?.CustomerTractor?.reg_no ?? ''));
    if (isEditAppointmentModelOpen || isEditAppointmentDrawerOpen) {
      dispatch(setCustomerTractorId(editServicePopId?.CustomerTractor.id ?? null));
    }
  }, [editServicePopId, dispatch, isEditAppointmentModelOpen, isEditAppointmentDrawerOpen]);
  const cartState = useSelector((state) => state.cart.cartState);

  const summaryRedirect = () => {
    if (window.innerWidth > 600) {
      const anchor = 'right';
      dispatch(setCartState({ ...cartState, [anchor]: false }));
      navigate('/order-summary', { state: 'manual-redirect' });
    } else {
      const anchor = 'right';
      dispatch(setCartState({ ...cartState, [anchor]: false }));
      navigate('/order-summary', { state: 'manual-redirect' });
    }
  };
  const WorkshopDirection = useSelector((state) => state.location.WorkshopDirection);

  //  for temprory solution for the cart destroye when user remove items  manually
  // once api changes come then below use effect will be removed
  useEffect(() => {
    if (cartItems.length === 0) {
      localStorage.setItem('location_change', true);
      dispatch(setCartItems(null));
    }
  }, [cartItems, dispatch]);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', paddingBlock: '24px 0px', paddingInline: 12 }}>
        <p
          style={{
            color: 'var(--clr-primary)',
            fontSize: 18,
            fontWeight: 500,
          }}
          className="heading"
        >
          {langData('Cart')} ({data?.cart_items_count ?? 0}) {langData('Items')}
        </p>
        <div onClick={onClose} style={{ cursor: 'pointer', paddingRight: window.innerWidth <= 768 ? '0px' : '20px' }}>
          <IconCrossCart />
        </div>
      </div>
      <CartWrapper>
        {cartItems?.length > 0 ? (
          <>
            <CartDetails>
              <CustomTableContainer
                sx={{
                  maxHeight: window.innerWidth > 600 ? 'calc(100vh - 230px)' : 'calc(60vh - (-50px))',
                }}
              >
                {window.innerWidth >= 600 ? (
                  <Table
                    style={{
                      borderTop: '1px solid rgba(224, 224, 224, 1)',
                      paddingLeft: '0px',
                    }}
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead
                      style={{
                        borderTop: '1px solid rgba(224, 224, 224, 1)',
                        padding: '0px',
                      }}
                    >
                      <TableRow>
                        <TableCell style={{ padding: '8px 8px 8px 0px' }}>{langData('Item(s)')}</TableCell>
                        <TableCell style={{ padding: '8px 8px 8px 0px' }}>{langData('Quantity')}</TableCell>
                        <TableCell style={{ padding: '8px 8px 8px 0px' }}>{langData('Price')}</TableCell>
                        <TableCell style={{ padding: '8px 8px 8px 0px' }}></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {cartItems.map((cartItem, index) =>
                        cartItem.service_id ? (
                          <Service key={index} cartItem={cartItem} isPastService={isPastService} cartItems={cartItems} availabilityMessage={availabilityMessage} />
                        ) : cartItem.Item?.ItemCategory ? (
                          <Items key={index} item={cartItem} customer_id={data?.customer_id} />
                        ) : (
                          <Kit key={index} kit={cartItem} customer_id={data?.customer_id} />
                        ),
                      )}
                    </TableBody>
                  </Table>
                ) : (
                  <Table>
                    <TableBody>
                      {cartItems.map(
                        (cartItem, index) =>
                          cartItem.Service && <ServiceResponsive key={index} cartItem={cartItem} cartItems={cartItems} availabilityMessage={availabilityMessage} />,
                      )}
                      {cartItems.map((kit, index) => kit.Item?.item_categories_id === null && <KitResponsive key={index} kit={kit} customer_id={data?.customer_id} />)}
                      {cartItems.map((item, index) => item.Item?.item_categories_id && <ItemsResponsive key={index} item={item} customer_id={data?.customer_id} />)}
                    </TableBody>
                  </Table>
                )}
              </CustomTableContainer>
            </CartDetails>
            <BillingSummary>
              <div>
                <p>{langData('Total')}</p>
                <p>₹ {formatAmount(data.sub_total)}</p>
              </div>
              <div>
                <p>{langData('Discount')}</p>
                <p style={{ color: '#108D0D' }}>₹ {formatAmount(data.discount)}</p>
              </div>
              {/* this code is for future Prefrence to show the total before tax */}
              {/* <div style={{ borderTop: '1px solid grey' }}></div>
              <div>
                <p>{langData('Total Before Tax')}</p>
                <p>₹ {formatAmount(data.total_before_tax)}</p>
              </div> */}
              <div>
                <p>
                  {langData('Tax')} <strong style={{ fontWeight: 500, color: '#EC2726' }}>(18%)</strong>
                </p>
                <p>₹ {formatAmount(data.tax)}</p>
              </div>
              <div>
                <h4>{langData('Grand Total')}</h4>
                <h4>₹ {formatAmount(data.total)}</h4>
              </div>
              <Button variant="contained" onClick={() => summaryRedirect()} disabled={isPastService || isSlotNotAvailable}>
                {langData('Continue')}
              </Button>
            </BillingSummary>
          </>
        ) : (
          <>
            <EmptyCart className={`${cartItems?.length === 0 ? 'empty_cart' : ''}`}>
              <IconEmptyCart />
              <p>Your Cart Is Currently Empty!</p>
              <p>Looks like you have not added anything in the cart.</p>
            </EmptyCart>
          </>
        )}
      </CartWrapper>
      {isMobile
        ? editServicePopId &&
          isAuth &&
          isEditAppointmentDrawerOpen && (
            <BookAppointmentDrawerMobile
              customerTractors={customerTractors}
              toggleDrawer={toggleDrawer}
              isForNavigation={{
                brandName: editServicePopId?.CustomerTractor.TractorModel.TractorBrand?.en_label,
                modelName: editServicePopId?.CustomerTractor?.TractorModel?.en_label,
              }}
              chassisNO={chassisNO}
              tractorModelName={editServicePopId?.CustomerTractor?.TractorModel?.model_name}
              tractorBrandName={editServicePopId?.CustomerTractor.TractorModel.TractorBrand?.brand_name}
              selectedService={editServicePopId.service_name}
              selectServicePriceOffer={editServicePopId.Service.offer_total}
              selectServicePriceTotal={editServicePopId.Service.mrp_total}
              workshopName={data.Workshop.workshop_name}
              WorkshopDirection={WorkshopDirection}
              workshopAddress1={data.Workshop.SubDistrict.subdistrict_name}
              workshopAddress2={data.Workshop.SubDistrict.District.district_name}
              tractorModelId={editServicePopId.CustomerTractor.TractorModel.id}
              tractorBrandId={editServicePopId.CustomerTractor.TractorModel.TractorBrand.id}
              service_id={editServicePopId.service_id}
              modelOpen={isEditAppointmentDrawerOpen}
              otherDetails={editServicePopId.other_details}
              customerTractorId={customerTractorId}
              editCustomerTractorId={editServicePopId?.CustomerTractor.id ?? null}
              cartItemId={editServicePopId?.id ?? null}
              workshopIdUser={data.workshop_id}
              setChange={setChange}
              isPastService={isPastService}
            />
          )
        : editServicePopId &&
          isAuth &&
          isEditAppointmentModelOpen && (
            <BookAppointmentModel
              customerTractors={customerTractors}
              isForNavigation={{
                brandName: editServicePopId?.CustomerTractor.TractorModel.TractorBrand?.en_label,
                modelName: editServicePopId?.CustomerTractor?.TractorModel?.en_label,
              }}
              chassisNO={chassisNO}
              tractorModelName={editServicePopId?.CustomerTractor?.TractorModel?.model_name}
              tractorBrandName={editServicePopId?.CustomerTractor.TractorModel.TractorBrand?.brand_name}
              selectedService={editServicePopId.service_name}
              selectServicePriceOffer={editServicePopId.Service.offer_total}
              selectServicePriceTotal={editServicePopId.Service.mrp_total}
              workshopName={data.Workshop.workshop_name}
              WorkshopDirection={WorkshopDirection}
              workshopAddress1={data.Workshop.SubDistrict.subdistrict_name}
              workshopAddress2={data.Workshop.SubDistrict.District.district_name}
              tractorModelId={editServicePopId.CustomerTractor.TractorModel.id}
              tractorBrandId={editServicePopId.CustomerTractor.TractorModel.TractorBrand.id}
              service_id={editServicePopId.service_id}
              modelOpen={isEditAppointmentModelOpen}
              otherDetails={editServicePopId.other_details}
              customerTractorId={customerTractorId}
              editCustomerTractorId={editServicePopId?.CustomerTractor.id ?? null}
              cartItemId={editServicePopId?.id ?? null}
              workshopIdUser={data.workshop_id}
              setChange={setChange}
              isPastService={isPastService}
            />
          )}
    </>
  );
}

const CartWrapper = styled('div')({
  width: 'min(100vw,600px)',
  paddingBlock: '24px 0px',
  paddingInline: 12,
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  ':has(.empty_cart)': {
    justifyContent: 'center',
  },
  '@media only screen and (max-width: 600px)': {
    height: '92vh',
  },
});

const EmptyCart = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
  alignItems: 'center',
  justifyContent: 'center',
});

const CartDetails = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  p: { color: 'var(--clr-primary)', fontSize: 18, fontWeight: 500 },
});

const BillingSummary = styled('div')({
  backgroundColor: '#f2f7ff',
  padding: '1rem',
  marginInline: '-10px',
  display: 'flex',
  flexDirection: 'column',
  marginTop: 'auto',
  gap: '0.3rem',
  div: {
    display: 'flex',
    justifyContent: 'space-between',
    p: {
      color: 'var(--clr-primary)',
      fontWeight: 500,
    },
  },
});

const CustomeTableCellService = styled(TableCell)({
  display: 'flex',
  gap: 12,
  position: 'relative',
  paddingLeft: 0,
  borderBottom: 0,
  div: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    p: {
      fontSize: 14,
    },
    div: {
      p: {
        fontSize: 16,
        fontWeight: 600,
      },
    },
  },
});

const CustomeTableCellServiceResponsive = styled(TableCell)({
  paddingLeft: 0,
  borderBottom: 0,
  div: {
    div: {
      div: {
        p: {
          fontSize: 16,
          fontWeight: 600,
        },
      },
      p: {
        fontSize: 14,
        fontWeight: 600,
      },
    },
  },
});

const CustomeTableCellQty = styled(TableCell)({
  verticalAlign: 'top',
  borderBottom: 0,
  paddingLeft: 0,
});

const CustomeTableCellPrice = styled(TableCell)({
  verticalAlign: 'top',
  color: 'var(--clr-primary)',
  fontWeight: 600,
  fontSize: 16,
  borderBottom: 0,
  paddingLeft: 0,
});

const Service = ({ cartItem, cartItems, availabilityMessage }) => {
  const deleteCartItem = useDeleteCartItem(cartItem.id);
  const dispatch = useDispatch();
  const langData = useLanguage();
  if (!cartItem.Service) return null;
  const issues = cartItem.other_details.issues ? JSON.parse(cartItem.other_details.issues) : {};
  // const issuesWithTractor = cartItem?.other_details.issues;
  // const issuesWithTractorParsed = JSON.parse(issuesWithTractor);
  const currentDate = dayjs();
  const isInvalid = isInvalidService(cartItem, currentDate);

  return (
    <Fragment>
      <TableRow className="service-cell">
        <CustomeTableCellService rowSpan={6}>
          <LazyImage name={cartItem.service_type_img} height="2rem" width="2rem" />
          <div>
            <div>
              <p>{cartItem.service_name}</p>
            </div>
            <p>{cartItem.tractor_name}</p>
            <p style={{ color: '#6D7D93' }}>
              {langData('Chassis No')} : {cartItem.CustomerTractor?.reg_no}
            </p>
          </div>
        </CustomeTableCellService>
        <CustomeTableCellQty>
          <Counter count={cartItem.quantity} disabled />
        </CustomeTableCellQty>
        <CustomeTableCellPrice>
          ₹ {cartItem.Service.offer_total}
          <p
            style={{
              fontSize: 14,
              fontWeight: 500,
              color: 'var(--clr-gray)',
            }}
          >
            <del>₹ {cartItem.Service.mrp_total}</del>
          </p>
        </CustomeTableCellPrice>

        <CustomeTableCellQty onClick={() => deleteCartItem.mutate()} style={{ cursor: 'pointer' }}>
          <IconCrossCart height="0.75rem" width="0.75rem" />
        </CustomeTableCellQty>
      </TableRow>
      <TableRow className="service-meta">
        <TableCell colSpan={4} style={{ padding: 0, paddingBottom: 8 }}>
          <div
            style={{
              fontSize: 14,
              color: '#6D7D93',
              marginTop: '-0.5rem',
            }}
          >
            <div
              style={{
                display: 'flex',
                gap: 16,
                marginBottom: 4,
                alignItems: 'center',
                border: isInvalid ? '2px solid #ec2726c7' : 'none',
                borderRadius: '3px',
                paddingLeft: '7px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  gap: 6,
                  alignItems: 'center',
                }}
              >
                <IconCalendar
                  style={{
                    height: '0.8rem',
                    width: '0.8rem',
                  }}
                />
                <p
                  style={{
                    fontSize: 12,
                    color: 'var(--clr-primary)',
                    fontWeight: 600,
                  }}
                >
                  {dayjs(cartItem.other_details.date).format('MMM D, YYYY')}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  gap: 6,
                  alignItems: 'center',
                }}
              >
                <IconWatch
                  style={{
                    height: '0.8rem',
                    width: '0.8rem',
                  }}
                />
                <p
                  style={{
                    fontSize: 12,
                    color: 'var(--clr-primary)',
                    fontWeight: 600,
                  }}
                >
                  {cartItem.other_details.time}
                </p>
              </div>
              <EditText
                onClick={() => {
                  dispatch(setEditServicePopId(cartItem));
                  dispatch(setIsEditAppointmentModelOpen(true));
                }}
              >
                {langData('Edit')}
              </EditText>
            </div>
            {(Object.keys(issues).length > 0 || cartItem.other_details?.notes) && ( // Check if issuesWithTractorParsed is not empty
              <IssuesAndNotes>
                {Object.keys(issues).length > 0 && (
                  <p>
                    {langData('Issue')} :{' '}
                    {Object.keys(issues)
                      .filter((key) => key !== 'other')
                      .map((key) => langData(key.charAt(0).toUpperCase() + key.slice(1)))
                      .join(', ')}
                    {issues['other'] && Object.keys(issues).length > 1 ? ', ' : ''}
                    {issues['other']
                      ? `${langData('Other')} - ${issues['other']
                          .split(',')
                          .map((issue) => issue.trim().charAt(0).toUpperCase() + issue.trim().slice(1))
                          .join(', ')}`
                      : ''}
                  </p>
                )}
                {cartItem.other_details?.notes && (
                  <p>
                    {langData('Notes')} : {cartItem.other_details.notes}
                  </p>
                )}
              </IssuesAndNotes>
            )}
          </div>
          <div>
            <p
              style={{
                fontSize: 11,
                color: 'rgb(236 37 36 / 78%)',
                fontWeight: 800,
              }}
            >
              {availabilityMessage(cartItem, cartItems)}
            </p>
          </div>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

const ServiceResponsive = ({ cartItem, onIncrease, onDecrease, cartItems, availabilityMessage }) => {
  const deleteCartItem = useDeleteCartItem(cartItem.id);
  const dispatch = useDispatch();
  const issues = cartItem.other_details.issues ? JSON.parse(cartItem.other_details.issues) : {};
  if (!cartItem.Service) return null;
  const currentDate = dayjs();
  const isInvalid = isInvalidService(cartItem, currentDate);

  return (
    <Fragment>
      <TableRow className="service-cell">
        <CustomeTableCellServiceResponsive colSpan={4}>
          <div style={{ display: 'flex', gap: 16 }}>
            <LazyImage name={cartItem.service_type_img} height="2rem" width="2rem" />
            <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
              <div>
                <p>{cartItem.service_name}</p>
                <p>{cartItem.tractor_name}</p>
                <p style={{ color: '#6D7D93', fontWeight: 500 }}>Chassis No: {cartItem.CustomerTractor?.reg_no}</p>
              </div>
              <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                <Counter count={cartItem.quantity} onIncrease={() => onIncrease(cartItem)} onDecrease={() => onDecrease(cartItem)} disabled />
                <p style={{ fontSize: 14, fontWeight: 600 }}>₹ {cartItem.Service.offer_total}</p>
                <p
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                    color: 'var(--clr-gray)',
                  }}
                >
                  <del>₹ {cartItem.Service.mrp_total}</del>
                </p>
              </div>
            </div>
          </div>
        </CustomeTableCellServiceResponsive>
        <CustomeTableCellQty onClick={() => deleteCartItem.mutate()} style={{ cursor: 'pointer' }}>
          <IconCrossCart height="0.75rem" width="0.75rem" />
        </CustomeTableCellQty>
      </TableRow>
      <TableRow className="service-meta">
        <TableCell colSpan={5} style={{ padding: 0, paddingBottom: 8 }}>
          <div
            style={{
              fontSize: 14,
              color: '#6D7D93',
            }}
          >
            <div
              style={{
                border: isInvalid ? '2px solid #ec2726c7' : 'none',
                display: 'flex',
                gap: 16,
                marginBottom: 4,
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  gap: 6,
                  alignItems: 'center',
                }}
              >
                <IconCalendar
                  style={{
                    height: '0.8rem',
                    width: '0.8rem',
                  }}
                />
                <p
                  style={{
                    fontSize: 12,
                    color: 'var(--clr-primary)',
                    fontWeight: 600,
                  }}
                >
                  {dayjs(cartItem.other_details.date).format('MMM D, YYYY')}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  gap: 6,
                  alignItems: 'center',
                }}
              >
                <IconWatch
                  style={{
                    height: '0.8rem',
                    width: '0.8rem',
                  }}
                />
                <p
                  style={{
                    fontSize: 12,
                    color: 'var(--clr-primary)',
                    fontWeight: 600,
                  }}
                >
                  {cartItem.other_details.time}
                </p>
              </div>
              <EditText
                onClick={() => {
                  dispatch(setEditServicePopId(cartItem));
                  dispatch(setIsEditAppointmentModelOpen(true));
                  dispatch(setIsEditAppointmentDrawerOpen(true));
                }}
              >
                Edit
              </EditText>
            </div>
            {(Object.keys(issues).length > 0 || cartItem.other_details?.notes) && ( // Check if issuesWithTractorParsed is not empty
              <IssuesAndNotes>
                {Object.keys(issues).length > 0 && (
                  <p>
                    Issue:
                    {Object.keys(issues)
                      .filter((key) => key !== 'other')
                      .map((key) => key.charAt(0).toUpperCase() + key.slice(1))
                      .join(', ')}
                    {issues['other'] && Object.keys(issues).length > 1 ? ', ' : ''}
                    {issues['other']
                      ? `Other - ${issues['other']
                          .split(',')
                          .map((issue) => issue.trim().charAt(0).toUpperCase() + issue.trim().slice(1))
                          .join(', ')}`
                      : ''}
                  </p>
                )}
                {cartItem.other_details?.notes && <p>Notes: {cartItem.other_details.notes}</p>}
              </IssuesAndNotes>
            )}
          </div>
          <div>
            <p
              style={{
                fontSize: 11,
                color: 'rgb(236 37 36 / 78%)',
                fontWeight: 800,
              }}
            >
              {availabilityMessage(cartItem, cartItems)}
            </p>
          </div>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

const EditText = styled('div')({
  fontWeight: 600,
  color: '#EC2726',
  textDecoration: 'underline',
  cursor: 'pointer',
  fontSize: 12,
});

const IssuesAndNotes = styled('div')({
  display: 'flex',
  marginBottom: 6,
  background: '#F2F7FF',
  padding: 6,
  flexDirection: 'column',
  borderRadius: 8,
  p: {
    fontSize: '12px !important',
    color: '#6D7D93',
  },
  ' @media only screen and (max-width: 600px)': {
    p: {
      fontSize: 12,
      color: '#6D7D93',
    },
  },
});

const CustomeTableCellItem = styled(TableCell)({
  display: 'flex',
  gap: 12,
  padding: '18px',
  paddingLeft: 0,
  div: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
});

const CustomeTableCellItemQty = styled(TableCell)({
  verticalAlign: 'top',
  paddingLeft: 0,
});

const CustomeTableCellItemPrice = styled(TableCell)({
  verticalAlign: 'top',
  color: 'var(--clr-primary)',
  fontWeight: 600,
  fontSize: 16,
  paddingLeft: 0,
});

const Kit = ({ kit, customer_id }) => {
  const [countVal, setCountVal] = useState(kit.quantity);
  const [isFocused, setIsFocused] = useState(null);
  const updateCartItem = useUpdateCartItem(kit.id, customer_id);
  const deleteCartItem = useDeleteCartItem(kit.id);

  function onIncrease() {
    const formData = getFormData({
      item_id: kit.id,
      quantity: kit.quantity + 1,
    });
    updateCartItem.mutate(formData);
  }

  function onDecrease() {
    if (kit.quantity === 1) return;

    const formData = getFormData({
      item_id: kit.id,
      quantity: kit.quantity - 1,
    });
    updateCartItem.mutate(formData);
  }

  useEffect(() => {
    if (isFocused === false && countVal && countVal >= 1 && countVal !== kit.quantity) {
      const formData = getFormData({ item_id: kit.id, quantity: countVal });
      updateCartItem.mutate(formData, {
        onError: () => {
          setCountVal(kit.quantity);
        },
      });
    }
    if (countVal === '0') {
      setCountVal(kit.quantity);
    }
  }, [countVal, isFocused]);

  useEffect(() => {
    setCountVal(kit.quantity);
  }, [kit.quantity]);

  if (kit.Item?.item_categories_id !== null) return null;

  return (
    <Fragment>
      <TableRow>
        <CustomeTableCellItem>
          <LazyImage name={kit.item_img} height="2.5rem" width="2.5rem" />
          <div>
            <p style={{ fontSize: 16, fontWeight: 600 }}>{kit.Item.item_name}</p>
            {/* <p style={{ fontSize: 14, color: '#6D7D93', fontWeight: 500 }}>Chassis No: {kit.CustomerTractor?.reg_no}</p> */}
          </div>
        </CustomeTableCellItem>
        <CustomeTableCellItemQty>
          <QuantityEditCounter
            isFocused={isFocused}
            setIsFocused={setIsFocused}
            valueOfItem={kit.quantity}
            countVal={countVal}
            setCountVal={setCountVal}
            onIncrease={onIncrease}
            onDecrease={onDecrease}
          />
        </CustomeTableCellItemQty>
        <CustomeTableCellItemPrice>
          <div>
            <p style={{ fontSize: '1rem', fontWeight: 600 }}>₹ {kit.Item.offer_total}</p>
            <p style={{ fontSize: '0.9rem', fontWeight: 500 }}>
              <del style={{ color: 'var(--clr-gray)' }}>₹ {kit.Item.mrp_total}</del>
            </p>
          </div>
        </CustomeTableCellItemPrice>
        <CustomeTableCellItemPrice onClick={() => deleteCartItem.mutate()} style={{ cursor: 'pointer' }}>
          <IconCrossCart height="0.75rem" width="0.75rem" />
        </CustomeTableCellItemPrice>
      </TableRow>
    </Fragment>
  );
};

const CustomeTableCellItemResponsive = styled(TableCell)({
  paddingLeft: 0,
  '> div': {
    display: 'flex',
    gap: '16px',
  },
});

const KitResponsive = ({ kit, customer_id }) => {
  const [countVal, setCountVal] = useState(kit.quantity);
  const [isFocused, setIsFocused] = useState(null);

  const updateCartItem = useUpdateCartItem(kit.id, customer_id);
  const deleteCartItem = useDeleteCartItem(kit.id);

  function onIncrease(kit) {
    const formData = getFormData({
      item_id: kit.id,
      quantity: kit.quantity + 1,
    });
    updateCartItem.mutate(formData);
  }

  function onDecrease(kit) {
    if (kit.quantity === 1) return;

    const formData = getFormData({
      item_id: kit.id,
      quantity: kit.quantity - 1,
    });
    updateCartItem.mutate(formData);
  }

  useEffect(() => {
    if (isFocused === false && countVal && countVal > 1 && countVal !== kit.quantity) {
      const formData = getFormData({ item_id: kit.id, quantity: countVal });
      updateCartItem.mutate(formData);
    }
  }, [countVal, isFocused]);

  useEffect(() => {
    setCountVal(kit.quantity);
  }, [kit.quantity]);
  if (kit.Item?.item_categories_id !== null) return null;
  return (
    <Fragment>
      <TableRow>
        <CustomeTableCellItemResponsive colSpan={4}>
          <div>
            <LazyImage name={kit.item_img} height="3rem" width="3rem" />
            <div style={{ display: 'flex', flexDirection: 'column', gap: 14 }}>
              <p style={{ fontSize: 16, fontWeight: 600 }}>
                {/* {kit.Item.ServiceType.service_type_name} */}
                {/* <p style={{ fontSize: 14, color: '#6D7D93' }}> */}
                {kit.Item.item_name}
                {/* </p> */}
              </p>
              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                <QuantityEditCounter
                  isFocused={isFocused}
                  setIsFocused={setIsFocused}
                  valueOfItem={kit.quantity}
                  countVal={countVal}
                  setCountVal={setCountVal}
                  onIncrease={() => onIncrease(kit)}
                  onDecrease={() => onDecrease(kit)}
                />
                <p style={{ fontSize: 14, fontWeight: 600 }}>₹ {kit.Item.offer_total}</p>
                <p
                  style={{
                    fontSize: 14,
                    fontWeight: 600,
                    color: 'var(--clr-gray)',
                  }}
                >
                  <del>₹ {kit.Item.mrp_total}</del>
                </p>
              </div>
            </div>
          </div>
        </CustomeTableCellItemResponsive>
        <TableCell
          sx={{
            paddingRight: 0,
            paddingLeft: 0,
            verticalAlign: 'top',
            cursor: 'pointer',
          }}
          onClick={() => deleteCartItem.mutate()}
        >
          <IconCrossCart height="0.8rem" width="0.8rem" />
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

const Items = ({ item, customer_id }) => {
  const [countVal, setCountVal] = useState(item.quantity);
  const [isFocused, setIsFocused] = useState(null);
  const updateCartItem = useUpdateCartItem(item.id, customer_id);
  const deleteCartItem = useDeleteCartItem(item.id);

  function onIncrease(item) {
    const formData = getFormData({
      item_id: item.id,
      quantity: item.quantity + 1,
    });
    updateCartItem.mutate(formData);
  }
  function onDecrease(item) {
    if (item.quantity === 1) return;

    const formData = getFormData({
      item_id: item.id,
      quantity: item.quantity - 1,
    });
    updateCartItem.mutate(formData);
  }

  useEffect(() => {
    if (isFocused === false && countVal && countVal >= 1 && countVal !== item.quantity) {
      const formData = getFormData({ item_id: item.id, quantity: countVal });
      updateCartItem.mutate(formData, {
        onError: () => {
          setCountVal(item.quantity);
        },
      });
    }
    if (countVal === '0') {
      setCountVal(item.quantity);
    }
  }, [countVal, isFocused]);

  useEffect(() => {
    setCountVal(item.quantity);
  }, [item.quantity]);

  return (
    <Fragment>
      <TableRow>
        <CustomeTableCellItem>
          <LazyImage name={item.item_img} height="2.5rem" width="2.5rem" />
          <div>
            <p style={{ fontSize: 16, fontWeight: 600 }}>{item.Item.item_name}</p>
            <p style={{ fontSize: 14 }}>
              {item.CustomerTractor?.TractorModel?.TractorBrand.brand_name} {item.CustomerTractor?.TractorModel?.model_name}
            </p>
            <p style={{ fontSize: 14, color: '#6D7D93', fontWeight: 500 }}>
              {item?.TractorModel?.TractorBrand?.brand_name ?? ''}&nbsp;
              {item?.TractorModel?.model_name ?? ''}
            </p>
          </div>
        </CustomeTableCellItem>
        <CustomeTableCellItemQty>
          <QuantityEditCounter
            isFocused={isFocused}
            valueOfItem={item.quantity}
            onIncrease={() => onIncrease(item)}
            onDecrease={() => onDecrease(item)}
            setIsFocused={setIsFocused}
            countVal={countVal}
            setCountVal={setCountVal}
          />
        </CustomeTableCellItemQty>
        <CustomeTableCellItemPrice>
          <div>
            <p
              style={{
                fontSize: '1rem',
                fontWeight: 600,
              }}
            >
              ₹ {item.Item.offer_total}
            </p>
            <p
              style={{
                fontSize: '0.9rem',
                fontWeight: 500,
              }}
            >
              <del style={{ color: 'var(--clr-gray)' }}>₹ {item.Item.mrp_total}</del>
            </p>
          </div>
        </CustomeTableCellItemPrice>
        <CustomeTableCellItemPrice onClick={() => deleteCartItem.mutate()} style={{ cursor: 'pointer' }}>
          <IconCrossCart height="0.75rem" width="0.75rem" />
        </CustomeTableCellItemPrice>
      </TableRow>
    </Fragment>
  );
};

const ItemsResponsive = ({ item, customer_id }) => {
  const [countVal, setCountVal] = useState(item.quantity);
  const [isFocused, setIsFocused] = useState(null);

  const updateCartItem = useUpdateCartItem(item.id, customer_id);
  const deleteCartItem = useDeleteCartItem(item.id);

  const onIncrease = (item) => {
    const formData = getFormData({
      item_id: item.id,
      quantity: item.quantity + 1,
    });
    updateCartItem.mutate(formData);
  };

  function onDecrease(item) {
    if (item.quantity === 1) return;

    const formData = getFormData({
      item_id: item.id,
      quantity: item.quantity - 1,
    });
    updateCartItem.mutate(formData);
  }

  useEffect(() => {
    if (isFocused === false && countVal && countVal > 1 && countVal !== item.quantity) {
      const formData = getFormData({ item_id: item.id, quantity: countVal });
      updateCartItem.mutate(formData);
    }
  }, [countVal, isFocused]);

  useEffect(() => {
    setCountVal(item.quantity);
  }, [item.quantity]);

  return (
    <Fragment>
      <TableRow>
        <CustomeTableCellItemResponsive colSpan={4}>
          <div>
            <LazyImage name={item.item_img} height="3rem" width="3rem" />
            <div style={{ display: 'flex', flexDirection: 'column', gap: 14 }}>
              <p style={{ fontSize: 16, fontWeight: 600 }}>
                {item.Item.item_name}
                <p style={{ fontSize: 14, color: '#6D7D93', fontWeight: 500 }}>
                  {item?.TractorModel?.TractorBrand?.brand_name ?? ''}&nbsp;
                  {item?.TractorModel?.model_name ?? ''}
                </p>
              </p>
              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                <QuantityEditCounter
                  isFocused={isFocused}
                  valueOfItem={item.quantity}
                  onIncrease={() => onIncrease(item)}
                  onDecrease={() => onDecrease(item)}
                  setIsFocused={setIsFocused}
                  countVal={countVal}
                  setCountVal={setCountVal}
                />
                <p style={{ fontSize: 14, fontWeight: 600 }}>₹ {item.Item.mrp_total}</p>
                <p
                  style={{
                    fontSize: 14,
                    fontWeight: 600,
                    color: 'var(--clr-gray)',
                  }}
                >
                  <del>₹ {item.Item.mrp_total}</del>
                </p>
              </div>
            </div>
          </div>
        </CustomeTableCellItemResponsive>
        <TableCell
          sx={{
            paddingRight: 0,
            paddingLeft: 0,
            verticalAlign: 'top',
            cursor: 'pointer',
          }}
          onClick={() => deleteCartItem.mutate()}
        >
          <IconCrossCart height="0.8rem" width="0.8rem" />
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

const CustomTableContainer = styled(TableContainer)({
  maxHeight: window.innerWidth > 600 ? 'calc(100vh - 230px)' : 'calc(60vh - (-50px))',
  '&::-webkit-scrollbar': {
    width: '4px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f5f5f5',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'var(--clr-secondary)',
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: 'var(--clr-secondary)',
  },
});

function isInvalidService(cartItem, currentDate) {
  if (cartItem.other_details?.date && cartItem.other_details?.time) {
    const [startTime, endTime] = cartItem.other_details.time.split(' - ');
    const serviceStartDateTime = dayjs(`${cartItem.other_details.date} ${startTime}`, 'YYYY-MM-DD hh:mm A');
    const serviceEndDateTime = dayjs(`${cartItem.other_details.date} ${endTime}`, 'YYYY-MM-DD hh:mm A');
    return serviceStartDateTime.isBefore(currentDate) || serviceEndDateTime.isBefore(currentDate);
  }

  return false;
}
