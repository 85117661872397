import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import useAxios from '../../config/axios/useAxios';
import { useContext } from 'react';
import { promptActions, promptContext } from '../../context/prompt';
import { useAuthContext } from '../../context/authContext';

export function useGetProfile() {
  const { getData } = useAuthContext();
  const { isAuth } = getData();
  const API = 'customer/get';
  const protectedAxios = useAxios();
  const { setToken } = useAuthContext();
  const prompt = useContext(promptContext);

  return useQuery({
    queryKey: [API],
    enabled: isAuth,
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(API);
        return data;
      } catch (error) {
        if (error.response.status === 401) {
          setToken('clearToken');
          prompt({
            type: promptActions.SHOW_PROMPT,
            payload: {
              message: error.response.data?.message || 'Token Expired',
            },
          });
        }
        return;
      }
    },
  });
}

export function useUpdateProfile() {
  const API = 'customer/update';
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (form) => protectedAxios.put(`${API}`, form),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customer/get'] });
    },
    onError: (error) => {
      prompt({
        type: promptActions.SHOW_PROMPT,
        payload: {
          message: error.response.data?.message || 'Error while adding',
        },
      });
    },
  });
}

export function useUpdateProfileImage() {
  const API = 'customer/update_image';
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (form) => protectedAxios.put(`${API}`, form),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customer/get'] });
    },
    onError: (error) => {
      prompt({
        type: promptActions.SHOW_PROMPT,
        payload: {
          message: error.response.data?.message || 'Error while adding',
        },
      });
    },
  });
}

export function useGetAddress() {
  const API = 'customer/address/getall';
  const protectedAxios = useAxios();
  const { setToken } = useAuthContext();
  const prompt = useContext(promptContext);
  const { getData } = useAuthContext();
  const { isAuth } = getData();
  return useQuery({
    enabled: isAuth,
    queryKey: [API],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(API);
        return data;
      } catch (error) {
        if (error.response.status === 401) {
          setToken('clearToken');
          prompt({
            type: promptActions.SHOW_PROMPT,
            payload: {
              message: error.response.data?.message || 'Token Expired',
            },
          });
        }
        return;
      }
    },
  });
}

export function useAddAddress() {
  const API = 'customer/address/add';
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (form) => protectedAxios.post(`${API}`, form),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customer/address/getall'] });
      queryClient.invalidateQueries({ queryKey: ['getdata/customer_address'] });
    },
    onError: (error) => {
      prompt({
        type: promptActions.SHOW_PROMPT,
        payload: {
          message: error.response.data?.message || 'Error while adding',
        },
      });
    },
  });
}
export function useUpdateAddress(address_id) {
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();
  const API = 'customer/address/update';
  const protectedAxios = useAxios();
  return useMutation({
    mutationFn: (form) => protectedAxios.put(`${API}/${address_id}`, form),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customer/address/getall'] });
    },
    onError: (error) => {
      prompt({
        type: promptActions.SHOW_PROMPT,
        payload: {
          message: error.response.data?.message || 'Error while adding',
        },
      });
    },
  });
}

export function useGetTractor() {
  const API = 'customer/tractor/getall';
  const protectedAxios = useAxios();
  const { setToken } = useAuthContext();
  const prompt = useContext(promptContext);
  const { getData } = useAuthContext();
  const { lang } = getData();
  return useQuery({
    queryKey: [lang, API],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(API);
        return data;
      } catch (error) {
        if (error.response.status === 401) {
          setToken('clearToken');
          prompt({
            type: promptActions.SHOW_PROMPT,
            payload: {
              message: error.response.data?.message || 'Token Expired',
            },
          });
        }
        return;
      }
    },
  });
}

export function useAddTractor(language) {
  const API = 'customer/tractor/add';
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (form) => protectedAxios.post(`${API}`, form),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`${language}`, 'customer/tractor/getall'],
      });
    },
    onError: (error) => {
      prompt({
        type: promptActions.SHOW_PROMPT,
        payload: {
          message: error.response.data?.message || 'Error while adding',
        },
      });
    },
  });
}

export function useUpdateTractor(tractor_id, language) {
  const API = 'customer/tractor/update';
  const protectedAxios = useAxios();
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (form) => protectedAxios.put(`${API}/${tractor_id}`, form),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`${language}`, 'customer/tractor/getall'],
      });
    },
    onError: (error) => {
      prompt({
        type: promptActions.SHOW_PROMPT,
        payload: {
          message: error.response.data?.message || 'Error while adding',
        },
      });
    },
  });
}

export function useGetStates() {
  const API = 'getdata/states';
  const protectedAxios = useAxios();
  const { setToken } = useAuthContext();
  const prompt = useContext(promptContext);

  return useQuery({
    queryKey: [API],
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(API);
        return data;
      } catch (error) {
        if (error.response.status === 401) {
          setToken('clearToken');
          prompt({
            type: promptActions.SHOW_PROMPT,
            payload: {
              message: error.response.data?.message || 'Token Expired',
            },
          });
        }
        return;
      }
    },
  });
}
export function useGetDistricts(state_id) {
  const API = 'getdata/districts';
  const protectedAxios = useAxios();
  const { setToken } = useAuthContext();
  const prompt = useContext(promptContext);

  return useQuery({
    queryKey: [API, state_id],
    enabled: !!state_id,
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`${API}/${state_id}`);
        return data;
      } catch (error) {
        if (error.response.status === 401) {
          setToken('clearToken');
          prompt({
            type: promptActions.SHOW_PROMPT,
            payload: {
              message: error.response.data?.message || 'Token Expired',
            },
          });
        }
        return;
      }
    },
  });
}

export function useGetSubDistricts(district_id) {
  const API = 'getdata/subdistricts';
  const protectedAxios = useAxios();
  const { setToken } = useAuthContext();
  const prompt = useContext(promptContext);
  const parsedDistrictId = parseInt(district_id);
  return useQuery({
    queryKey: [API, parsedDistrictId],
    enabled: !isNaN(parsedDistrictId) && parsedDistrictId !== 0,
    queryFn: async () => {
      try {
        const { data } = await protectedAxios.get(`${API}/${parsedDistrictId}`);
        return data;
      } catch (error) {
        if (error.response.status === 401) {
          setToken('clearToken');
          prompt({
            type: promptActions.SHOW_PROMPT,
            payload: {
              message: error.response.data?.message || 'Token Expired',
            },
          });
        }
        return;
      }
    },
  });
}

export function useMakeAddressPrimary(customer_id, address_id) {
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();
  const API = 'customer/primary_address/update';
  const protectedAxios = useAxios();
  return useMutation({
    mutationFn: () => protectedAxios.put(`${API}/${customer_id}/${address_id}`),
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: ['customer/address/getall'] });
      localStorage.setItem('location_change', true);
      prompt({
        type: promptActions.SHOW_PROMPT,
        payload: {
          type: 'success',
          message: response.data?.message || 'Added successfully',
        },
      });
    },
    onError: (error) => {
      prompt({
        type: promptActions.SHOW_PROMPT,
        payload: {
          message: error.response.data?.message || 'Error while adding',
        },
      });
    },
  });
}

export function useDeleteTractor(language) {
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();
  const API = 'customer/tractor/delete';
  const protectedAxios = useAxios();
  return useMutation({
    mutationFn: (tractor_id) => protectedAxios.delete(`${API}/${tractor_id}`),
    onSuccess: (response) => {
      prompt({
        type: promptActions.SHOW_PROMPT,
        payload: {
          type: 'success',
          message: response.data?.message || 'De-activated successfully',
        },
      });
      queryClient.invalidateQueries({
        queryKey: [`${language}`, 'customer/tractor/getall'],
      });
    },
    onError: (error) => {
      prompt({
        type: promptActions.SHOW_PROMPT,
        payload: {
          message: error.response.data?.message || 'Error while adding',
        },
      });
    },
  });
}

export function useActiveTractor(language) {
  const prompt = useContext(promptContext);
  const queryClient = useQueryClient();
  const API = 'customer/tractor/active';
  const protectedAxios = useAxios();
  return useMutation({
    mutationFn: (tractor_id) => protectedAxios.put(`${API}/${tractor_id}`),
    onSuccess: (response) => {
      prompt({
        type: promptActions.SHOW_PROMPT,
        payload: {
          type: 'success',
          message: response.data?.message || 'Activated successfully',
        },
      });
      queryClient.invalidateQueries({
        queryKey: [`${language}`, 'customer/tractor/getall'],
      });
    },
    onError: (error) => {
      prompt({
        type: promptActions.SHOW_PROMPT,
        payload: {
          message: error.response.data?.message || 'Error while adding',
        },
      });
    },
  });
}
