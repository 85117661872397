import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  locationId: localStorage.getItem('location_id') ?? null,
  stateId: localStorage.getItem('state_id') ?? null,
  districtId: localStorage.getItem('district_id') ?? null,
  locationChange: false,
  workshopSelectId: null,
  stateName: '',
  tehsilName: '',
  workshopName: '',
  workshopAddress1: '',
  workshopAddress2: '',
  WorkshopDirection: '',
  isLocationModelOpen: false,
  isLocationChangeAgree: false,
  isLocationWorkShopModel: false,
  isWorkshopLocationChangeAgree: false,
};

export const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setLocationID: (state, action) => {
      state.locationId = action.payload;
    },
    setDistrictId: (state, action) => {
      state.districtId = action.payload;
    },
    setStateID: (state, action) => {
      state.stateId = action.payload;
    },
    setLocationChange: (state, action) => {
      state.locationChange = action.payload;
    },
    setStateName: (state, action) => {
      state.stateName = action.payload;
    },
    setTehsilName: (state, action) => {
      state.tehsilName = action.payload;
    },
    setWorkshopSelectId: (state, action) => {
      state.workshopSelectId = action.payload;
    },
    setWorkshopName: (state, action) => {
      state.workshopName = action.payload;
    },
    setWorkshopAddress1: (state, action) => {
      state.workshopAddress1 = action.payload;
    },
    setWorkshopAddress2: (state, action) => {
      state.workshopAddress2 = action.payload;
    },
    setWorkshopDirection: (state, action) => {
      state.WorkshopDirection = action.payload;
    },
    setIsLocationModelOpen: (state, action) => {
      state.isLocationModelOpen = action.payload;
    },
    setIsLocationChangeAgree: (state, action) => {
      state.isLocationChangeAgree = action.payload;
    },
    setIsLocationWorkShopModel: (state, action) => {
      state.isLocationWorkShopModel = action.payload;
    },
    setIsWorkshopLocationChangeAgree: (state, action) => {
      state.isWorkshopLocationChangeAgree = action.payload;
    },
  },
});
export const {
  setIsWorkshopLocationChangeAgree,
  setIsLocationWorkShopModel,
  setIsLocationChangeAgree,
  setIsLocationModelOpen,
  setWorkshopDirection,
  setLocationID,
  setStateID,
  setLocationChange,
  setStateName,
  setTehsilName,
  setWorkshopSelectId,
  setWorkshopName,
  setWorkshopAddress1,
  setWorkshopAddress2,
  setDistrictId,
} = locationSlice.actions;
export default locationSlice.reducer;
