import { Avatar, Badge, Button, Divider, Menu, MenuItem, styled } from '@mui/material';
import IconLogoText from '../../../assets/icons/icon-sidebar/icon-logo-text';
import IconLogoTractor from '../../../assets/icons/icon-sidebar/icon-logo-tractor';
import { MenuOnly } from '../header';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../context/authContext';
import IconLogout from '../../../assets/icons/icon-header/icon-logout';
import { useUpdateLanguage } from './header-api';
import { useLanguage } from '../../../language/useLanguage';
import PopoverWithDropdowns from './location-popover';
import IconArrowDown from '../../../assets/icons/icon-common/icon-arrow-down';
import IconCart from '../../../assets/icons/icon-sidebar/icon-cart';
import IconBookings from '../../../assets/icons/icon-header/icon-bookings';
import IconDashCustomer from '../../../assets/icons/icon-dashboard/icon-dash-customer';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LocationChangeModel from '../../customer-order/location-change-model/location-change-model';
import { setIsLocationChangeAgree, setIsLocationModelOpen } from '../../../redux/features/location/locationSlice';

export default function HeaderWebV2({ HEADER_MENU, languageOptions, isImage, image, cartToggleDrawer, header_class, cartItems, existCartLocations }) {
  const navigate = useNavigate();
  const updateLanguage = useUpdateLanguage();
  const { getData, setToken, setLang } = useAuthContext();
  const { isAuth, lang } = getData();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorLanguageSelect, setAnchorLanguageSelect] = useState();
  const isLocationModelOpen = useSelector((state) => state.location.isLocationModelOpen);
  // const [selectedLanguageId, setSelectedLanguageId] = useState(null);
  const location = useLocation();

  const langData = useLanguage();

  const languageMenuOpen = Boolean(anchorLanguageSelect);
  const handleLanguageDrawerOpen = (event) => setAnchorLanguageSelect(event.currentTarget);
  const handleLanguageDrawerClose = () => setAnchorLanguageSelect(null);
  const savedValue = localStorage.getItem('selectedLang');
  console.debug('savedValue', savedValue);

  const getInitialState = () => {
    return savedValue ? savedValue : languageOptions[0]?.id;
  };
  const [option, setOption] = useState(getInitialState);
  const selectedLanguage = !isAuth ? languageOptions.find((lang) => lang.id === option) || languageOptions[0] : languageOptions.find((option) => option.id === lang);

  useEffect(() => {
    localStorage.setItem('selectedLang', option);
    if (isAuth) {
      localStorage.removeItem('selectedLang');
    }
  }, [option, savedValue, selectedLanguage, isAuth]);

  const handleClick = (option) => {
    setOption(option);
    if (isAuth) {
      const form = new FormData();
      form.append('language', option);
      updateLanguage.mutate(form, {
        onSuccess: (response) => {
          setToken(response.data.token);
          setAnchorLanguageSelect(null);
        },
      });
    } else {
      localStorage.setItem('selectedLang', option);
      setToken('changeLang', option);
      setAnchorLanguageSelect(null);
      setLang(option);
    }
  };

  !isAuth &&
    window.addEventListener('load', function (option) {
      let newSelectVal = localStorage.getItem('selectedLang', option);
      setLang(newSelectVal);
    });
  const open = Boolean(anchorEl);
  const handleDrawerOpen = (event) => setAnchorEl(event.currentTarget);
  const handleDrawerClose = () => setAnchorEl(null);

  const handleNavigation = (path) => {
    handleDrawerClose();
    navigate(path);
  };

  const dispatch = useDispatch();
  const handleDisAgree = () => {
    dispatch(setIsLocationModelOpen(false));
  };
  const handleAgree = () => {
    dispatch(setIsLocationChangeAgree(true));
    handleDisAgree();
  };

  return (
    <div>
      <SubHeaderContainer>
        <SubHeaderWrapper>
          <LeftContainer>
            <img src="/images/phone-logo-white-new.svg" style={{ height: '1.3rem', width: '1.3rem' }} />
            <Link to="tel:+91 92093 92096" target="_blank" style={{ color: '#ffffff', textDecoration: 'none' }}>
              +91 9209 392 096
            </Link>
            <Divider color="#fff" orientation="vertical" style={{ height: 14 }} />
            <img src="/images/email-logo-white-new-header.svg" style={{ height: '1.2rem', width: '1.2rem' }} />
            <Link to="mailto:customercare@tractorseva.com" target="_blank" style={{ color: '#fff', textDecoration: 'none' }}>
              customercare@tractorseva.com
            </Link>
          </LeftContainer>
          <RightContainer>
            <DownlaodAppContainer>
              <div style={{ display: 'flex', gap: 8 }}>
                <p>{langData('Download App')} :</p>
                <img
                  src="/images/playstore-white-logo-header.svg"
                  color="#fff"
                  style={{
                    height: '1.2rem',
                    width: '1.2rem',
                    cursor: 'pointer',
                  }}
                  onClick={() => window.open('https://play.google.com/store/apps/details?id=com.tractorseva.customer&pli=1', '_blank')}
                />
                <img
                  src="/images/appstore-white-logo-header.svg"
                  color="#fff"
                  style={{
                    height: '1.1rem',
                    width: '1.1rem',
                    cursor: 'pointer',
                  }}
                  onClick={() => window.open('https://apps.apple.com/in/app/ts-customer/id6472632200', '_blank')}
                />
              </div>
            </DownlaodAppContainer>
            <Divider color="#fff" orientation="vertical" style={{ height: 14 }} />
            <div>
              <div className="header-username">
                <Button
                  variant="text"
                  onClick={languageMenuOpen ? handleLanguageDrawerClose : handleLanguageDrawerOpen}
                  startIcon={<img src="/images/language-white-logo-new-header.svg" color="#fff" />}
                  endIcon={<IconArrowDown />}
                  style={{ color: '#fff' }}
                >
                  {selectedLanguage.label}
                </Button>
              </div>
              <Menu
                open={languageMenuOpen}
                anchorEl={anchorLanguageSelect}
                className="profile-menu language-menu"
                onClose={handleLanguageDrawerClose}
                elevation={0}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                sx={{
                  '& .MuiPaper-root': {
                    borderRadius: '8px',
                    boxShadow: '0px 8px 24px 0px rgba(149, 157, 165, 0.37)',
                    marginTop: '10px',
                    minWidth: '124px',
                  },
                  '& ul': {
                    paddingTop: 0,
                    paddingBottom: 0,
                  },
                  '& li': {
                    gap: '1em',
                    paddingTop: '0.5em',
                    paddingBottom: '0.5em',
                    alignItems: 'center',
                  },
                }}
              >
                {languageOptions.map((option) => (
                  <MenuItem
                    key={option.id}
                    className={`${!isAuth ? `language-option ${option.id === savedValue ? 'active' : ''}` : `language-option ${option.id === lang ? 'active' : ''}`}`}
                    // className={`language-option ${option.id === lang ? 'active' : ''}`}
                    onClick={() => {
                      handleClick(option.id);
                      handleLanguageDrawerClose();
                    }}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Menu>
            </div>
            <Divider color="#fff" orientation="vertical" style={{ height: 14 }} />
            <PopoverWithDropdowns existCartLocations={existCartLocations} />
          </RightContainer>
        </SubHeaderWrapper>
      </SubHeaderContainer>
      <div
        className="header-home"
        style={{
          padding: '2rem var(--padding-inline)',
          backgroundColor: `${header_class ? 'var(--bg-secondary)' : '#fff'}`,
        }}
      >
        <div className="logo" onClick={() => navigate(`/`)}>
          <IconLogoTractor width="50" height="50" />
          <IconLogoText width="120" height="50" />
        </div>
        <div className="header-menu">
          {HEADER_MENU?.map((item, index) => (
            <MenuOnly key={index} item={item} />
          ))}
        </div>
        <div className="header-profile-container">
          {isAuth && !location.pathname.includes('order-summary') ? (
            <Badge badgeContent={cartItems ?? 0} max={99} color="secondary" style={{ cursor: 'pointer' }}>
              <IconCart color="var(--clr-primary)" onClick={cartToggleDrawer('right', true)} height="1.5rem" width="1.5rem" />
            </Badge>
          ) : (
            ''
          )}
          {isAuth ? (
            <div className="profile" style={{ cursor: 'pointer' }}>
              {isImage ? (
                <Avatar src={image} onClick={open ? handleDrawerClose : handleDrawerOpen} />
              ) : (
                <Avatar style={{ backgroundColor: 'var(--clr-primary)' }} onClick={open ? handleDrawerClose : handleDrawerOpen}>
                  {image}
                </Avatar>
              )}
              <Menu
                open={open}
                className="profile-menu"
                onClose={handleDrawerClose}
                elevation={0}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                sx={{
                  '& .MuiPaper-root': {
                    borderRadius: '8px',
                    boxShadow: '0px 8px 24px 0px rgba(149, 157, 165, 0.37)',
                    marginTop: '10px',
                  },
                  '& ul': {
                    paddingTop: 0,
                    paddingBottom: 0,
                  },
                  '& li': {
                    gap: '1em',
                    paddingTop: '0.5em',
                    paddingBottom: '0.5em',
                    alignItems: 'center',
                  },
                }}
              >
                <MenuItem onClick={() => handleNavigation('/profile')}>
                  <div className="menu-icon">
                    <IconDashCustomer width="2em" height="2em" />
                  </div>
                  <div className="menu-details">
                    <div className="menu-text-header">{langData('My Profile')}</div>
                    <div className="menu-text-subheader">{langData('Manage your Profile, Address & Tractor')}</div>
                  </div>
                </MenuItem>
                <MenuItem onClick={() => handleNavigation('/orders')}>
                  <div className="menu-icon">
                    <IconBookings width="2em" height="2em" />
                  </div>
                  <div className="menu-details">
                    <div className="menu-text-header">{langData('My Order')}</div>
                    <div className="menu-text-subheader">{langData('See your order details')}</div>
                  </div>
                </MenuItem>
                <MenuItem onClick={() => handleNavigation('/logout')}>
                  <div className="menu-icon">
                    <IconLogout width="2em" height="2em" />
                  </div>
                  <div className="menu-details">
                    <div className="menu-text-header">{langData('Logout')}</div>
                  </div>
                </MenuItem>
              </Menu>
            </div>
          ) : (
            <Button variant="outlined" onClick={() => navigate('/login')}>
              {langData('Login')}
            </Button>
          )}
        </div>
      </div>
      {isLocationModelOpen && <LocationChangeModel isModelOpen={isLocationModelOpen} handleAgree={handleAgree} handleDisAgree={handleDisAgree} />}
    </div>
  );
}

const SubHeaderContainer = styled('div')({
  backgroundColor: '#EC2726',
  padding: '0.2rem var(--padding-inline)',
});

const SubHeaderWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  p: { fontSize: '0.9rem', color: '#fff' },
});

const LeftContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
});

const RightContainer = styled('div')({
  display: 'flex',
  gap: 16,
  alignItems: 'center',
});

const DownlaodAppContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
});
