import './footer.css';
import '../homepage/components/cancellation-and-rescheduling-policy/cancellation-and-rescheduling-policy';
import '../homepage/components/terms-and-condition/terms-and-condition';
import '../homepage/components/privacy-policy/privacy-policy';
import '../homepage/components/service-agreement/service-agreement';
import { Link, useNavigate } from 'react-router-dom';
import IconLogoTractor from '../../assets/icons/icon-sidebar/icon-logo-tractor';
import IconLogoText from '../../assets/icons/icon-sidebar/icon-logo-text';
import { useLanguage } from '../../language/useLanguage';
import { Button, styled } from '@mui/material';
import IconPhone from '../../assets/icons/icon-common/icon-phone-new';
import IconFacebook from '../../assets/icons/icon-common/icon-facebook-new';
import IconInstagram from '../../assets/icons/icon-common/icon-instagram-new';
import IconLinkdin from '../../assets/icons/icon-common/icon-linkdin-new';
import IconTwitter from '../../assets/icons/icon-common/icon-twitter-new';
import IconEmailFooter from '../../assets/icons/icon-common/icon-email-footer-new';
import IconLocationFooter from '../../assets/icons/icon-common/icon-location-footer-new';
import { useSelector } from 'react-redux';
import { HashLink } from 'react-router-hash-link';

const home = ['', 'home-page-v2'];
export default function FooterV2() {
  const navigate = useNavigate();
  const langData = useLanguage();
  const tractorModelName = useSelector((state) => state.customer_order.tractorModelName);
  const tractorBrandName = useSelector((state) => state.customer_order.tractorBrandName);

  const header_class = home.includes(location.pathname.split('/')[1]);
  return (
    <footer className={`${header_class ? 'home-footer' : ''}`} style={{ marginTop: 32 }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
        <FirstSection>
          <HaveQuetion className="ask-container">
            <div className="ask-title">
              <p>
                {langData('Have a Question?')} <br />
                {langData('Feel free to ask')}
              </p>
            </div>
            <div className="contact-container" style={{ alignItems: 'center' }}>
              <IconPhone height="1.5rem" width="1.5rem" />
              <Link to="tel:+91-92093-92096" target="_blank" style={{ fontSize: '1.2rem' }}>
                {langData('+91 92093 92096')}
              </Link>
            </div>
          </HaveQuetion>
          <Enquire>
            <p style={{ color: '#fff', fontSize: '2rem', fontWeight: 600 }}>
              {langData('Send Inquiry')} <br />
              {langData('For Franchise')}
            </p>
            <div>
              <Button color="white" variant="contained" onClick={() => navigate('/franchise')}>
                {langData('Inquiry')}
              </Button>
            </div>
          </Enquire>
        </FirstSection>
        <SecondSection>
          <div className="logo-social-container" style={{ flexGrow: 1 }}>
            <div className="logo-container" style={{ display: 'flex', gap: 32, flexDirection: 'column' }}>
              <div
                style={{
                  paddingBottom: 5,
                  display: 'flex',
                  gap: 6,
                  alignItems: 'center',
                }}
                className="logo"
                onClick={() => navigate(`/`)}
              >
                <IconLogoTractor width="40" height="50" color1="var(--bg-secondary)" color2="var(--bg-secondary)" />
                <IconLogoText width="90" height="50" color1="var(--bg-secondary)" color2="var(--bg-secondary)" />
              </div>
              <div className="social-media-container">
                <div className="abt-footer-title">
                  <p style={{ color: '#ffffff80' }}>{langData('Follow Us')}</p>
                </div>
                <div className="social-media-icn-container">
                  <Link target="_blank" to="https://www.facebook.com/tractorsevaindia?mibextid=LQQJ4d">
                    <IconFacebook height="2rem" width="2rem" />
                  </Link>
                  <Link target="_blank" to="https://www.instagram.com/tractorseva/?igsh=MXBsZGk3ajkyMTA4NQ%3D%3D">
                    <IconInstagram height="2rem" width="2rem" />
                  </Link>
                  <Link target="_blank" to="https://twitter.com/TractorSeva">
                    <IconTwitter height="2rem" width="2rem" />
                  </Link>
                  <Link target="_blank" to="https://www.linkedin.com/company/tractor-seva/">
                    <IconLinkdin height="2rem" width="2rem" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="help-info-footer-links" style={{ flexGrow: 1 }}>
            <div className="help-info-link-footer-title">
              <p>{langData('Important Links')}</p>
            </div>
            <li>
              <Link to="terms-and-condition">{langData('Terms and Condition')}</Link>
            </li>
            <li>
              <Link to="privacy-policy">{langData('Privacy Policy')}</Link>
            </li>
            <li>
              <Link to="service-agreement">{langData('Service Agreement')}</Link>
            </li>
            <li>
              <Link to="cancellation-policy">{langData('Cancellation and Rescheduling Policy')}</Link>
            </li>
          </div>
          <div className="help-info-footer-links" style={{ flexGrow: 1 }}>
            <div className="help-info-link-footer-title">
              <p>{langData('Quick Links')}</p>
            </div>
            <li onClick={() => window.scrollTo(0, 0)}>
              <HashLink to="/">{langData('Home')}</HashLink>
            </li>
            <li>
              <HashLink to={`${tractorBrandName && tractorModelName ? '/customer-order/#book-a-service' : '/#select-service'}`}>{langData('Service')}</HashLink>
            </li>
            <li>
              <HashLink to={`${tractorBrandName && tractorModelName ? '/customer-order/#buy-kits' : '/#select-service'}`}>{langData('Kits')}</HashLink>
            </li>
            <li>
              <HashLink to={`${tractorBrandName && tractorModelName ? '/customer-order/#buy-parts-&-accessories' : '/#select-service'}`}>
                {langData('Parts & Accessories')}
              </HashLink>
            </li>
            <li>
              <Link to="about-us">{langData('About Us')}</Link>
            </li>
            <li>
              <Link to="franchise">{langData('Franchises')}</Link>
            </li>
            <li>
              <Link to="contact-us">{langData('Contact Us')}</Link>
            </li>
          </div>
          <div className="help-info-footer-links" style={{ flexGrow: 1 }}>
            <div className="help-info-link-footer-title">
              <p>{langData('Our Offerings')}</p>
            </div>
            <li>
              <HashLink to={`${tractorBrandName && tractorModelName ? '/customer-order/#book-a-service' : '/#select-service'}`}>{langData('Book Service')}</HashLink>
            </li>
            <li>
              <HashLink to={`${tractorBrandName && tractorModelName ? '/customer-order/#buy-kits' : '/#select-service'}`}>{langData('Buy Kits')}</HashLink>
            </li>
            <li>
              <HashLink to={`${tractorBrandName && tractorModelName ? '/customer-order/#buy-parts-&-accessories' : '/#select-service'}`}>
                {langData('Buy Parts & Accessories')}
              </HashLink>
            </li>
          </div>
          <div className="help-info-link-container" style={{ flexGrow: 1 }}>
            <div className="help-info-link-footer-title">
              <p style={{ color: '#ffffffcc' }}>{langData('Contact Us')}</p>
            </div>
            <div className="help-info-footer-links">
              <div className="location-container">
                <div className="location-icn-container">
                  <IconLocationFooter height="1.5rem" width="1.5rem" />
                </div>
                <div className="location-details">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: langData(
                        'Bedienung Solution Private Limited (Tractor Seva)<br /> C/o- InFED Indian Institute of Management Nagpur Mihan (Non-Sez), Nagpur, Maharashtra, India - 441108',
                      ),
                    }}
                    style={{ color: '#ffffff80' }}
                  ></p>
                </div>
              </div>
              <div className="mail-container">
                <div className="mail-icn-container">
                  <IconEmailFooter height="1.5rem" width="1.5rem" />
                </div>
                <div className="mail-details">
                  <Link to="mailto:customercare@tractorseva.com" target="_blank" style={{ color: '#ffffff80' }}>
                    customercare@tractorseva.com
                  </Link>
                </div>
              </div>
              <div className="mail-container">
                <div className="mail-icn-container">
                  <IconPhone height="1.5rem" width="1.5rem" />
                </div>
                <div className="mail-details">
                  <Link to="tel:+9192093 92096" target="_blank" style={{ color: '#ffffff80' }}>
                    {langData('+91 92093 92096')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </SecondSection>
        <CopyRightSection className="sub-footer-container">
          <div>
            <p>{langData('All rights reserved to Tractor seva')}</p>
          </div>
          <div className="pc-container">
            <img src="/icons/UPI.svg" style={{ width: '2.5rem' }} />
            <img src="/icons/RuPay 1.svg" style={{ width: '2.5rem' }} />
            <img src="/icons/MasterCard.svg" style={{ width: '2.5rem', height: '1rem' }} />
            <img src="/icons/Visa&mastercard.svg" style={{ width: '2.5rem' }} />
            <img src="/icons/AmericanExpress.svg" style={{ width: '2.5rem' }} />
          </div>
        </CopyRightSection>
      </div>
    </footer>
  );
}

export function SubFooterV2() {
  const langData = useLanguage();
  const header_class = home.includes(location.pathname.split('/')[1]);

  return (
    <footer className={`${header_class ? 'home-footer' : ''}`}>
      <CopyRightSection>
        <div>
          <p>{langData('All rights reserved to Tractor seva')}</p>
        </div>
        <div className="pc-container">
          <img src="/icons/UPI.svg" style={{ width: '2.5rem' }} />
          <img src="/icons/RuPay 1.svg" style={{ width: '2.5rem' }} />
          <img src="/icons/MasterCard.svg" style={{ width: '2.5rem', height: '1rem' }} />
          <img src="/icons/Visa&mastercard.svg" style={{ width: '2.5rem' }} />
          <img src="/icons/AmericanExpress.svg" style={{ width: '2.5rem' }} />
        </div>
      </CopyRightSection>
    </footer>
  );
}

const FirstSection = styled('div')({
  display: 'flex',
  gap: '2rem',
  padding: '3rem 0 2rem',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
});

const SecondSection = styled('div')({
  display: 'flex',
  gap: '2rem',
  padding: '3rem 0 2rem',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  borderTop: '1px solid #ffffff26',
  '>div:nth-child(1)': {
    flexBasis: 122,
  },
  '>div:nth-child(2)': {
    flexBasis: 61,
  },
  '>div:nth-child(3)': {
    flexBasis: 50,
  },
  '>div:nth-child(4)': {
    flexBasis: 68,
  },
  '>div:nth-child(5)': {
    flexBasis: 298,
  },
  ' div.help-info-footer-links': {
    li: {
      a: { color: '#ffffff80' },
    },
    p: {
      color: '#FFFFFFCC',
    },
  },
  '@media only screen and (max-width: 600px)': {
    '>div:nth-child(1)': {
      flexBasis: '100%',
    },
    '>div:nth-child(2)': {
      flexBasis: '100%',
    },
    '>div:nth-child(3)': {
      flexBasis: '100%',
    },
    '>div:nth-child(4)': {
      flexBasis: '100%',
    },
    '>div:nth-child(5)': {
      flexBasis: '100%',
    },
  },
});

const CopyRightSection = styled('div')({
  marginInline: 'calc(0px - var(--padding-inline))',
  background: '#FFFFFF1A',
  paddingInline: 'var(--padding-inline)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0.5rem var(--padding-inline)',
  'div p': {
    color: '#ffffffcc',
    fontSize: 12,
    fontWeight: 400,
  },
  '@media only screen and (max-width: 600px)': {
    alignItems: 'self-start',
    gap: '0.8rem',
  },
});

const Enquire = styled('div')({
  borderLeft: '1px solid #ffffff26',
  paddingLeft: 28,
  display: 'flex',
  flexDirection: 'column',
  gap: 14,
  flex: '1 1 500px',
  '@media only screen and (max-width: 600px)': {
    borderLeft: 'none',
    paddingLeft: 0,
  },
});

const HaveQuetion = styled('div')({
  flex: '1 1 500px',
  display: 'flex',
  flexDirection: 'column',
  gap: 14,
  '@media only screen and (max-width: 600px)': {
    borderBottom: '1px solid #ffffff26',
    paddingBottom: '2rem',
  },
});
