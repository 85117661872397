import axios from 'axios';
import { useAuthContext } from '../../context/authContext';

export default function useAxios() {
  const { getData } = useAuthContext();
  const { token } = getData();

  // Create an axios instance
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    headers: { Authorization: `Bearer ${token}` },
  });

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      // Check if the error is a 401 Unauthorized
      if (error.response && error.response.status === 401) {
        // Clear local storage
        window.location.href = '/session-timeout';
        localStorage.clear();
      }
      return Promise.reject(error);
    },
  );

  return axiosInstance;
}
