import { HashLink } from 'react-router-hash-link';
import { useGetProfile } from '../profile/profile-api';
import { useAuthContext } from '../../context/authContext';
import { useLanguage } from '../../language/useLanguage';
import HeaderWebV2 from './header/header-web-V2';
import HeaderResponsiveV2 from './header/header-responsive-V2';
import { Drawer } from '@mui/material';
import { useGetLocation } from '../homepage/homepage-api';
import { useDispatch, useSelector } from 'react-redux';
import { setDistrictId, setLocationID, setStateID } from '../../redux/features/location/locationSlice';
import Cart from './cart/cart';
import { useGetCart } from './header/header-api';
import { setCartId, setCartItems, setCartState } from '../../redux/features/cart/cartSlice';
import { useEffect } from 'react';

export const HEADER_MENU = [
  {
    title: 'Home',
    path: '/',
    subItems: null,
    target: '',
  },
  {
    title: 'Shop Now',
    path: '/#select-service',
    subItems: null,
    target: '',
  },
  {
    title: 'About Us',
    path: '/about-us',
    subItems: null,
    target: '',
  },
  {
    title: 'Warranty',
    path: '/warranty',
    subItems: null,
    target: '',
  },
  // {
  //   title: "Know Tractor Seva",
  //   path: "https://about.tractorseva.com/",
  //   subItems: null
  // },
  {
    title: 'Franchise',
    path: '/franchise',
    subItems: null,
    target: '',
  },
  {
    title: 'Contact Us',
    path: '/contact-us',
    subItems: null,
    target: '',
  },
  // {
  //   title: "Download App",
  //   path: "https://play.google.com/store/apps/details?id=com.tractorseva.customer",
  //   subItems: null,
  //   target: "_blank"
  // },
];

export const SIDEBAR_MENU = [
  // {
  //   title: "Know Tractor Seva",
  //   path: "https://about.tractorseva.com/",
  //   subItems: null
  // },
  {
    title: 'Home',
    path: '/',
    subItems: null,
    target: '',
  },
  {
    title: 'Shop Now',
    path: '/#select-service',
    subItems: null,
    protected: false,
    target: '',
  },
  {
    title: 'About Us',
    path: '/about-us',
    subItems: null,
    protected: false,
    target: '',
  },
  {
    title: 'Warranty',
    path: '/warranty',
    subItems: null,
    target: '',
  },
  {
    title: 'My Orders',
    path: '/orders',
    subItems: null,
    protected: true,
    target: '',
  },
  {
    title: 'My Profile',
    path: '/profile',
    subItems: null,
    protected: true,
    target: '',
  },
  {
    title: 'Franchise',
    path: '/franchise',
    subItems: null,
    protected: false,
    target: '',
  },
  {
    title: 'Contact Us',
    path: '/contact-us',
    subItems: null,
    protected: false,
    target: '',
  },
  // {
  //   title: "Download App",
  //   path: "https://play.google.com/store/apps/details?id=com.tractorseva.customer",
  //   subItems: null,
  //   protected: false,
  //   target: "_blank",
  // },
];

const languageOptions = [
  {
    id: 'EN',
    label: 'English',
    sortLabel: 'En',
  },
  {
    id: 'HI',
    label: 'हिन्दी',
    sortLabel: 'हिन्दी',
  },
  {
    id: 'MR',
    label: 'मराठी',
    sortLabel: 'मराठी',
  },
];

const header_secondary = ['booking', 'profile', 'cancellation-policy', 'terms-and-condition', 'service-agreement', 'privacy-policy', 'warranty-bosch-t&c'];

export default function Header() {
  const { getData } = useAuthContext();
  const { userData, isAuth } = getData();
  const { data, isLoading } = useGetProfile();
  const image = !isLoading && data?.image ? data.image : `${userData?.first_name[0]}`;
  const location = useGetLocation();
  const dispatch = useDispatch();
  const header_class = header_secondary.includes(window.location.pathname.split('/')[1]);
  const cart = useGetCart(userData?.user_id);
  const cartState = useSelector((state) => state.cart.cartState);
  const cartItems = useSelector((state) => state.cart.cartItems);
  const locationId = useSelector((state) => state.location.locationId);
  const stateId = useSelector((state) => state.location.stateId);
  const districtId = useSelector((state) => state.location.districtId);
  const locationChange = useSelector((state) => state.location.locationChange);
  const isLocationChange = localStorage.getItem('location_change');
  // const queryClient = useQueryClient();
  // useEffect(() => {
  //   if (token !== null) {
  //     queryClient.invalidateQueries(['getData/location']);
  //   }
  // }, [token, queryClient]);

  useEffect(() => {
    const updateLocation = (locId, stId, dtId) => {
      localStorage.setItem('location_id', locId);
      localStorage.setItem('state_id', stId);
      localStorage.setItem('district_id', dtId);
      dispatch(setLocationID(locId));
      dispatch(setStateID(stId));
      dispatch(setDistrictId(dtId));
    };

    // **Check each field individually and clear only the null ones**
    if (isAuth && locationId === null) {
      updateLocation(null, stateId, districtId);
    } else if (isAuth && stateId === null) {
      updateLocation(locationId, null, districtId);
    } else if (isAuth && districtId === null) {
      updateLocation(locationId, stateId, null);
    } else {
      if (!isAuth && location.isFetched && !locationChange) {
        if (isLocationChange) {
          updateLocation(locationId, stateId, districtId);
        } else {
          updateLocation(location?.data?.location_id, location?.data?.state_id, location?.data?.district_id);
        }
      } else if (isAuth && cart?.data?.Workshop && !locationChange && ['false', undefined, null].includes(isLocationChange)) {
        const workshopSubDistrictId = cart?.data?.Workshop.SubDistrict.id;
        if (workshopSubDistrictId && workshopSubDistrictId !== locationId) {
          updateLocation(workshopSubDistrictId, cart?.data?.Workshop.SubDistrict.District.State.id, cart?.data?.Workshop.SubDistrict.District.id);
        }
      } else if (!locationId && location.isFetched && !locationChange) {
        updateLocation(location?.data?.location_id, location?.data?.state_id, location?.data?.district_id);
      } else if (isAuth && (!cartItems || cartItems.length === 0) && !locationChange && (isLocationChange === 'false' || isLocationChange === null) && location.isFetched) {
        if (location?.data) {
          updateLocation(location?.data?.location_id, location?.data?.state_id, location?.data?.district_id);
        }
      } else {
        updateLocation(locationId, stateId, districtId);
      }
    }

    if (cart?.data) {
      dispatch(setCartId(cart.data.id));
    }
  }, [isAuth, cart, location, locationId, stateId, locationChange, dispatch, cartItems, isLocationChange, districtId]);

  const cartToggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    dispatch(setCartState({ ...cartState, [anchor]: open }));
  };

  const hideHeader = useSelector((state) => state.tractor_selection.hideHeader);

  // this use Effect is used for whenever our cart api called or invalidate  data updated this will set updated value

  useEffect(() => {
    if (cart?.data) {
      dispatch(setCartItems(cart?.data?.CartItems ?? []));
    }
  }, [cart.data, dispatch]);

  return (
    <header id="header" style={{ position: `${hideHeader ? 'static' : 'sticky'}` }} className={`${header_class ? 'header-blue' : ''}`}>
      {window.innerWidth > 600 ? (
        <>
          {/* <HeaderWeb
            HEADER_MENU={HEADER_MENU}
            languageOptions={languageOptions}
            isImage={Boolean(data?.image)}
            image={image}
          /> */}
          <HeaderWebV2
            HEADER_MENU={HEADER_MENU}
            languageOptions={languageOptions}
            isImage={Boolean(data?.image)}
            image={image}
            cartToggleDrawer={cartToggleDrawer}
            onClose={cartToggleDrawer('right', false)}
            header_class={header_class}
            cartItems={cart?.data?.cart_items_count}
            existCartLocations={{
              state: cart?.data?.Workshop?.SubDistrict.District.State.id ?? null,
              districtId: cart?.data?.Workshop?.SubDistrict.District.id ?? null,
              locationId: cart?.data?.Workshop?.SubDistrict.id ?? null,
            }}
          />
        </>
      ) : (
        // <HeaderResponsive
        //   SIDEBAR_MENU={SIDEBAR_MENU}
        //   languageOptions={languageOptions}
        //   isImage={Boolean(data?.image)}
        //   image={image}
        // />
        <HeaderResponsiveV2
          SIDEBAR_MENU={SIDEBAR_MENU}
          languageOptions={languageOptions}
          isImage={Boolean(data?.image)}
          image={image}
          cartToggleDrawer={cartToggleDrawer}
          cartItems={cart?.data?.cart_items_count}
          existCartLocations={{
            state: cart?.data?.Workshop?.SubDistrict.District.State.id ?? null,
            districtId: cart?.data?.Workshop?.SubDistrict.District.id ?? null,
            locationId: cart?.data?.Workshop?.SubDistrict.id ?? null,
          }}
        />
      )}
      <Drawer anchor={window.innerWidth > 600 ? 'right' : 'bottom'} open={cartState['right']} onClose={cartToggleDrawer('right', false)}>
        <Cart data={cart?.data} onClose={cartToggleDrawer('right', false)} />
      </Drawer>
    </header>
  );
}

export function MenuOnly({ item }) {
  const langData = useLanguage();
  return (
    <HashLink className="sidebar-main-link" to={item.path} target={item.target}>
      <div className={`sidebar-title`}>{langData(item.title)}</div>
    </HashLink>
  );
}
