import { Button, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { IconCalendar } from '../../../assets/icons/icon-common/icon-cart-calendar-new';
import dayjs from 'dayjs';
import { LazyImage } from '../../../assets/image/lazyImage';
import { Fragment } from 'react';
import { IconWatch } from '../../../assets/icons/icon-common/icon-watch-new';
import { useGetCartSummary } from '../../layout/header/header-api';
import { useLanguage } from '../../../language/useLanguage';
import { useSelector } from 'react-redux';
import { formatAmount } from '../../../config/common-function/calculation';
import { useLocation, useNavigate } from 'react-router-dom';
import './order-summary.css';
import { useAddCustomerOrder } from '../../layout/cart/cart-api';
import { Link } from 'react-router-dom';
import NoCartFound from '../../no-cart-found/no-cart-found';

export default function OrderSummary() {
  const cart_id = useSelector((state) => state.cart.cartId);
  const cartItems = useSelector((state) => state.cart.cartItems);
  const cart_summary = useGetCartSummary(cart_id);
  const langData = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();
  console.debug(location, 'location');
  const addCustomerOrder = useAddCustomerOrder();
  if (cartItems == null || (Array.isArray(cartItems) && cartItems.length == 0)) {
    return <NoCartFound />;
  }
  if (!cart_summary.isFetched) return '...';

  const { customer_details, workshop_details, cart_details } = cart_summary.data;

  function handleSubmit(e) {
    e.preventDefault();
    addCustomerOrder.mutate(
      { cart_id },
      {
        onSuccess: (res) => {
          const linkData = res.split('https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction');
          window.open(`https://www.tractorseva.com/paylink/${linkData[1]}`, '_self');
        },
      },
    );
  }

  return (
    <div>
      <TitleWrapper>
        <h1>{langData('Order Summary')}</h1>
      </TitleWrapper>
      <FirstSectionContainer>
        <CustomerDetails>
          <BoxHeading>
            <div className="customer-details-div">
              <p>{langData('Customer Details')}</p>
            </div>
            <div className="customer-details-child">
              <div className="customer-details-sub-child">
                <div className="personal-info-div">
                  <p className="personal-info-div-p">{langData('Personal Info.')}</p>
                  <p style={{ color: 'var(--clr-primary)', fontWeight: 600 }}>{customer_details.customer_name}</p>
                  <p style={{ color: 'var(--clr-primary)', fontWeight: 500 }}>+91 {customer_details.customer_phone_no}</p>
                  <p style={{ color: 'var(--clr-primary)', fontWeight: 500 }}>{customer_details.customer_email}</p>
                </div>
                <div className="personal-info-div">
                  <p className="personal-info-div-p">{langData('Customer Address')}</p>
                  <p style={{ color: 'var(--clr-primary)', fontWeight: 600 }}>{customer_details.address_name}</p>
                  <summary style={{ color: 'var(--clr-primary)', fontWeight: 500 }}>{customer_details.address}</summary>
                </div>
              </div>
            </div>
          </BoxHeading>
        </CustomerDetails>
        <div className="workshop">
          <BoxHeading>
            <WorkShopLocationContainer>
              <p className="workshop-p">
                {langData('Workshop Details')}
                <span className="workshop-p-span"> {langData('(For Service & Pickup)')}</span>
              </p>
              <Link to={workshop_details.workshop_map_link} style={{ color: 'red', fontWeight: 600, fontSize: 14 }}>
                {langData('Get Direction')}
              </Link>
            </WorkShopLocationContainer>
            <div className="workshop-content-div">
              <div className="workshop-content-child">
                <div className="workshop-content-sub-child">
                  <p className="workshop-content-p">{langData('Workshop')}</p>
                  <p style={{ color: 'var(--clr-primary)', fontWeight: 500 }}>{workshop_details.workshop_name}</p>
                </div>
                <div className="workshop-content-sub-child">
                  <p className="workshop-content-p">{langData('Workshop Address')}</p>
                  <summary style={{ color: 'var(--clr-primary)', fontWeight: 500 }}>{workshop_details.workshop_address}</summary>
                </div>
              </div>
            </div>
          </BoxHeading>
        </div>
      </FirstSectionContainer>
      <FirstSectionContainer sx={{ marginBottom: 8 }}>
        <CustomerDetails>
          <BoxHeading>
            <div style={{ paddingBlock: 12 }}>
              <p
                style={{
                  color: 'var(--clr-primary)',
                  fontWeight: 600,
                  fontSize: 18,
                }}
              >
                {langData('Cart Details')}
              </p>
            </div>
            <CustomTableContainer>
              {/* {window.innerWidth >= 600 && ( */}
              <Table
                style={{
                  borderTop: '1px solid rgba(224, 224, 224, 1)',
                  paddingLeft: '0px',
                }}
                stickyHeader
                aria-label="sticky table"
              >
                <TableHead
                  style={{
                    borderTop: '1px solid rgba(224, 224, 224, 1)',
                    padding: '0px',
                  }}
                >
                  <TableRow>
                    <TableCell style={{ padding: '8px 8px 8px 0px' }}>{langData('Item(s)')}</TableCell>
                    <TableCell style={{ padding: '8px 8px 8px 0px' }}>{langData('Quantity')}</TableCell>
                    <TableCell style={{ padding: '8px 8px 8px 0px' }}>{langData('Price')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cart_details.map((cartItem, index) => (cartItem.service_id ? <Service cartItem={cartItem} key={index} /> : <Kit kit={cartItem} key={index} />))}
                </TableBody>
              </Table>
              {/* )} */}
            </CustomTableContainer>
          </BoxHeading>
        </CustomerDetails>
        <PriceDetails>
          <BoxHeading>
            <div style={{ paddingBlock: 12, borderBottom: '1px solid #0028521A' }}>
              <p
                style={{
                  color: 'var(--clr-primary)',
                  fontWeight: 600,
                  fontSize: 18,
                }}
              >
                {langData('Price Details')}
              </p>
            </div>
            <BillingSummary>
              <div>
                <p>{langData('Total')}</p>
                <p>₹ {formatAmount(cart_summary.data.sub_total)}</p>
              </div>
              <div>
                <p>{langData('Discount')}</p>
                <p style={{ color: '#108D0D' }}>₹ {formatAmount(cart_summary.data.discount)}</p>
              </div>
              <div>
                <p>
                  {langData('Tax')} <strong style={{ fontWeight: 500, color: '#EC2726' }}>(18%)</strong>
                </p>
                <p>₹ {formatAmount(cart_summary.data.tax)}</p>
              </div>
              <div>
                <h4>{langData('Grand Total')}</h4>
                <h4>₹ {formatAmount(cart_summary.data.total)}</h4>
              </div>
            </BillingSummary>
            {window.innerWidth > 600 && (
              <ButtonWrapper>
                <Button
                  variant="outlined"
                  onClick={() => {
                    if (location.state === null) {
                      navigate('/');
                    } else {
                      navigate(-1);
                    }
                  }}
                >
                  {langData('Cancel')}
                </Button>
                <Button variant="contained" sx={{ width: 250 }} onClick={handleSubmit}>
                  {langData('Place Order')}
                </Button>
              </ButtonWrapper>
            )}
          </BoxHeading>
        </PriceDetails>
        {window.innerWidth <= 600 && (
          <ButtonWrapperResponsive>
            <Button
              variant="outlined"
              onClick={() => {
                if (location.state === null) {
                  navigate('/');
                } else {
                  navigate(-1);
                }
              }}
            >
              {langData('Cancel')}
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              {langData('Place Order')}
            </Button>
          </ButtonWrapperResponsive>
        )}
      </FirstSectionContainer>
    </div>
  );
}

const TitleWrapper = styled('div')({
  paddingBlock: 24,
  borderBottom: '1px solid #0028521A',
});

const PriceDetails = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  flex: '1 1 500px',
  border: '1px solid #0028521A',
  height: 286,
  // '@media only screen and (max-width: 600px)': {
  //   height: '25vh',
  //   marginBottom: '64px',
  // },
});

const ButtonWrapper = styled('div')({
  display: 'flex',
  gap: 16,
  justifyContent: 'flex-end',
  flexWrap: 'wrap',
  paddingTop: 16,
});

const ButtonWrapperResponsive = styled('div')({
  display: 'flex',
  // gap: 16,
  justifyContent: 'space-between',
  width: '100%',
  flexWrap: 'wrap',
  padding: 16,
  backgroundColor: '#F2F7FF',
  marginInline: 'calc(0px - var(--padding-inline))',
  flexGrow: 1,
  // position: 'fixed',
  zIndex: 9,
});

const BoxHeading = styled('div')({
  paddingInline: 12,
  width: '100%',
});

const WorkShopLocationContainer = styled('div')({
  paddingBlock: 12,
  borderBottom: '1px solid #0028521A',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const FirstSectionContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  gap: 24,
  marginTop: 24,
});

const CustomerDetails = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  flex: '1 1 500px',
  border: '1px solid #0028521A',
});

const Service = ({ cartItem }) => {
  const langData = useLanguage();
  return (
    <Fragment>
      <TableRow className="service-cell">
        <CustomeTableCellService rowSpan={6}>
          <LazyImage name={cartItem.service_type_img} height="2rem" width="2rem" />
          <div>
            <div>
              <p>{cartItem.service_name}</p>
            </div>
            <p style={{ fontWeight: 600 }}>{cartItem.tractor_model}</p>
            <p style={{ color: '#6D7D93' }}>Chassis No: {cartItem.chassis_no}</p>
          </div>
        </CustomeTableCellService>
        <CustomeTableCellPrice>{cartItem.quantity}</CustomeTableCellPrice>
        <CustomeTableCellPrice>
          <div>
            <p style={{ fontSize: '1rem', fontWeight: 600 }}>₹ {cartItem.offer_total}</p>
            <p style={{ fontSize: '0.9rem', fontWeight: 500 }}>
              <del style={{ color: 'var(--clr-gray)' }}>₹ {cartItem.mrp_total}</del>
            </p>
          </div>
        </CustomeTableCellPrice>
      </TableRow>
      <TableRow className="service-meta">
        <TableCell colSpan={4} style={{ padding: 0, paddingBottom: 8 }}>
          <div
            style={{
              fontSize: 14,
              color: '#6D7D93',
              marginTop: '-0.5rem',
            }}
          >
            <div
              style={{
                display: 'flex',
                gap: 16,
                marginBottom: 4,
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  gap: 6,
                  alignItems: 'center',
                }}
              >
                <IconCalendar
                  style={{
                    height: '0.8rem',
                    width: '0.8rem',
                  }}
                />
                <p
                  style={{
                    fontSize: 12,
                    color: 'var(--clr-primary)',
                    fontWeight: 600,
                  }}
                >
                  {dayjs(cartItem.date).format('MMM D, YYYY')}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  gap: 6,
                  alignItems: 'center',
                }}
              >
                <IconWatch
                  style={{
                    height: '0.8rem',
                    width: '0.8rem',
                  }}
                />
                <p
                  style={{
                    fontSize: 12,
                    color: 'var(--clr-primary)',
                    fontWeight: 600,
                  }}
                >
                  {cartItem.time}
                </p>
              </div>
            </div>
            {(Object.keys(cartItem.issues).length > 0 || cartItem?.notes) && (
              <IssuesAndNotes>
                {cartItem.issues && (
                  <p>
                    {langData('Issue')} :{' '}
                    {Object.keys(cartItem.issues)
                      .filter((key) => key !== 'other')
                      .map((key) => langData(key.charAt(0).toUpperCase() + key.slice(1)))
                      .join(', ')}{' '}
                  </p>
                )}
                {cartItem.issues['other'] ? (
                  <p>
                    {Object.keys(cartItem.issues).filter((key) => key !== 'other').length > 0 ? ', ' : ''}
                    {langData('Other')} -{' '}
                    {cartItem.issues['other']
                      .split(',')
                      .map((issue) => issue.trim().charAt(0).toUpperCase() + issue.trim().slice(1))
                      .join(', ')}
                  </p>
                ) : null}
                {cartItem.notes && (
                  <p>
                    {langData('Notes')} : {cartItem.notes}
                  </p>
                )}
              </IssuesAndNotes>
            )}
          </div>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

const Kit = ({ kit }) => {
  return (
    <Fragment>
      <TableRow>
        <CustomeTableCellItem>
          <LazyImage name={kit.item_img} height="2.5rem" width="2.5rem" />
          <div>
            <p style={{ fontSize: 14, fontWeight: 600 }}>{kit.item_name}</p>
            <p style={{ fontSize: 14, fontWeight: 600 }}>{kit.tractor_model}</p>
          </div>
        </CustomeTableCellItem>
        <CustomeTableCellItemPrice>{kit.quantity}</CustomeTableCellItemPrice>
        <CustomeTableCellItemPrice>
          <div>
            <p style={{ fontSize: '1rem', fontWeight: 600 }}>₹ {kit.offer_total}</p>
            <p style={{ fontSize: '0.9rem', fontWeight: 500 }}>
              <del style={{ color: 'var(--clr-gray)' }}>₹ {kit.mrp_total}</del>
            </p>
          </div>
        </CustomeTableCellItemPrice>
      </TableRow>
    </Fragment>
  );
};

const CustomeTableCellService = styled(TableCell)({
  display: 'flex',
  gap: 12,
  position: 'relative',
  paddingLeft: 0,
  borderBottom: 0,
  div: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    p: {
      fontSize: 14,
    },
    div: {
      p: {
        fontSize: 16,
        fontWeight: 600,
      },
    },
  },
});

const IssuesAndNotes = styled('div')({
  display: 'flex',
  marginBottom: 6,
  background: '#F2F7FF',
  padding: 6,
  flexDirection: 'column',
  borderRadius: 8,
  p: {
    fontSize: 12,
    color: '#6D7D93',
  },
  ' @media only screen and (max-width: 600px)': {
    p: {
      fontSize: 12,
      color: '#6D7D93',
    },
  },
});

const CustomeTableCellPrice = styled(TableCell)({
  verticalAlign: 'top',
  color: 'var(--clr-primary)',
  fontWeight: 600,
  fontSize: 16,
  borderBottom: 0,
  paddingLeft: 0,
});

const CustomTableContainer = styled(TableContainer)({
  '&::-webkit-scrollbar': {
    width: '4px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f5f5f5',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'var(--clr-secondary)',
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: 'var(--clr-secondary)',
  },
});

const BillingSummary = styled('div')({
  paddingBlock: '1rem',
  borderBottom: '1px solid #0028521A',
  display: 'flex',
  flexDirection: 'column',
  marginTop: 'auto',
  gap: '1rem',
  div: {
    display: 'flex',
    justifyContent: 'space-between',
    p: {
      color: 'var(--clr-primary)',
      fontWeight: 500,
    },
  },
});

const CustomeTableCellItem = styled(TableCell)({
  display: 'flex',
  gap: 12,
  paddingLeft: 0,
  alignItems: 'center',
  div: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
});

const CustomeTableCellItemPrice = styled(TableCell)({
  verticalAlign: 'top',
  color: 'var(--clr-primary)',
  fontWeight: 500,
  fontSize: 16,
  paddingLeft: 0,
  paddingBottom: 0,
});
