import { Select, styled } from '@mui/material';

export const TractorAndWorkshopSelect = styled('div')({
  display: 'flex',
  minHeight: 133,
  backgroundColor: '#F2F7FF',
  flexWrap: 'wrap',
  gap: '3rem',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '0 calc(var(--padding-inline) * -1)',
  padding: '1rem var(--padding-inline)',
  ' .selected-tractor': {
    width: '100%',
    flex: 2.5,
    order: 1,
    ' .tractor-heading': {
      color: '#6D7D93',
      fontSize: 14,
    },
    ' .selected-tractor-model': {
      display: 'flex',
      padding: '0.5rem 0',
      gap: '1rem',
      alignItems: 'center',
      h3: { fontWeight: 600 },
      p: { fontWeight: 500 },
      ' span': {
        color: 'var(--clr-secondary)',
        cursor: 'pointer',
        textDecoration: 'underline',
        fontSize: 14,
      },
    },
  },
  '.model-change-mobile': {
    display: 'none',
  },
  '.mobile-change-none': {
    // display: "block",
  },
  ' .selection-tabs': {
    height: 85,
    flex: 6,
    order: 2,
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'center',
    gap: '1rem',
    ' .tab': {
      flex: 1,
      display: 'flex',
      gap: '0.6rem',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '& p': {
        width: '100%',
        fontWeight: 500,
      },
    },
    '.services-tabs0': {
      maxWidth: 100,
    },
    '.services-tabs1': {
      maxWidth: 90,
    },
    '.services-tabs2': {
      maxWidth: 200,
    },
    '.services-tabs3': {
      maxWidth: 180,
    },
    ' .tab-bordered': {
      borderLeft: '1px solid #ccc',
      justifyContent: 'center',
      paddingLeft: '1rem',
      '& p': {
        width: '105%',
        // textWrap: "warp",
      },
    },
  },
  ' .selected-workshop': {
    flex: 3.5,
    order: 3,
    width: '100%',
    ' .MuiTypography-subtitle1 > a': {
      fontSize: 14,
    },
    ' .selected-tractor-model': {
      padding: '1rem 0',
      display: 'flex',
      gap: '1rem',
      ' span': {
        color: 'var(--clr-secondary)',
        cursor: 'pointer',
      },
    },
  },
  '@media (max-width: 600px)': {
    flexDirection: 'column',
    gap: '1rem',
    ' .selected-tractor, .selection-tabs, .selected-workshop': {
      flex: '0 0 100%',
    },
    '.selected-tractor': {
      ' .tractor-heading': {
        display: 'none',
        order: 1,
      },
    },
    ' .selected-tractor-mobile': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '.model-change-mobile': {
        display: 'block',
      },
      '.mobile-change-none': {
        display: 'none',
      },
      ' span': {
        color: 'var(--clr-secondary)',
        cursor: 'pointer',
      },
    },
    ' .selection-tabs': {
      flexDirection: 'row',
      order: 3,
      justifyContent: 'flex-start',
      '.tab > div': {
        display: 'flex',
        justifyContent: 'center',
      },
      '.tab': {
        '& p': {
          textAlign: 'center',
        },
      },
      ' .tab-bordered': {
        borderLeft: 'none',
        paddingLeft: '0',
        '& p': {
          textAlign: 'center',
        },
      },
    },
    '.selected-workshop': {
      order: 2,
    },
  },
});

export const CustomSelect = styled(Select)(() => ({
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiSelect-select': {
    padding: '0px 12px',
  },
}));
export const TractorModelChange = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  '.change-tractor-brand': {
    width: '70%',
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
  },
  '@media (max-width: 600px)': {
    flexDirection: 'column',
    alignItems: 'start',
    gap: '1rem',
    '.change-tractor-brand': {
      width: '100%',
      flexDirection: 'column',
      gap: '1rem',
      alignItems: 'start',
    },
  },
});
export const ServiceNoFound = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  margin: '80px 0',
  '.no-service-title': {
    textAlign: 'center',
    color: '#002852',
    fontWeight: '600',
  },
  '.no-service-des': {
    textAlign: 'center',
    color: '#6D7D93',
    fontWeight: '400',
  },
  '.no-service-tractor': { display: 'block', margin: 'auto' },
});
export const ServiceButton = styled('div')({
  '@media (min-width: 1600px)': {
    display: 'flex',
    gap: '7px',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '-4px',
  },

  '.service-btn-parent': { display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '0.5rem' },
  '@media (max-width: 1500px)': {
    '.service-btn-parent': { display: 'flex', justifyContent: 'flex-start' },
  },
});
