import { Navigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useAuthContext } from '../../context/authContext';
import { useDispatch } from 'react-redux';

export default function Logout() {
  const { setToken, setLang } = useAuthContext();
  const dispatch = useDispatch();

  useEffect(() => {
    setToken('clearToken');
    dispatch({ type: 'LOGOUT' });
    setLang('EN');
    localStorage.clear();
  }, []);
  return <Navigate to="/" />;
}
